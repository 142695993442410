/* eslint-disable no-confusing-arrow */
import React, { useEffect, useState, CSSProperties, FC } from 'react';
import * as S from './glide-inspector-footer.style';
import InspectorActions, { InspectorAction } from '@virtus/components/Inspector/components/InspectorActions';
import { useObjectActions } from 'src/hooks/useObjectActions';
import { RootState } from 'src/reducers/rootReducer';
import { connect } from 'react-redux';
import ActionForm from 'src/components/forms/action-form/action-form';
import { selectComponents } from 'src/reducers/components';
import { BUTTON_WIDTH } from 'src/utils/constants';

export interface ReduxProps {
  component: any;
}

export type GlideInspectorFooterProps = {
  displayViewData: any;
  actionHandlers: { [key: string]: any };
  disabled?: boolean;
  customPrimaryActions?: InspectorAction[];
  isExpanded?: boolean;
  overrideFooter?: boolean;
  onWorkflowActionClicked?: (transition_uri: string) => void;
  isEditing?: boolean;
  formGroupsStateRef?: any;
};

export const DefaultButtonsContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
};

export const GlideInspectorFooter: FC<GlideInspectorFooterProps & ReduxProps> = ({
  disabled = false,
  displayViewData,
  actionHandlers,
  customPrimaryActions,
  isExpanded,
  overrideFooter = false,
  component,
  onWorkflowActionClicked,
  isEditing,
  formGroupsStateRef,
}) => {
  const { primaryAction, nestedPrimaryActions, otherActions } = useObjectActions({
    rowDetails: displayViewData,
    customPrimaryActions,
    actionHandlers,
    onWorkflowActionClicked,
    isEditing,
    formGroupsStateRef,
  });
  const [width, setWidth] = useState(100);

  /* ResizeObserver reports the change in the width ofthe parent element,
     using ResizeObserver avoids infinite callback loops when resizing via a callback function. */
  useEffect(() => {
    const timer = setTimeout(() => {
      const resizeObserver = new ResizeObserver((event: any) => {
        setWidth(event[0].contentBoxSize[0].inlineSize);
      });
      resizeObserver.observe(document.getElementById('footer') as Element);
    }, 100);
    return () => clearTimeout(timer);
  });
  const primaryActions = nestedPrimaryActions ?? primaryAction;
  const showMoreActions = !isExpanded && !overrideFooter && !nestedPrimaryActions && otherActions?.length > 0;
  const showModalActions = isExpanded && otherActions && otherActions.length > 0 && !nestedPrimaryActions;
  const visibleNbActions = Math.floor(width / BUTTON_WIDTH);
  const extraActions = isExpanded && otherActions && otherActions.slice(visibleNbActions + 1);

  const sortedPrimaryActions = primaryActions?.sort((a: any, b: any) => {
    return b.text.length - a.text.length;
  });
  return component?.actionForm?.visible ? (
    <ActionForm showAsModal={false} targetUris={displayViewData?.uri} />
  ) : (
    <S.Footer data-testid="glide-inspector-footer">
      {showModalActions && (
        <S.MoreActionsWrapper>
          <S.MoreActions>
            {otherActions.slice(0, visibleNbActions).map((action: InspectorAction) => (
              <S.SecondaryButton
                key={action.text}
                onClick={action.actionFn}
                data-testid="secondary-btn"
                backgroundcolor={action?.props?.backgroundcolor}
              >
                {action.text}
              </S.SecondaryButton>
            ))}
          </S.MoreActions>
          {extraActions.length > 0 && <InspectorActions actions={extraActions} actionButtonText="More" />}
        </S.MoreActionsWrapper>
      )}
      {showMoreActions && <InspectorActions actions={otherActions} actionButtonText="More" />}
      {sortedPrimaryActions?.map((action: InspectorAction) =>
        action.uri?.includes('canceledit') ? (
          <S.SecondaryButton
            key={action.text}
            onClick={action.actionFn}
            data-testid="secondary-btn"
            backgroundcolor={action?.props?.backgroundcolor}
          >
            {action.text}
          </S.SecondaryButton>
        ) : (
          <S.PrimaryButton
            key={action.text}
            data-testid="primary-btn"
            onClick={action.actionFn}
            disabled={disabled || !(action.props?.enabled ?? true)}
            backgroundcolor={action?.props?.backgroundcolor}
            variant="contained"
            color="secondary"
          >
            {action.text}
          </S.PrimaryButton>
        ),
      )}
    </S.Footer>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  component: selectComponents(state),
});

export default connect(mapStateToProps)(GlideInspectorFooter);
