import { GlideObject } from 'src/models/glide/glideObject';
import { ClientView } from 'src/api/views/client-view';
import { RouteComponentProps } from '@reach/router';

export type ClientViewKeys =
  | ClientViewTypeLookUps.Grid
  | ClientViewTypeLookUps.Pivot
  | ClientViewTypeLookUps.Dashboard
  | ClientViewTypeLookUps.Custom;

export enum ClientViewTypeLookUps {
  Grid = 'Grid',
  Pivot = 'Pivot',
  Report = 'Report',
  Combined = 'Combined',
  Custom = 'Custom',
  Dashboard = 'Dashboard',
}
export interface ClientViewConfigurationData {
  uri: string; // ie: instance/client_view_configuration/allocation_optimisations
  behaviour: string; // ie: instance/behaviours/module
  client_region: string; // ie: lookups/mainregion
  client_view_type: ClientViewKeys; // ie "lookups/pivot";
  display_name: string; // ie "Allocation Optimisations";
  enabled: boolean; // true
  expand_links: boolean;
  filter_uri: string; //ie "selection/type/allocation_optimisations?expanded=true"
  view: string; // "instance/display_views/allocation_optimisations_view",
  object_type: string; // "object_types/allocation_optimisations"
  is_time_series?: boolean;
  static_menu_actions?: string[]; // Additional grid actions
  is_instance_sensitive?: boolean;
  category?: string; // ie: "Blotters"
  actions_collection: any;
  actions_collection_web: any;
  browser_region?: string; // Override the client_region
  web_summary_panel?: any;
  toolbar_buttons?: any; // grid toolbar buttons
  bottom_panel_static_fields?: Partial<GlideObject>[];
  bottom_panel_details?: any;
  bottom_panel_hypo_test_result_details?: boolean;
  instance_sensitive_field_name?: string;
  is_default_view?: boolean;
}

export interface ClientViewConfiguration extends GlideObject {
  data: ClientViewConfigurationData;
}

export type ViewComponent = {
  [key in ClientViewKeys]: JSX.Element;
};
interface GlideViewType {
  category: string;
}

export interface GlideViewReduxProps {
  clientViewUri: string;
  clientViewData: ClientView;
  clientViewConfiguration: ClientViewConfigurationData;
  components: any;
  isPending: boolean;
  orderBottomPanel: Partial<GlideObject>[];
  hypoTestResultDetails: any;
  bottomPanelResults: any;
  isLoadingBottomPanelResults: boolean;
  instanceSensitiveBottomPanel: ClientViewConfiguration[];
}

export type GlideViewProps = GlideViewType & GlideViewReduxProps & RouteComponentProps;
