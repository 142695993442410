import { Column } from 'devextreme-react/data-grid';
import React from 'react';
import SelectTemplateRender from 'src/components/Select/Select';
import { Header } from './grid-action-column.style';

export type ActionEvent = {
  row: {
    rowIndex: number;
  };
};
export interface RowAction {
  text: string;
  onClick: (params?: any) => void;
  icon?: DXActionButtonIcons;
  getVisibility?: (e: ActionEvent) => boolean;
}

export type GridActionColumnProps = {
  actions: RowAction[];
};

//Latest icon list available on: https://js.devexpress.com/Documentation/Guide/Themes_and_Styles/Icons/
export enum DXActionButtonIcons {
  activefolder,
  airplane,
  aligncenter,
  alignjustify,
  alignleft,
  alignright,
  arrowdown,
  arrowleft,
  arrowright,
  arrowup,
  background,
  blockquote,
  bold,
  bookmark,
  box,
  bulletlist,
  car,
  card,
  cart,
  chart,
  check,
  chevrondoubleleft,
  chevrondoubleright,
  chevrondown,
  chevronleft,
  chevronright,
  chevronup,
  clear,
  clearformat,
  clearsquare,
  clock,
  close,
  codeblock,
  coffee,
  collapse,
  color,
  columnchooser,
  columnfield,
  comment,
  contains,
  contentlayout,
  copy,
  cut,
  datafield,
  decreaseindent,
  decreaselinespacing,
  detailslayout,
  docfile,
  docxfile,
  doesnotcontain,
  download,
  dragvertical,
  edit,
  endswith,
  equal,
  event,
  expand,
  export,
  exportpdf,
  exportselected,
  exportxlsx,
  favorites,
  fieldchooser,
  fields,
  file,
  fill,
  filter,
  floppy,
  font,
  fontsize,
  food,
  formula,
  fullscreen,
  gift,
  globe,
  greater,
  greaterorequal,
  group,
  growfont,
  header,
  help,
  hidepanel,
  hierarchy,
  home,
  image,
  inactivefolder,
  increaseindent,
  increaselinespacing,
  info,
  isblank,
  isnotblank,
  italic,
  key,
  less,
  lessorequal,
  like,
  link,
  map,
  mediumiconslayout,
  mention,
  menu,
  message,
  minus,
  money,
  more,
  movetofolder,
  music,
  newfolder,
  notequal,
  orderedlist,
  overflow,
  palette,
  parentfolder,
  paste,
  pdffile,
  percent,
  photo,
  pin,
  pinleft,
  pinright,
  plus,
  pptfile,
  pptxfile,
  preferences,
  print,
  product,
  pulldown,
  range,
  redo,
  refresh,
  rename,
  repeat,
  rowfield,
  rtffile,
  runner,
  save,
  search,
  selectall,
  share,
  showpanel,
  shrinkfont,
  smalliconslayout,
  sortdown,
  sortdowntext,
  sorted,
  sortup,
  sortuptext,
  spindown,
  spinleft,
  spinright,
  spinup,
  square,
  startswith,
  strike,
  subscript,
  superscript,
  tags,
  tel,
  tips,
  toolbox,
  trash,
  txtfile,
  underline,
  undo,
  unpin,
  unselectall,
  upload,
  user,
  variable,
  video,
  warning,
  xlsfile,
}

const ActionColumnHeader = () => <Header>Actions</Header>;

export const GridActionColumn = ({ actions }: GridActionColumnProps) => {
  const sortedActions = actions.sort((a, b) => {
    return a.text.length - b.text.length;
  });
  const renderActionCell = (data: any) => {
    return <SelectTemplateRender actionsCollection={sortedActions} options={{ uri: data.data._uri }} />;
  };
  return (
    <Column
      dataField="Actions"
      fixed
      allowSorting={false}
      fixedPosition="right"
      width="auto"
      cellRender={renderActionCell}
      headerCellRender={ActionColumnHeader}
      cssClass="fixed-right-column"
      allowHiding={false}
    />
  );
};
