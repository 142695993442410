import { debounce } from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { lighten } from 'polished';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import LoadingIcon, { LoadingIconSizes, LoadingIconType } from 'src/LoadingIcon/LoadingIcon';
import MoreViewsSelector from 'src/ViewToolbar/components/MoreViewsSelector';
import ViewLabel from 'src/ViewToolbar/components/ViewLabel';
import { ViewToolbarView } from 'src/ViewToolbar/ViewToolbar.model';
import styled, { css } from 'styled-components';

// we use a class variable to track last container width
// tracking this throught state brings issues and performance leaks.
const getInnerWidth = () => window.innerWidth * (window.innerWidth < 900 ? 0.2 : 0.45);
let lastParentWidth: any = getInnerWidth();

const getNewVisibleElements = (currentWidth: number, currentViews: number, totalViews: any) => {
  const newEls =
    currentWidth === lastParentWidth ? currentViews + 1 : Math.floor((currentWidth * currentViews) / lastParentWidth);
  return totalViews.reduce((prev: any, view: ViewToolbarView, index: number) => {
    if (index + 1 <= newEls) {
      prev.push(view.id);
    }
    return prev;
  }, []);
};

const parseViews = (views: ViewToolbarView[]): ViewToolbarView[] =>
  views.map((view: ViewToolbarView) => {
    const { id } = view;
    const { name = '', isBeingCreated: loading = false } = get(view, 'viewSetting.value', {});
    return { id, name, loading };
  });

interface ViewLabelsProps {
  views: any[];
  onClick: (id: number) => React.MouseEventHandler;
  active?: number;
  reporting?: any;
  onCloseTab?: (id: any, isReport?: boolean) => void;
  parentRef?: React.Ref<any>;
  labelStyle?: any;
  setParentWidth?: (width: number) => string;
  customParseViews?: (data: any[]) => any[];
}

const ViewLabels: React.FunctionComponent<ViewLabelsProps> = ({
  views = [],
  onClick,
  active,
  reporting,
  onCloseTab,
  labelStyle,
  setParentWidth,
  customParseViews,
  parentRef,
}) => {
  const labelViews = useMemo(() => (customParseViews ? customParseViews(views) : parseViews(views)), [
    views,
    customParseViews,
  ]); // ViewToolbarView[]
  const parent = parentRef as any;
  const reportTabs =
    (reporting &&
      reporting.adHocReports &&
      Object.keys(reporting.adHocReports).reduce((prev: any, key: any) => {
        const report = reporting.adHocReports[key];
        if (!report.error) {
          return [
            ...prev,
            {
              id: key,
              name: report && report.reportLabel,
              isReport: true,
              loading: (report && report.loading) || false,
            },
          ];
        }
        return prev;
      }, [])) ||
    [];

  const [visibleViews, setVisibleViews] = useState<number[]>(
    [...labelViews, ...reportTabs].map((view: ViewToolbarView) => view.id),
  );

  const viewsToDropdown: ViewToolbarView[] = [...labelViews, ...reportTabs].filter(
    (view: ViewToolbarView) => !visibleViews.includes(view.id),
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const toggleParentWidth = () => {
    if (parent && parent.current) {
      parent.current.style.width = setParentWidth
        ? setParentWidth(window.innerWidth)
        : window.innerWidth < 900
        ? '20vw'
        : '45vw';
    }
  };

  // we access state variable through getter to avoid out of sync issues
  const getParent = () => parent;
  const getTotalViews = () => [...labelViews, ...reportTabs];
  const getCurrentViews = () => {
    const parentEl = getParent();
    if (!parentEl?.current) return getTotalViews().length;
    // we check if we have views in dropdown
    const hasMore = parentEl?.current?.querySelector('[data-testid="more-views-dropdown-button-label"]');
    // we decrease 2 for add view btn and more button
    return parentEl?.current?.children.length - (hasMore ? 2 : 1);
  };

  const checkVisibileViewsUpdate = (skipViewSizeValidation = false) => {
    // we rely on window width since inner dom is still resizing and has shrinked values
    const currWidth = getInnerWidth();
    const currViews = getCurrentViews();
    if (skipViewSizeValidation || currWidth > lastParentWidth) {
      // we do this to show the maximum possible without breaking the layout
      const newVisibleViews = getNewVisibleElements(currWidth, currViews, getTotalViews());
      setVisibleViews(newVisibleViews);
    }
  };

  // this function checks if the window grows how many view labels can be displayed
  const handleResize = debounce(() => {
    toggleParentWidth();
    checkVisibileViewsUpdate();
  });

  useLayoutEffect(() => {
    checkVisibileViewsUpdate(true);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [views]);

  const visibilityHandler = useCallback(
    (id: number, isVisible: boolean) => {
      const isVisibleView = visibleViews.includes(id);
      if (isVisible === isVisibleView) return; // no change needed

      const newVisibleViews = isVisible ? [...visibleViews, id] : visibleViews.filter(_id => _id !== id);
      const pw = getInnerWidth();
      lastParentWidth = pw;
      toggleParentWidth();
      setVisibleViews(newVisibleViews);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleViews, setVisibleViews],
  );

  const activeTab = (reporting && reporting.currentReportView) || active;

  return (
    <>
      {[...labelViews, ...reportTabs].map(
        (view: ViewToolbarView, position: number) =>
          visibleViews.includes(view.id) && (
            <ViewLabel
              key={view.id}
              position={position}
              view={view}
              onViewClick={onClick}
              active={activeTab}
              containerEl={parent?.current}
              visibilityHandler={visibilityHandler}
              onClose={onCloseTab}
              labelStyle={labelStyle}
            />
          ),
      )}
      {!isEmpty(viewsToDropdown) && (
        <MoreViewsSelector
          views={viewsToDropdown}
          activeView={activeTab}
          onViewClick={onClick}
          onClose={onCloseTab}
          labelStyle={labelStyle}
        />
      )}
    </>
  );
};

export const LoadingSpinner = () => <LoadingIcon show type={LoadingIconType.Virtus} size={LoadingIconSizes.small} />;

const LoadingPlaceholder: React.FunctionComponent = () => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    Creating...
    <LoadingSpinner />
  </div>
);

export const LoadingPlaceholderStyled = styled(LoadingPlaceholder)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.div<{ active: boolean; isReport?: boolean; ['data-testid']: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => {
    return props.active ? (props.isReport ? 'var(--fis-green)' : 'var(--blue)') : 'var(--label)';
  }};
  color: var(--text);
  height: 28px;
  width: 'auto';
  max-width: fit-content;
  border-radius: 2px;
  padding: ${props => (props['data-testid'] === 'page-view-undefined' ? '0' : '6px')};
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  white-space: nowrap;
  cursor: pointer;
  margin: ${props => (props['data-testid'] === 'page-view-undefined' ? '0' : '4px 5px 4px 0')};
  transition: 0.1s all;

  &:hover {
    ${_props => labelMixin}
  }
`;

const labelMixin = css<any>`
  background-color: ${props => {
    return props.active
      ? props.isReport
        ? lighten(0.1, '#57b124')
        : lighten(0.1, '#0d83c9')
      : lighten(0.1, '#54616D');
  }};
`;

export const EllipsisText = styled.span<{ maxWidth?: string }>`
  max-width: ${({ maxWidth = '30ch' }) => maxWidth};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default ViewLabels;
