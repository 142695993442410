import { GlideDataSource, glideDataTypeMap } from '@virtus/components/DxDataGrid/utils/mapSchemaGlide';
import { TotalItem } from 'devextreme-react/data-grid';
import { LoadingIconGlide } from '@virtus/components/LoadingIcon';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import summaries from 'src/components/glide-object-manager/utils/dxdatagrid-summaries';
import validators from 'src/components/glide-object-manager/utils/dxdatagrid-validators';
import { GridView } from './grid-view/grid-view';
import { Header } from './header/header';
import { CustomCellRenderKeyProps } from '@virtus/components/DxDataGrid/DxDataGrid';
import { CellData } from 'src/components/grids/dxgrid-client-view/dxgrid-client-view.model';
import { getObjectCollectionColumns } from 'src/components/glide-object-manager/utils/get-object-collection-cells';
import {
  Action,
  Changes,
  ColumnSummary,
  DeletedRows,
  IAddAPIRulesToDataSource,
  IAddValidatorsToDataSource,
  Inspector,
} from 'src/components/glide-object-manager/model';
import * as S from './style';
import { getFormPropsForGlideObjectManager } from 'src/components/forms/glide-data-content/glide-data-content';
import { SearchInput } from 'src/components/forms/form-elements/form-fields';
import SearchService from 'src/services/search.service';
import { FormFieldType } from 'src/components/forms/form-elements';
import { footerSummaryFields } from 'src/utils/common';
import {
  buttonIconProps,
  ButtonsToExclude,
  GridEditProps,
  layoutRegion,
  otherActionsConfig,
} from './glide-object-manager.model';
import { ToolBarButtonsType } from 'src/components/grids/dxgrid-client-view';
import { GridViewProps } from './grid-view/model';
import { useGridFieldRule } from 'src/hooks/use-grid-field-rule';
import { useObjectActions } from 'src/hooks/useObjectActions';
import { useSelector } from 'react-redux';
import { activeTabSelector } from 'src/reducers/tabs';
import { RootState } from 'src/reducers/rootReducer';
import { glideQuerySelector } from 'src/api/query';

export type bottomLayoutGOMProps = {
  useDarkTheme: boolean;
};

export type popupLayoutGOMProps = {
  onBack: () => void;
  getDataGrid: (args: {
    object_uri: string;
    object_field_name: string;
    object_form_props: any;
    selected_row_data: any;
  }) => void;
};

export type GlideObjectManagerComponentProps = {
  layout?: layoutRegion;
  disableEditMode?: boolean;
  formProps?: any;
  loading?: boolean;
  enableEdit?: boolean;
  dataSource: GlideDataSource;
  fieldName: string;
  actions?: Action[];
  inspectors?: Inspector[];
  onRefresh: (body: any) => void;
  onGridViewChangesSaved?: ({ changedRows, newRows, currentAmountOfItems }: Changes) => void;
  displayType?: string;
  handleExpansion?: () => void;
  showHeader?: boolean;
  overrideGridViewProps?: {
    allowDeleting?: boolean;
    usePagination?: boolean;
    skipkeyId?: boolean;
    ignoreDxSaveButton?: boolean;
    skipSchema?: boolean;
  };
  overridePopupGOMProps?: popupLayoutGOMProps;
  overrideBottomGOMProps?: bottomLayoutGOMProps;
  onCustomToolBarPreparing?: () => (e: any) => void;
  parseGridData?: (data: any) => any;
  toggleEditOnCancel?: boolean;
  customizeGridColumns?: (e: any) => void;
  customDxToolbarButtonsActions?: any;
  selectedRowData?: { [key: string]: any };
  displayViewData?: any;
  editObjectIcons?: any;
  removeIconsConfig?: any;
};

const initialGridEditProps: GridEditProps = {
  edit: false,
  editMode: 'batch',
  allowUpdating: false,
  allowDeleting: false,
  allowAdding: false,
  allowSelection: false,
  allowMultipleSelection: false,
};

export const getSummariesFromDataSource = (fieldName: string) => {
  const fieldSummaries = (summaries as any)[fieldName];
  if (!fieldSummaries) return undefined;
  return fieldSummaries.map((props: ColumnSummary) => <TotalItem key={props.column} {...props} />);
};

export const addAPIRulesToDataSource = ({
  dataSource = { schema: [], data: [], fieldRules: {} },
  externalProps,
}: IAddAPIRulesToDataSource): GlideDataSource => {
  const nextDataSource = {
    ...dataSource,
    schema: dataSource?.schema?.map(column => {
      const columnFieldRules = dataSource.fieldRules?.[`fields/${column.dataField}`];
      return {
        ...column,
        editProps: {
          parentValues: externalProps?.formProps?.parent,
          apiRules: columnFieldRules || [],
        },
      };
    }),
  };
  return nextDataSource;
};

export const addValidatorsToDataSource = ({
  fieldName,
  dataSource = { schema: [], data: [], fieldRules: {} },
  externalProps,
}: IAddValidatorsToDataSource) => {
  const nextDataSource = {
    ...dataSource,
    schema: dataSource?.schema?.map(column => {
      const fieldValidators = validators[fieldName];
      if (!fieldValidators) return column;
      const columnValidator = fieldValidators[column.display_name || column.caption || ''];
      return {
        ...column,
        validationRules: columnValidator
          ? columnValidator({
              externalProps,
            })
          : undefined,
      };
    }),
  };
  return nextDataSource;
};

// const isGridEdited = (ref: any) => {
//   const element = ref?.current?.instance.element().querySelectorAll('[title="Save changes"][tabindex="0"]')[0];
//   if (element) {
//     return !element.classList.contains('dx-state-disabled');
//   }
//   return false;
// };

export const getUpdatedRowsData = (currentData: any, editedData: any) => {
  const newRows: any = [];
  const existingUpdatedRows = currentData.reduce(
    (
      accParent: any,
      currParent: {
        data: any;
        oldData: any;
        isNewRow: boolean;
        removed: boolean;
      },
    ) => {
      if (currParent?.removed) return accParent;
      if (currParent?.isNewRow) {
        delete currParent.data['__KEY__'];
        newRows.push({ ...currParent.data });
        return accParent;
      }
      const updatedData = editedData && editedData.filter((ed: any) => ed.key === currParent.data._uri)[0];
      return {
        ...accParent,
        [currParent.data._uri]: updatedData && Object.keys(updatedData).length ? updatedData.data : {},
      };
    },
    {},
  );
  return [newRows, existingUpdatedRows];
};

export const GlideObjectManagerComponent = React.memo(
  React.forwardRef(
    (
      {
        layout = layoutRegion.POPUP,
        loading,
        dataSource,
        fieldName,
        formProps,
        onGridViewChangesSaved,
        displayType,
        handleExpansion,
        showHeader = true,
        overrideGridViewProps,
        overridePopupGOMProps,
        overrideBottomGOMProps,
        onCustomToolBarPreparing,
        parseGridData,
        customizeGridColumns,
        customDxToolbarButtonsActions,
        selectedRowData,
        displayViewData,
        editObjectIcons,
        removeIconsConfig,
      }: GlideObjectManagerComponentProps,
      ref: any,
    ) => {
      const [gridEditProperty, setGridEditProperty] = useState<GridEditProps>(initialGridEditProps);
      const [orderCollectionUris, setOrderCollectionUris] = useState<string[]>([]);
      const clientViewUri = useSelector((state: RootState) => activeTabSelector(state));
      const inspectordata = useSelector((state: RootState) =>
        glideQuerySelector(state, clientViewUri, 'inspectorData'),
      );

      const { mapGridFieldRules, updateCellValues } = useGridFieldRule();
      //Icons and actions config based
      const actionHandlersObject = {
        'instance/actions/edit_object': () => {
          setGridEditProperty(prev => ({
            ...prev,
            ...editObjectIcons,
          }));
        },
        'instance/actions/canceledit': () => {
          (gridViewRef?.current as any)?.instance.cancelEditData();
          setGridEditProperty(initialGridEditProps);
          orderCollectionUris.length > 0 && setOrderCollectionUris([]);
        },
      };
      const getRowDetails = () => {
        if (orderCollectionUris.length) {
          displayViewData.uri = orderCollectionUris[0];
        }
        return displayViewData;
      };

      const { primaryAction, nestedPrimaryActions, otherActions } = useObjectActions({
        rowDetails: getRowDetails(),
        actionHandlers: actionHandlersObject,
      });

      const onObjectCollectionCellClick = useCallback(
        (cellData: CellData) => {
          const formProps = getFormPropsForGlideObjectManager({
            field: cellData.column.caption,
            formValues: cellData.data,
          });
          overridePopupGOMProps?.getDataGrid({
            object_uri: cellData.data._uri,
            object_field_name: cellData.column.caption.toLowerCase().replace(' ', '_'),
            object_form_props: formProps,
            selected_row_data: cellData.data,
          });
        },
        [overridePopupGOMProps?.getDataGrid],
      );

      const customizeColumns: any = useCallback(customizeGridColumns ? customizeGridColumns : () => {}, []);

      const objectCollectionColumns: CustomCellRenderKeyProps = useMemo(
        () => getObjectCollectionColumns(dataSource?.schema, onObjectCollectionCellClick),
        [dataSource?.schema, onObjectCollectionCellClick],
      );

      let gridViewRef = useRef(null);
      gridViewRef = ref ? ref : gridViewRef;

      const restoreGridInitialState = () => {
        if (gridEditProperty.edit) {
          (gridViewRef?.current as any)?.instance.cancelEditData();
          setGridEditProperty(initialGridEditProps);
        }
        orderCollectionUris.length > 0 && setOrderCollectionUris([]);
      };

      useEffect(() => {
        restoreGridInitialState();
      }, [fieldName, dataSource]);

      useEffect(() => {
        if (layout === layoutRegion.BOTTOM || layout === layoutRegion.POPUP) {
          mapGridFieldRules({ schema: dataSource?.schema, data: dataSource?.data, fieldRules: dataSource?.fieldRules });
        }
      }, [dataSource, mapGridFieldRules]);

      const persistChanges = useCallback(() => {
        const currentData = gridViewRef?.current
          ? (gridViewRef?.current as any).instance.getController('data')._items
          : [];

        let validChangedRows: any;
        let newData: any[] = [];
        let newRowsObject: DeletedRows = [];
        if (parseGridData) {
          const { changedRows, updatedData } = parseGridData(currentData);
          validChangedRows = changedRows;
          newData = updatedData;
        } else {
          const [newRows, existingUpdatedRows] = getUpdatedRowsData(
            currentData,
            (gridViewRef as any)?.current?.instance.getController('editing')._editData,
          );
          newRowsObject = newRows;
          validChangedRows = existingUpdatedRows;
        }

        if (onGridViewChangesSaved) {
          onGridViewChangesSaved({
            changedRows: validChangedRows,
            newRows: newRowsObject,
            currentAmountOfItems: Object.keys(validChangedRows).length + newRowsObject.length,
            updatedData: newData,
          });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dataSource?.schema, onGridViewChangesSaved]);

      //Keeping this code temporarily to decide if need to reuse or not
      /**  const handleOnRowValidating = useCallback(
        (e: { isValid: boolean }) => {
          if (e.isValid) {
            persistChanges();
          }
        },
        [persistChanges],
      ); **/

      const onEditorPreparing = useCallback(
        (e: any) => {
          if (e.parentType === 'dataRow') {
            e.editorOptions.onValueChanged = (data: any) => {
              e.component.cellValue(e.row.rowIndex, e.dataField, data.value);
              // Using parent grid data i.e inspector data from store for field rules calculation.
              const mergeParentData = {};
              if (inspectordata?.data && Object.keys(inspectordata?.data)?.length) {
                Object.values(inspectordata?.data).map((data: any) => {
                  const reduceData = data.reduce((acc: any, v: any) => {
                    const displayName = (Object.values(v?.field) as any)[0]?.display_name;
                    acc[displayName] = v?.value;
                    return acc;
                  });
                  Object.assign(mergeParentData, reduceData);
                });
              }
              const rowData = Object.keys(mergeParentData).length ? mergeParentData : selectedRowData;

              updateCellValues(e, rowData ?? {});
            };
          }

          if (e.parentType === 'dataRow' && e.caption === 'Override Comment') {
            e.editorOptions.onValueChanged = (data: any) => {
              if (/^[a-zA-Z0-9 ]*$/.test(data.value)) {
                e.component.cellValue(e.row.rowIndex, 'Override Comment', data.value);
              }
            };
          }
        },
        [selectedRowData, updateCellValues],
      );

      const dataSourceWithValidators = useMemo(
        () =>
          addValidatorsToDataSource({
            fieldName,
            dataSource,
            externalProps: {
              formProps,
              dataGridRef: gridViewRef,
            },
          }),
        [dataSource, fieldName, formProps],
      );

      const dataSourceWithAPIRules = useMemo(
        () =>
          addAPIRulesToDataSource({
            dataSource: dataSourceWithValidators,
            externalProps: {
              formProps,
            },
          }),
        [dataSourceWithValidators, formProps],
      );

      const summaryRender = useCallback(() => getSummariesFromDataSource(fieldName), [fieldName]);

      /**
       * @param e - cell edit event
       * @callback Method to handle change in allocation % in GOM grid cells and highlight the footer
       */
      const handleOnCellPrepared = useCallback(
        (e: any) => {
          if (gridEditProperty.edit && e.rowType === 'totalFooter') {
            footerSummaryFields({ column: e.column.caption, e, formProps });
          }
        },
        [gridEditProperty, formProps],
      );

      const darkThemeRef = useRef(overrideBottomGOMProps?.useDarkTheme);
      useEffect(() => {
        darkThemeRef.current = overrideBottomGOMProps?.useDarkTheme;
      }, [overrideBottomGOMProps?.useDarkTheme]);

      const getFundList = () => {
        const fundList = dataSource.data.map((item: any) => item.fund);
        const updatedFundList = (gridViewRef.current as any).instance
          .getController('data')
          ._items.map((item: any) => item.data.fund)
          .filter((fund: string | undefined) => fund);
        return updatedFundList?.length ? updatedFundList : fundList;
      };

      const EditObjectCellComponent = useCallback(
        ({ props, column }: any) => {
          const field: FormFieldType = {
            dataType: '',
            formElementType: '',
            defaultValue: '',
            name: '',
            displayName: '',
            searchType: '',
            hideLabel: true,
            required: false,
            readonly: !column.allowEditing,
            textDark: layout === layoutRegion.BOTTOM ? !darkThemeRef.current : false,
            searchService: SearchService().search,
          };

          switch (column.dataType) {
            case glideDataTypeMap.Object:
              if (!column.lookups) {
                field.dataType = 'object';
                field.formElementType = 'search';
                field.defaultValue = props.data.text;
                if (column?.objectType) {
                  field.searchType = column.objectType;
                }
                const onChange = (e: any) => {
                  props.data.setValue(e.target.value, e.target.name);
                };

                return (
                  <SearchInput field={field} onChange={onChange} value={props.data.text} fundList={getFundList()} />
                );
              }
              return null;
            default:
              return null;
          }
        },
        [darkThemeRef.current, getFundList],
      );

      const defaultDxToolbarButtonsActions = useMemo(
        () => ({
          saveButton: () => {
            persistChanges();
          },
        }),
        [persistChanges],
      );
      const getPrimaryActionIcons = useCallback(
        (primaryActions: any) => {
          const buttonsConfig: any = [];
          if (primaryActions && primaryActions.length) {
            primaryActions.map((action: any) => {
              if (action.text.indexOf(ButtonsToExclude) === -1) {
                buttonsConfig.push({
                  name: `${action.text.toLowerCase()}Button`,
                  widget: 'dxButton',
                  options: {
                    icon: buttonIconProps[action.text].icon,
                    hint: action.text,
                    elementAttr: { 'data-testid': `${action.text.toLowerCase()}-btn-order-selection` },
                    onClick: action?.actionFn,
                    disabled: action?.props?.hasOwnProperty('enabled') ? !action?.props?.enabled : false,
                  },
                  location: 'after',
                });
              }
            });
          }
          return buttonsConfig;
        },
        [restoreGridInitialState, primaryAction, gridEditProperty],
      );

      const getToolbarButtons = useCallback((): ToolBarButtonsType => {
        const actionCollectionsConfig = [];
        if (otherActions && otherActions.length && !gridEditProperty.edit) {
          actionCollectionsConfig.push(otherActionsConfig);
        }
        const addAfterButtons = getPrimaryActionIcons(nestedPrimaryActions ?? primaryAction);
        return {
          add: [],
          addAfter: [...addAfterButtons],
          addBefore: [...actionCollectionsConfig],
          remove: [...removeIconsConfig],
        };
      }, [orderCollectionUris, primaryAction, otherActions, nestedPrimaryActions, gridEditProperty]);
      const getToolbarIconsOrder = (actions: any) => {
        return actions ? actions.map((action: any) => `${action.text.toLowerCase()}Button`) : [];
      };
      const getDataGridProperties = useCallback(
        (dataGridProps: any) => {
          dataGridProps = {
            ...dataGridProps,
            toolbarIconsOrder: [
              !gridEditProperty.edit && 'otherActionsButtons',
              ...getToolbarIconsOrder(primaryAction),
              'addRowButton',
              'saveButton',
              ...getToolbarIconsOrder(nestedPrimaryActions),
              'exportButton',
              'columnChooserButton',
            ],
            dxDataGridProps: {
              ...dataGridProps.dxDataGridProps,
              onCellClick: (cellData: any) => {
                const results = cellData?.row?.data;
                if (results) {
                  setOrderCollectionUris([results._uri]);
                }
              },
              toolbarButtons: {
                ...getToolbarButtons(),
              },
            },
          };
          return dataGridProps;
        },
        [getToolbarButtons, otherActions, primaryAction, nestedPrimaryActions, gridEditProperty],
      );

      let dataGridProps: GridViewProps = useMemo(
        () => ({
          dataGrid: {
            dataSource: {
              schema: dataSourceWithAPIRules.schema,
              data: dataSource?.data,
            },
            summaryRender,
          },
          id: 'data-grid-order-bottom-panel',
          storageKey: fieldName,
          isFilterButtonLeftAligned: true,
          skipKeyId: true,
          dxDataGridProps: {
            noDataText: 'No Data',
            onCellPrepared: handleOnCellPrepared,
            onEditorPreparing,
          },
          EditObjectCellComponent,
          customizeColumns,
          customCellRenderKeyProps: objectCollectionColumns,
          overrideGridViewProps: {
            ...gridEditProperty,
            ...overrideGridViewProps,
          },
          useDarkTheme: overrideBottomGOMProps?.useDarkTheme,
          layout,
          actionsCollection: !gridEditProperty.edit && otherActions,
          customDxToolbarButtonsActions:
            layout === layoutRegion.BOTTOM ? customDxToolbarButtonsActions : defaultDxToolbarButtonsActions,
          allowHeaderSearch: layout === layoutRegion.BOTTOM,
          exportFileName: fieldName?.capitalizeWords('_'),
        }),
        [
          EditObjectCellComponent,
          dataSource?.data,
          dataSource?.schema,
          fieldName,
          customDxToolbarButtonsActions,
          defaultDxToolbarButtonsActions,
          gridEditProperty,
          handleOnCellPrepared,
          onEditorPreparing,
          summaryRender,
          overrideBottomGOMProps?.useDarkTheme,
          orderCollectionUris,
          otherActions,
          primaryAction,
          nestedPrimaryActions,
        ],
      );

      dataGridProps = useMemo(() => getDataGridProperties(dataGridProps), [
        fieldName,
        dataGridProps,
        getDataGridProperties,
        overrideBottomGOMProps?.useDarkTheme,
        otherActions,
        primaryAction,
        nestedPrimaryActions,
      ]);

      return (
        <S.Wrapper>
          {loading || !dataSource ? (
            <S.LoadingWrapper>
              <LoadingIconGlide size="large" show={loading} />
            </S.LoadingWrapper>
          ) : (
            <S.Wrapper data-testid="glide-object-manager">
              {showHeader && layout === layoutRegion.POPUP && (
                <Header
                  onBack={overridePopupGOMProps ? overridePopupGOMProps.onBack : () => {}}
                  title={fieldName}
                  displayType={displayType}
                  handleExpansion={handleExpansion}
                />
              )}
              <S.Body>
                <S.Grid>
                  <GridView
                    ref={gridViewRef}
                    {...dataGridProps}
                    onCustomToolBarPreparing={onCustomToolBarPreparing}
                    layout={layout}
                  />
                </S.Grid>
              </S.Body>
            </S.Wrapper>
          )}
        </S.Wrapper>
      );
    },
  ),
);
