import React from 'react';
import * as S from './summary-panel.style';
import { ActionButtonProps } from 'src/components/screen-wrapper/components/summary-buttons/summary-buttons';
import { RootState } from 'src/reducers';
import { selectCVC } from 'src/reducers/tabs';
import { connect } from 'react-redux';
import { ClientViewConfigurationData, ClientViewTypeLookUps } from 'src/components/glide-view/glide-view.model';
import DatePicker from 'src/components/date-picker/date-picker';
import SummaryButtons from 'src/components/page-wrapper/components/summary-buttons/summary-buttons';

export interface RenderSectionProps {
  panel_left: {
    action_buttons: ActionButtonProps[];
    onButtonClickCallback?: (e: any, id: string) => void;
  };
  panel_right?: { onButtonClickCallback?: (data: any) => void };
}

export interface SummaryPanelProps {
  children?: React.ReactElement<any>;
  panelContentLeft?: any;
  panelContentRight?: any;
}

interface ReduxProps {
  clientViewConfiguration: ClientViewConfigurationData;
}

export const SummaryPanel = ({
  clientViewConfiguration,
  panelContentLeft,
  panelContentRight,
  children,
}: ReduxProps & SummaryPanelProps) => {
  const hasComplianceButtons = clientViewConfiguration?.web_summary_panel?.panel_left?.action_buttons.length > 0;

  const complianceButtons = () =>
    clientViewConfiguration.web_summary_panel.panel_left.action_buttons.map(
      (
        item: { id: string; text: string | number; label: string; backgroundColor: string | undefined },
        index: string | number | undefined,
      ) => (
        <SummaryButtons
          id={item.id}
          key={index}
          buttonText={item.text}
          buttonLabel={item.label}
          backgroundcolor={item.backgroundColor}
          onButtonClick={() => {}}
        />
      ),
    );

  if (!clientViewConfiguration.is_time_series && !panelContentLeft && !panelContentRight && !hasComplianceButtons)
    return null;

  return (
    <S.SummaryPanelComponent>
      <div>{panelContentLeft}</div>
      {hasComplianceButtons && complianceButtons()}
      {panelContentRight && <div>{panelContentRight}</div>}
      {children}
      {clientViewConfiguration?.client_view_type === ClientViewTypeLookUps.Custom && (
        <S.DatePickerWrapper>
          <DatePicker singleDateSelection dateFieldLabel="Baseline Date: " />
          <DatePicker singleDateSelection dateFieldLabel="Current Date: " />
        </S.DatePickerWrapper>
      )}
      {clientViewConfiguration?.client_view_type !== ClientViewTypeLookUps.Custom &&
        clientViewConfiguration.is_time_series && (
          <S.DatePickerWrapper>
            <DatePicker singleDateSelection />
          </S.DatePickerWrapper>
        )}
    </S.SummaryPanelComponent>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  clientViewConfiguration: selectCVC(state),
});

export default connect(mapStateToProps)(SummaryPanel);
