import { DxPivotGridDataType, GlideDataType, PivotGridField, PivotSummaryType } from '../model/dx-pivot-grid.model';
import { GlideDataSource, GlideSchema } from '@virtus/components/DxDataGrid/utils/mapSchemaGlide';
import { formatNumber } from '@virtus/common/utils/formatters';
import { updateOnLoadCellValues } from '@virtus/glide/src/utils/cell-calculation-field-rule';

export interface PivotFieldSchema extends GlideSchema {
  pivot_grid_display_area: undefined | string;
  aggregation_calculation: any;
}

export interface PivotGridDataSource extends Omit<GlideDataSource, 'schema'> {
  schema: PivotFieldSchema[];
}

interface mapSchemaTOFieldsProps {
  data: PivotGridDataSource;
  isTreeview?: boolean;
  defaultView?: any;
}

const isDataFieldOnly = (item: PivotFieldSchema) => {
  if (!item.pivot_grid_display_area) {
    return;
  }
  return item.pivot_grid_display_area === 'RC' ? false : undefined;
};

const getAreaForField = (schema: PivotFieldSchema, defaultView: any[]) => {
  const field: any = defaultView.find(item => item.display_name === schema.display_name);
  return field
    ? {
        area: field.area,
        areaIndex: field.areaIndex,
      }
    : {};
};

const getSummaryType = (item: PivotFieldSchema) => {
  return item.aggregation_calculation && item.aggregation_calculation !== 'custom'
    ? PivotSummaryType[item.aggregation_calculation as keyof typeof PivotSummaryType]
    : PivotSummaryType.Count;
};

// TODO: Implement correct schema mapper as we will need to map Glide-object type to correct data type supported by Pivot grid.
export const getPivotFieldsFromSchema = ({
  data,
  defaultView,
  isTreeview = false,
}: mapSchemaTOFieldsProps): PivotGridField[] => {
  const { schema } = data;

  return schema.map(
    (item: PivotFieldSchema): PivotGridField => {
      const commonFieldConfig = {
        dataField: item.display_name || item.dataField,
        caption: item.display_name || item.caption,
        isMeasure: isDataFieldOnly(item),
        displayFolder: (isTreeview && item.category) || undefined,
        summaryType: getSummaryType(item),
        ...getAreaForField(item, defaultView),
      };
      switch (item.data_type as GlideDataType) {
        case GlideDataType.Decimal: {
          const checkValue = (value: any) => (value == null ? value : value.toString());
          const retValue = {
            ...commonFieldConfig,
            dataType: DxPivotGridDataType.number,
            format: (value: any) => {
              return item.format && value ? formatNumber(value, item.format.toLowerCase()) : checkValue(value);
            },
            calculateSummaryValue: (e: any) => {
              if (item?.calculation_script_js) {
                const value = updateOnLoadCellValues(e, item?.calculation_script_js, schema, 'Pivot');
                return value ?? '';
              }
              if (e.value()) {
                return e.value();
              }
            },
          };

          return retValue;
        }
        case GlideDataType.DateTime:
        case GlideDataType.Date: {
          return {
            ...commonFieldConfig,
            dataType: DxPivotGridDataType.date,
          };
        }
        case GlideDataType.String:
        case GlideDataType.Object:
        case GlideDataType.LargeString: {
          const retValue = {
            ...commonFieldConfig,
            dataType: DxPivotGridDataType.string,
            summaryType: PivotSummaryType.Custom,
            calculateCustomSummary: (options: any) => {
              switch (options.summaryProcess) {
                case 'start':
                  options.TotalData = {};
                  break;
                case 'calculate':
                  if (!options.TotalData[options.value || 'no_value']) {
                    options.TotalData[options.value || 'no_value'] = options.value;
                  }
                  break;
                case 'finalize': {
                  // eslint-disable-next-line no-case-declarations
                  const arr = Object.values(options.TotalData);
                  const arrLength1 = (arr: any) => (arr.length === 1 ? arr[0] : '#mutlipleValues');
                  options.totalValue = arr.length === 0 ? '' : arrLength1(arr);
                  break;
                }
              }
            },
            selector: (data: any) => {
              return data[item.display_name];
            },
          };
          return retValue;
        }
        default:
          return {
            ...commonFieldConfig,
            dataType: DxPivotGridDataType.string,
          };
      }
    },
  );
};
