import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { validateForm, getErrorsPerFormField, populateFormWithErrors } from 'src/components/forms/common/validation';
import { setDefaultValuesForFormField } from 'src/components/forms/common/initialization';
import { formsSelector, FormsAction, FormData } from 'src/reducers/forms';

interface UseFormProps {
  onSubmit: (data: { [key: string]: { [key: string]: string } }) => void;
  onValidationError?: (errors: any) => void;
  objectKey?: string;
  initialEditing?: boolean;
}

export const useForm = ({ onSubmit, objectKey = 'order' }: UseFormProps) => {
  const { formChanges, ...formState } = useSelector(formsSelector);
  const dispatch = useDispatch();
  const [formSubmittedWithErrors, setFormSubmittedWithErrors] = useState(false);

  const setFormState = (formData: FormData) => {
    dispatch({
      type: FormsAction.SET_FORM,
      formData: { ...formState, ...formData },
    });
  };

  const onChangeField = (change: { [key: string]: string }) => {
    const [key, value] = Object.entries(change)[0];
    if (formState.formValues[key] === value) return;

    const newFormChanges = {
      ...formChanges,
      ...change,
    };

    const nextFormValues = {
      ...(formState?.formValues || {}),
      ...change,
    };

    const nextFormErrors = !formSubmittedWithErrors
      ? validateForm(change, formState?.formFields)
      : validateForm(nextFormValues, formState?.formFields);

    setFormState({
      ...formState,
      formErrors: nextFormErrors,
      formValues: nextFormValues,
      hasChanged: true,
      formChanges: newFormChanges,
    });
  };

  const onSave = (actionArguments?: any) => {
    const formValidationErrors = getErrorsPerFormField(formState);
    const thereAreErrors = Object.keys(formValidationErrors).length;
    if (thereAreErrors) {
      setFormSubmittedWithErrors(true);
      const formGroupsWithErrors = populateFormWithErrors(formValidationErrors, formState);
      return setFormState({ ...formState, ...formGroupsWithErrors });
    }
    if (actionArguments) {
      setFormState({ ...formState, ...setDefaultValuesForFormField(formState, actionArguments) });
    }
    onSubmit({ [`${objectKey}`]: formChanges });
  };

  return {
    onSave,
    onChangeField,
    setFormState,
    formState,
    formChanges,
  };
};
