import { Location as ReachLocation, LocationContext, Router } from '@reach/router';
import { appsLinks } from '@virtus/common/appLinks/appLinks';
import { authSelectors, GlideSession } from '@virtus/common/auth/reducer';
import getEnvFromUrl from '@virtus/common/utils/getEnvFromUrl';
import { VirtusTheme } from '@virtus/components';
import Loading from '@virtus/components/Loading';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import { AppLink, AppNames } from '@virtus/components/NavigationBar/components/AppMenu/AppMenu';
import { TopRightMenuItemProps } from '@virtus/components/NavigationBar/components/NavigationBarMenu/NavigationBarMenu';
import { Header } from '@virtus/components/page';
import React, { Suspense, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as S from 'src/app/App/App.style';
import AuthenticationHandlerGlide from 'src/app/App/AuthenticationHandlerGlide';
import { DbSelectorOverlay } from 'src/components/db-selector-overlay/db-selector-overlay';
import TabPanelNavigation from 'src/components/tab-panel-navigation/tab-panel-navigation';
import config, { GlideAppConfig } from 'src/config';
import configureHeader from 'src/config/headerConfig';
import { useEventListener } from 'src/hooks/useEventListener';
import { RootState } from 'src/reducers';
import { CustomGlideRoute, TabsAction, tabSelector, TabState } from 'src/reducers/tabs';
import GlideView from 'src/components/glide-view/glide-view';
import { GroupedList } from 'src/components/grouped-list/grouped-list';
import { globalActionSelector } from '../app.saga';
import { actions } from 'src/reducers/actions';
import { GlobalActionObject } from 'src/models/actions';
import './App.css';

const Credits = React.lazy(() => import(/* webpackPreload: true */ 'src/pages/Credits/Credits'));
const Deals = React.lazy(() => import(/* webpackPreload: true*/ 'src/pages/Deals/Deals'));

interface ReduxProps {
  getUsername: () => string;
  glideSession: GlideSession;
  tabs: TabState;
  globalActions: GlobalActionObject[];
}

interface ReduxDispatch {
  openNewTab: (path: string, routes?: any) => void;
  closeTab: (tab: CustomGlideRoute) => void;
  resolveAction: (action: any, targetUri?: string) => void;
}

interface OwnProps {
  config: GlideAppConfig;
}

type AppProps = ReduxProps & OwnProps & ReduxDispatch;

interface TopRightMenuItems {
  validator: boolean;
  items: TopRightMenuItemProps;
}

const getTopRightMenuItems = (itemsGroup: TopRightMenuItems[]): TopRightMenuItemProps[] => {
  return itemsGroup
    .filter(itemGroup => itemGroup.validator)
    .map(group => {
      return group.items;
    });
};

// Needed to be able to target Glide website from other portal apps
window.name = 'glide';

/**
 * This is to disable alert message from Muse lib.
 * @Note :- This will also override any javascript alert message in entire Glide application
 */
window.alert = (msg: string) => console.warn(`[Muse] ${msg}`);

const appMenuLinks: AppLink[] = [
  {
    name: AppNames.nexus,
    // @ts-ignore
    route: appsLinks.nexus[__ENVIRONMENT__],
    color: 'var(--fis-green)',
  },
  {
    name: AppNames.glide,
    // @ts-ignore
    route: appsLinks.glide[__ENVIRONMENT__],
    color: 'var(--default-blue)',
  },
  {
    name: AppNames.genesis,
    // @ts-ignore
    route: appsLinks.genesis[__ENVIRONMENT__],
    color: 'var(--purple-genesis)',
  },
];

/** Theme, Header and Routing */
const App: React.FC<AppProps> = ({
  getUsername,
  glideSession,
  openNewTab,
  closeTab,
  tabs,
  globalActions,
  resolveAction,
}) => {
  const [showDBDialog, setShowDBDialog] = useState(false);
  const handleOnCloseDBDialog = () => setShowDBDialog(false);

  const misc: JSX.Element = (
    <GroupedList
      data={globalActions}
      label="Global Actions"
      onChange={(_, v, r) => {
        if (r === 'select-option') {
          resolveAction(v);
        }
      }}
    />
  );
  const backHandler = () => {
    closeTab({
      fullPath: tabs.currentPath,
      isHighlighted: true,
      activeView: '',
    });
  };

  useEventListener('popstate', backHandler, window);

  const keyHandler = (event: KeyboardEvent) => {
    const winShortcut = event.altKey && event.key === '?';
    const osxShortcut = event.metaKey && event.key === '/';
    if (winShortcut || osxShortcut) return setShowDBDialog(true);
  };

  useEventListener('keydown', keyHandler);

  const clientSelector = useCallback(() => {
    if (getEnvFromUrl(['dev', 'local'], location.href) && glideSession) {
      return (
        <DbSelectorOverlay
          show={showDBDialog}
          onClose={handleOnCloseDBDialog}
          currentEnv={localStorage.getItem('client_env') || ''}
        />
      );
    }
    return null;
  }, [glideSession, showDBDialog]);

  const changeRoute = (path: string) => {
    openNewTab(path);
  };

  const initializingApp = (
    <Loading type={LoadingIconType.Glide} size={LoadingIconSizes.large} text="Initialising Glide Web..." show full />
  );

  return (
    <Suspense fallback={initializingApp}>
      <VirtusTheme>
        {!glideSession?.user && initializingApp}
        <AuthenticationHandlerGlide config={config} setClientEnv={setShowDBDialog}>
          {({ isGlideAuthenticated, login, logout }) => (
            <S.StyledApp>
              <ReachLocation>
                {(location: LocationContext) => {
                  const topRightMenuItems = getTopRightMenuItems([
                    {
                      validator: true,
                      items: {
                        text: 'Logout',
                        onClick: () => {
                          logout(config);
                        },
                      },
                    },
                  ]);
                  const headerConfiguration = configureHeader(tabs.routes);
                  return (
                    isGlideAuthenticated &&
                    tabs.routes &&
                    tabs.routes.length && (
                      <>
                        <Header
                          {...headerConfiguration}
                          appMenuLinks={appMenuLinks}
                          appMenuValue="Glide"
                          isLoggedIn={isGlideAuthenticated}
                          topRightButtonText={getUsername()}
                          path={location.location.pathname}
                          topRightMenuItems={topRightMenuItems}
                          onLogin={login}
                          enabledApps={[AppNames.nexus, AppNames.glide]}
                          appColor="var(--default-blue)"
                          misc={misc}
                          routeChangeCallback={changeRoute}
                          isHelpVisible={true}
                          helpURL={config.glide.baseURL + '/glide/help'}
                        />
                        <TabPanelNavigation />
                        <Router>
                          <Credits path="/credit_details" />
                          <Deals path="/deals" />
                          {tabs.routes.map((route: any) => {
                            if (!route || !route.uri) return;
                            return (
                              <GlideView
                                key={route.uri}
                                path={route?.path}
                                default={route?.defaultRoute}
                                category={route?.category}
                              />
                            );
                          })}
                        </Router>
                      </>
                    )
                  );
                }}
              </ReachLocation>
            </S.StyledApp>
          )}
        </AuthenticationHandlerGlide>
        {clientSelector()}
      </VirtusTheme>
    </Suspense>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  getUsername: () => authSelectors.getUsername(state),
  glideSession: authSelectors.glideSession(state),
  tabs: tabSelector(state),
  globalActions: globalActionSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatch => ({
  openNewTab: (path: string, routes: any) => dispatch({ type: TabsAction.OPEN_TAB, path, routes }),
  closeTab: (tab: CustomGlideRoute) => dispatch({ type: TabsAction.CLOSE_TAB, tab }),
  resolveAction: (action: any, targetUri?: string) =>
    dispatch({ type: actions.action.DISPATCH_ACTION, payload: { action, targetUri } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
