import formatDate from '@virtus/common/utils/dateFormater';
import React, { SyntheticEvent } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { colors } from 'src/theme';
import styled from 'styled-components';
import './Calendar.css';

export enum CalendarErrors {
  INVALID_DATE = 'Invalid Date',
  EMPTY_DATE = '--/--/--',
}

export interface DateFilterProps {
  value?: Date | string;
  style?: { [key: string]: React.CSSProperties };
  onChange: (e: any) => void;
  afterDisableDate?: Date;
  beforeDisableDate?: Date;
}

const modifiersStyles = {
  selected: { color: 'white', backgroundColor: colors.accent },
  today: { color: '#76C8FF', fontWeight: 600 },
  disabled: { cursor: 'not-allowed', color: '#717171' },
};

const Calendar: React.FunctionComponent<DateFilterProps> = ({
  value,
  style = {},
  onChange,
  afterDisableDate,
  beforeDisableDate,
}) => {
  const handleDateChange = (newDate: Date, modifiers: any, e: SyntheticEvent) => {
    e.stopPropagation();
    if (modifiers.disabled) return;

    const _date = formatDate(newDate);
    onChange(_date);
  };

  // Prevent error if the value is not a valid date
  if (value && typeof value !== 'string' && !value.getTime()) return null;

  const calendarValue: Date | undefined =
    (typeof value === 'string' && value === CalendarErrors.INVALID_DATE) || !value ? undefined : new Date(value);

  const disabledDays =
    afterDisableDate || beforeDisableDate ? { after: afterDisableDate, before: beforeDisableDate } : undefined;

  return (
    <CalendarWrapper data-testid="calendar" style={style.wrapper}>
      <DayPicker
        showOutsideDays
        onDayClick={handleDateChange}
        month={calendarValue}
        modifiersStyles={modifiersStyles}
        selectedDays={[calendarValue]}
        disabledDays={disabledDays as any}
      />
    </CalendarWrapper>
  );
};

const CalendarWrapper = styled.div`
  margin-top: 2px;
  color: white;
  width: 100%;
`;

export default Calendar;
