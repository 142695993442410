import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GlideObjectManagerComponent } from 'src/components/glide-object-manager/components/glide-object-manager';
import { useSelector } from 'react-redux';
import Search from '@virtus/components/Search';
import DropdownMenu, { DropdownMenuItem, DropdownMenuItemText } from '@virtus/components/DropdownMenu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { TimeSeriesDatePicker } from 'src/components/time-series-date-picker/time-series-date-picker';
import * as S from './glide-time-series.style';
import { useDispatch } from 'react-redux';
import { requestAsync } from 'redux-query';
import { getGlideTimeSeries } from 'src/api/queries/time-series/time-series';
import Loading from '@virtus/components/Loading';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import { useMutation } from 'redux-query-react';
import DeleteIcon from '@virtus/components/icons/DeleteIcon';
import { ComponentActionTypes, selectComponents } from 'src/reducers/components';
import { GlideTimeSeriesMutation } from 'src/api/mutations';
import { formattedDate } from 'src/components/forms/form-elements/form-fields/DateInput/DateInput';
import {
  EditableTabKeys,
  layoutRegion,
  primaryActionConfig,
} from '../glide-object-manager/components/glide-object-manager.model';

export interface GlideTimeSeriesProps {
  uri: string;
  seriesData: any;
  fields?: any[];
  isPending?: boolean;
  displayViewObj?: any;
}

interface overrideGridprops {
  allowDeleting?: boolean;
  usePagination?: boolean;
  skipkeyId?: boolean;
  ignoreDxSaveButton?: boolean;
  skipSchema?: boolean;
}

const overrideGridprops: overrideGridprops = {
  allowDeleting: false,
  usePagination: false,
  skipkeyId: true,
  ignoreDxSaveButton: true,
  skipSchema: true,
};

const searchStyle = {
  input: {
    height: '28px',
  },
  row: {
    margin: 0,
    height: '28px',
    borderRadius: '2px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
};
export const computeDayCount = (data: any): { date: any[]; count: number; freq: string } => {
  let date: any = [];
  let count = 0;
  let freq: string = 'B';
  if (data.dateType === 'single') {
    date = [...data.singleDate];
    count = date.length;
  } else if (data.dateType === 'range') {
    const toDate = data?.toDate && formattedDate(data.toDate).date;
    const fromDate = data?.fromDate && formattedDate(data.fromDate).date;

    date = toDate && fromDate ? [[fromDate, toDate]] : [];
    freq = data?.frequency;
  }
  return { date, count, freq };
};

export const GlideTimeSeries = ({ uri, seriesData, fields, isPending, displayViewObj }: GlideTimeSeriesProps) => {
  const { datePicker } = useSelector(selectComponents);
  const [{ isPending: isUpdatePending }, updateGlideTimeSeries] = useMutation((data: any, updatedData: any) =>
    GlideTimeSeriesMutation.post(data, updatedData),
  );

  const [searchString, setSearchString] = useState('');
  const [dateCount, setDateCount] = useState(
    seriesData?.data[0] ? Object.keys(seriesData?.data[0]).length - 1 : datePicker.count,
  );
  const [dateDropDownOpen, setDateDropDownOpen] = useState(false);
  const dispatch = useDispatch();
  const gridRef = useRef(null);

  // TODO: Update with correct functionality
  const onRefresh = () => {};

  const onInputChange = (value: string) => {
    if (value) {
      setSearchString(value);
    } else {
      filterGrid('');
    }
  };

  useEffect(() => {
    const dateColumnCount: number = seriesData?.data[0]
      ? Object.keys(seriesData?.data[0]).length - 1
      : datePicker.count;
    if (dateColumnCount >= 0 && dateColumnCount !== dateCount) {
      setDateCount(dateColumnCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seriesData?.data]);

  const onButtonSubmit = () => {
    filterGrid(searchString);
  };

  const filterGrid = (value: string): void => {
    (gridRef?.current as any).instance.filter('Trade Field', 'contains', value);
  };

  const toggleDateSelector = (
    visible: boolean,
    { count, date, dateConfig }: { count: number; date: any; dateConfig: any },
  ) => {
    dispatch({
      type: ComponentActionTypes.TOGGLE_COMPONENT_VISIBILITY,
      payload: { component: 'datePicker', props: { visible, count, date, dateConfig } },
    });
  };

  const inspectorOnCancel = () => {
    toggleDateSelector(false, datePicker);
  };

  const inspectorOnApply = useCallback((data: any) => {
    const { date, count, freq } = computeDayCount(data);
    dispatch(requestAsync(getGlideTimeSeries({ uri, date, fields, freq })));
    toggleDateSelector(false, { count, date, dateConfig: { ...data } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDateChange = (_: any) => {};

  const onDateButtonClick = () => {
    if (datePicker.visible) {
      toggleDateSelector(false, datePicker);
    } else {
      toggleDateSelector(true, datePicker);
    }
  };

  const onDateDropDownClose = useCallback(() => {
    setDateDropDownOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDateDropDownOpen = useCallback(() => {
    const { dateType } = datePicker.dateConfig;
    if (datePicker.date.length > 0 && dateType !== 'range') {
      setDateDropDownOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parseGridData = (gridData: any): any => {
    const changedRows = gridData.filter((item: any) => item.modified);
    const modifiedData: any = {};

    changedRows.forEach((row: any) => {
      row.modifiedValues.forEach((modifiedValue: any, index: number) => {
        if (modifiedValue !== undefined) {
          const columnName: string = Object.keys(row.key)[index];
          if (!modifiedData[columnName]) {
            modifiedData[columnName] = {
              [row.key['Trade Field']]: modifiedValue,
              date: columnName,
            };
          } else {
            modifiedData[columnName][row.key['Trade Field']] = modifiedValue;
          }
        }
      });
    });

    return {
      changedRows: Object.values(modifiedData).map((item: any) => ({ ...item, date: `${item.date}T00:00:00` })),
      updatedData: changedRows.map((item: any) => item.data),
    };
  };

  const customizeColumns = useCallback(
    (columns: any[]) => {
      const index: number = columns.findIndex(({ dataField }: any) => dataField === 'Trade Field');

      if (index > -1) {
        columns[index].allowEditing = false;
        columns[index].customizeText = ({ value }: any) => {
          const data = fields?.find((item: any) => item.name === value);
          return data ? data.display_name : '';
        };
      }
    },
    [fields],
  );

  const handleOnGridViewChangesSaved = useCallback(({ changedRows, updatedData }: any) => {
    const hasChanges = Object.keys(changedRows).length > 0;
    if (hasChanges) {
      updateGlideTimeSeries({ [uri]: changedRows }, updatedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteDate = useCallback(
    (selectedDate: string) => {
      const newDate = datePicker.date.filter((item: any) => item !== selectedDate);
      toggleDateSelector(datePicker.visible, {
        ...datePicker,
        date: newDate,
        count: newDate.length,
        dateConfig: { ...datePicker.dateConfig, singleDate: newDate },
      });
      dispatch(requestAsync(getGlideTimeSeries({ uri, date: newDate, fields })));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [datePicker.date],
  );

  const renderDateDropDown = useMemo(() => {
    const { dateType } = datePicker.dateConfig;
    if (datePicker.date.length === 0 || dateType === 'range') {
      return null;
    }
    return datePicker.date.map((item: string, index: number) => {
      const date = new Date(`${item} UTC`).toISOString().split('T')[0];

      return (
        <DropdownMenuItem key={index} style={{ cursor: 'default' }}>
          <DropdownMenuItemText style={{ marginRight: '5px' }}>{date}</DropdownMenuItemText>
          <S.DeleteIconWrapper onClick={() => deleteDate(item)}>
            <DeleteIcon />
          </S.DeleteIconWrapper>
        </DropdownMenuItem>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePicker.date, datePicker.dateConfig.dateType]);

  const isLoading = isPending || isUpdatePending;

  return (
    <>
      <S.HeaderPanel>
        <S.SearchContainer>
          <Search onChange={onInputChange} style={{ ...searchStyle }} />
          <S.SubmitButton onClick={onButtonSubmit}>Submit</S.SubmitButton>
        </S.SearchContainer>

        <S.DateFieldContainer>
          <S.DateInputContainer>
            <S.DateButton onClick={onDateButtonClick}>
              <S.CalendarIcon />
              <S.DateInput value={dateCount ? `${dateCount} Dates` : ''} readOnly />
            </S.DateButton>
          </S.DateInputContainer>
          {datePicker.visible && (
            <S.DatePickerWrapper>
              <TimeSeriesDatePicker
                onChange={onDateChange}
                dateObj={datePicker.dateConfig}
                inspectorOnApply={inspectorOnApply}
                inspectorOnCancel={inspectorOnCancel}
                calendarAlign="left"
              />
            </S.DatePickerWrapper>
          )}
          <DropdownMenu
            onOpen={onDateDropDownOpen}
            onClose={onDateDropDownClose}
            button={<S.DateDropDown>{dateDropDownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</S.DateDropDown>}
          >
            {renderDateDropDown}
          </DropdownMenu>
        </S.DateFieldContainer>
      </S.HeaderPanel>
      {isLoading ? (
        <Loading type={LoadingIconType.Glide} size={LoadingIconSizes.large} show full />
      ) : (
        <S.StyledRowsWrapper>
          <GlideObjectManagerComponent
            layout={layoutRegion.INSPECTOR}
            ref={gridRef}
            dataSource={seriesData}
            loading={false}
            fieldName={EditableTabKeys.CAPITAL_STRUCTURE}
            onRefresh={onRefresh}
            showHeader={false}
            overrideGridViewProps={overrideGridprops}
            onGridViewChangesSaved={handleOnGridViewChangesSaved}
            parseGridData={parseGridData}
            customizeGridColumns={customizeColumns}
            editObjectIcons={primaryActionConfig[EditableTabKeys.CAPITAL_STRUCTURE]}
            removeIconsConfig={['addRowButton', 'groupPanel', 'refreshButton', 'exportButton', 'columnChooserButton']}
            displayViewData={{ displayView: displayViewObj }}
          />
        </S.StyledRowsWrapper>
      )}
    </>
  );
};
