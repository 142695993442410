import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import { GlideConfig } from 'src/auth';
import { ExecuteCallback, NetworkHandler } from 'src/core-services/reduxQueryNetworkInterface';
import loadMockData from '../utils/loadMockData';

let glideCoreServiceClient: GlideAPIClient;

/**
 * Glide Client for Glide Web API
 * Requests are compatible with Redux Query API
 *
 */
export class GlideAPIClient {
  private readonly _axios: AxiosInstance;
  private cancelSource!: CancelTokenSource;
  private requestConfig!: AxiosRequestConfig;
  // Redux Query callback
  private cb?: ExecuteCallback;

  /**
   * @param baseURL Base URL for all API requests
   * @param mockRoutes
   */
  constructor({ baseURL, mockRoutes }: GlideConfig) {
    this._axios = axios.create({
      baseURL,
      timeout: 90 * 1000,
      method: 'POST',
    });

    // this loads Mocked endpoints via Mock Axios Adapter
    if (mockRoutes) loadMockData(this._axios, mockRoutes);

    // set up a request interceptor to set the auth token on each out going request
    this._axios.interceptors.response.use(
      (response: AxiosResponse) => this.handleResponse(response),
      (error: AxiosError) => this.handleError(error),
    );

    this.cancelSource = axios.CancelToken.source();
  }

  public setAuthHeaders(headers: any, clientEnvironment: string) {
    this._axios.defaults.headers.common['Authorization'] = headers.idToken;
    this._axios.defaults.headers.common['token'] = headers.accessToken;
    this._axios.defaults.headers.common['Client-Env'] = clientEnvironment;

    /*const client_env = localStorage.getItem('client_env');
    if (client_env && getEnvFromUrl(['local', 'dev', 'staging'], location.href)) {
      console.info(`setting ClientEnv header: ${client_env}`);
      this._axios.defaults.headers.common.ClientEnv = client_env;
    }*/
  }

  handleError(error: AxiosError): any {
    if (!error.response) {
      const errMsg = `Axios: No API response for ${error.config.method} to '${error.config.url}' : ${error.message}`;
      console.error(errMsg, error);
      return Promise.reject(new Error('No API response'));
    }

    const { response } = error;
    const { request, data } = response;

    // reject api call with error message
    const msg: string =
      (data && data.error_message) || (typeof data === 'string' && data) || error.message || '<no error message>';

    console.error(`HTTP ${response.status} for ${error.config.method} to '${request?.responseURL}'. 
      Error Message: ${msg}. Response status: ${response.statusText}`);

    if (this.cb) {
      const { status, headers } = response;
      this.cb(msg, status, data, '', headers);
    } else {
      return Promise.reject(new Error(`HTTP ${error.config.method} for ${request?.responseURL}`));
    }
  }

  handleResponse(response: AxiosResponse): any {
    const { request, data } = response;

    try {
      // Handle login request
      if (request && request.responseURL.endsWith('/authenticate')) {
        if (response.headers.authorization) {
          return { token: response.headers.authorization };
        }
        return { error_message: data?.error_message };
      }

      // Handle Redux Query cb
      if (this.cb) {
        const { status, headers } = response;
        if (data?.error_message) {
          this.cb(data?.error_message, status, data, '', headers);
        } else {
          this.cb(undefined, status, data, '', headers);
        }
      } else {
        return data;
      }
    } catch (e) {
      console.error(`Error reading headers.Authorization: ${e}`, e);
      return null;
    }
  }

  public async request(requestParams: AxiosRequestConfig = {}): Promise<any> {
    return this._axios(requestParams);
  }

  // interface methods for Redux Query below

  reduxQueryNetworkInterface(url: string, method: string, config: { body?: any; headers?: object }): NetworkHandler {
    this.requestConfig = {
      url,
      method,
      headers: {
        Authorization: this._axios.defaults.headers.common.Authorization,
        ...config.headers,
      },
      cancelToken: this.cancelSource.token,
    };

    this.requestConfig[method.toUpperCase() === 'GET' ? 'params' : 'data'] = config.body;

    return {
      execute: this.execute.bind(this),
      abort: this.abort.bind(this),
    };
  }

  public execute(cb: ExecuteCallback) {
    this.cb = cb;
    this._axios(this.requestConfig);
  }

  public abort() {
    this.cancelSource.cancel();
  }
}

// Enforce class usage as a singleton
export default function getGlideAPIClient(baseURL?: string) {
  if (glideCoreServiceClient) {
    return glideCoreServiceClient;
  }
  if (!baseURL) {
    throw Error('missing baseUrl to init glideAPIClient!');
  }
  glideCoreServiceClient = new GlideAPIClient({ baseURL });
  return glideCoreServiceClient;
}
