import React from 'react';
import { FormElementInputComponent } from 'src/components/forms/form-elements';
import { StyledToggleButton } from 'src/components/forms/form-elements/FormElements.style';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper';
import { useFieldRule } from 'src/components/forms/hooks/use-field-rule';

export const TogglerInput = ({
  field,
  onClick,
  value = '',
  error,
  onChange,
  formValues,
}: FormElementInputComponent) => {
  const fieldUpdateFn = (nextValue: any) => {
    const event: Partial<React.SyntheticEvent> = {
      target: {
        value: nextValue,
      } as HTMLInputElement,
    };
    onChange(event);
  };

  const fieldRule = useFieldRule({
    fieldName: field.displayName,
    fieldRules: field.fieldRules,
    formValues,
    fieldUpdateFn,
  });
  if (fieldRule.hidden) return null;

  return (
    <FormErrorWrapper
      error={error}
      displayName={field.displayName}
      name={field.name}
      disabled={fieldRule.disabled || field.disabled}
      required={fieldRule.required || field.required}
      readonly={fieldRule.disabled || field.readonly}
      disableErrorWarning={field.disableErrorWarning}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        {field.options &&
          Object.values(field.options).map((option, index) => (
            <StyledToggleButton
              data-testid={field.name}
              style={{ marginLeft: index === 0 ? '0' : 'inherit' }}
              disabled={fieldRule.disabled || field.disabled || field.readonly}
              backgroundcolor={
                value === option.name || (value === option.value && option.color) ? option.color : 'grey'
              }
              key={option.value}
              onClick={(e: any) => {
                onChange({
                  target: {
                    value: option.value,
                    addEventListener: () => null,
                    removeEventListener: () => null,
                    dispatchEvent: () => true,
                  },
                });

                if (onClick) onClick(e);
              }}
            >
              {option.name}
            </StyledToggleButton>
          ))}
      </div>
    </FormErrorWrapper>
  );
};
