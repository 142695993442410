import React from 'react';
import * as S from './compliance-cell-render.style';

interface ComplianceCellRenderProps {
  renderValue: string;
}

interface CellColor {
  [complianceStatus: string]: string;
}

const cellColor: CellColor = {
  ['Breach']: 'var(--red)',
  ['Rejected']: 'var(--red)',
  ['Approved']: 'var(--fis-green)',
  ['Passed']: 'var(--fis-green)',
};

export const ComplianceCellRender = ({ renderValue }: ComplianceCellRenderProps) => {
  return <S.ComplianceCellStyle color={cellColor[renderValue]}>{renderValue}</S.ComplianceCellStyle>;
};
