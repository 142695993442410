import { useEffect, useState } from 'react';
import { InspectorAction } from '@virtus/components/Inspector/components/InspectorActions';
import { store } from 'src/app/store';
import { actions as reduxActions } from 'src/reducers/actions';
import groupBy from 'lodash/groupBy';
import { WorkflowActions } from 'src/models/actions';
import { layoutRegion } from 'src/components/glide-object-manager/components/glide-object-manager.model';
import { getErrorsPerFormGroup } from 'src/components/forms/common/validation';
import { NotificationsAction } from 'src/reducers/notifications';

export type ISetFooterActionsfromActions = {
  rowDetails: any;
  isEditing?: boolean;
  layout?: layoutRegion;
  customPrimaryActions?: InspectorAction[];
  actionHandlers: { [key: string]: any };
  onWorkflowActionClicked?: (transition_uri: string) => void;
  formGroupsStateRef?: any;
};

export const getBgColor = (action: any) => {
  let bgcolor =
    action?.data?.editor_button_style?.split('/')[1] ||
    action?.data?.editor_button_style ||
    action?.editor_button_style ||
    'grey';
  if (bgcolor && bgcolor.toLowerCase() == 'blue') {
    bgcolor = '#0d83c9';
  } else if (bgcolor && bgcolor.toLowerCase() == 'green') {
    bgcolor = '#57b124';
  }
  return bgcolor;
};

const validateAddToScenario = (formGroupsStateRef: any) => {
  const formValidationErrors = getErrorsPerFormGroup(formGroupsStateRef?.current);
  const thereAreErrors = Object.keys(formValidationErrors).length;
  thereAreErrors > 0 &&
    store.dispatch({
      type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
      payload: { errorMessage: 'Please fill in all mandatory fields and Save order' },
    });
  return thereAreErrors;
};

export const useObjectActions = ({
  actionHandlers,
  rowDetails,
  isEditing,
  layout,
  customPrimaryActions = [],
  onWorkflowActionClicked,
  formGroupsStateRef,
}: ISetFooterActionsfromActions): any => {
  const [nestedPrimaryActions, setNestedPrimaryActions] = useState<any>(undefined);

  useEffect(() => {
    if (rowDetails?.data) {
      setNestedPrimaryActions(undefined);
    }
  }, [rowDetails]);

  const handleSubActions = (action: any) => {
    const subActions = action?.data?.actions;
    if (subActions && subActions?.length) {
      const newPrimaryActions = subActions.map((subAction: any) => {
        return {
          text: subAction.display_name,
          uri: subAction.uri,
          actionFn: () => {
            actionHandlers?.[subAction.uri]();
            setNestedPrimaryActions(undefined);
          },
          primary: true,
          props: {
            backgroundcolor: subAction.uri?.includes('canceledit') ? 'var(--error)' : undefined,
          },
        };
      });
      setNestedPrimaryActions(newPrimaryActions);
    }
  };

  const getActionsFromWorkflowActions = (workflowActions: WorkflowActions): InspectorAction[] =>
    Object.values(workflowActions).map(action => ({
      text: action.displayName,
      primary: false,
      props: {
        backgroundcolor: getBgColor(action),
        enabled: action?.enabled,
      },
      actionFn: () => onWorkflowActionClicked && onWorkflowActionClicked(action.uri),
    }));

  const workFlowActions = rowDetails?.workflowActions ? getActionsFromWorkflowActions(rowDetails?.workflowActions) : [];

  const setFooterActionsfromActions = rowDetails?.displayView?.data?.actions
    ? rowDetails?.displayView?.data?.actions?.map((action: any) => {
        const commonActionObj = {
          text: action?.data?.display_name,
          uri: action.uri,
          primary:
            action.uri === 'instance/actions/add_order_to_scenario' &&
            layout !== layoutRegion.BOTTOM &&
            layout !== layoutRegion.POPUP,
          props: {
            backgroundcolor: getBgColor(action),
            enabled: action?.data?.enabled,
            ordering: action?.data?.ordering,
          },
        };
        if (actionHandlers?.[action.uri]) {
          if (action.uri === 'instance/actions/edit_object') {
            if (isEditing && !nestedPrimaryActions) {
              handleSubActions(action);
            }
            return {
              ...commonActionObj,
              actionFn: () => {
                actionHandlers?.[action.uri]();
                handleSubActions(action);
              },
              primary: true,
            };
          }
          return { ...commonActionObj, actionFn: () => actionHandlers?.[action.uri]() };
        }

        return {
          ...commonActionObj,
          actionFn: () => {
            if (action.uri === 'instance/actions/add_order_to_scenario') {
              const error = validateAddToScenario(formGroupsStateRef);
              if (error) return;
            }
            store.dispatch({
              type: reduxActions.action.DISPATCH_ACTION,
              payload: { action, targetUri: rowDetails?.uri },
            });
          },
        };
      })
    : [];

  setFooterActionsfromActions.sort((a: any, b: any) => a.props?.ordering - b.props?.ordering);

  const allActions = rowDetails
    ? [...workFlowActions, ...setFooterActionsfromActions, ...customPrimaryActions]
    : [...customPrimaryActions];

  const { true: primaryAction, false: otherActions } = groupBy(allActions, 'primary');

  return {
    primaryAction,
    nestedPrimaryActions,
    setNestedPrimaryActions,
    otherActions,
  };
};
