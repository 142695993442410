import React, { useEffect, useRef } from 'react';
import { DashboardControl, ExtensionOptions, ResourceManager } from 'devexpress-dashboard';
import { StyledDashboard } from './dashboard.style';
import { RootState } from 'src/reducers';
import { authSelectors, GlideSession } from '@virtus/common/auth/reducer';
import { connect } from 'react-redux';
import 'jquery-ui/themes/base/all.css';
import './styles/dashboard.styles.css';
import 'devextreme/dist/css/dx.common.css';
import '@devexpress/analytics-core/dist/css/dx-analytics.common.css';
import '@devexpress/analytics-core/dist/css/dx-analytics.light.css';
import '@devexpress/analytics-core/dist/css/dx-querybuilder.css';
import 'devexpress-dashboard/dist/css/dx-dashboard.light.css';

export type DashboardProps = {
  endpoint: string;
  initialDashboardId: string;
  workingMode?: 'ViewerOnly';
  extensions?: ExtensionOptions;
};
export type ReduxProps = {
  glideSession: GlideSession;
};

type Props = DashboardProps & ReduxProps;

const Dashboard = React.memo(
  ({ endpoint, workingMode = 'ViewerOnly', extensions, glideSession, initialDashboardId }: Props) => {
    ResourceManager.embedBundledResources();
    const dash = useRef(null);
    useEffect(() => {
      const dashboardControl = new DashboardControl(dash.current as any, {
        // Specifies the URL where the Web Dashboard's server side is hosted.
        endpoint,
        workingMode,
        initialDashboardId,
        ajaxRemoteService: {
          headers: {
            Authorization: glideSession.token,
            ClientEnv: localStorage.getItem('Client_Env'),
          },
        },
        extensions: {
          'dashboard-export': {
            allowExportDashboardItems: true,
          },
          ...extensions,
        },
      });
      dashboardControl.render();
      return () => {
        dashboardControl.dispose();
      };
    }, [endpoint, workingMode, extensions, initialDashboardId, glideSession]);

    return (
      <StyledDashboard
        className="dx-swatch-glide-dashboard"
        ref={dash}
        style={{ width: '100%', height: '100%' }}
      ></StyledDashboard>
    );
  },
);

const mapStateToProps = (state: RootState): ReduxProps => ({
  glideSession: authSelectors.glideSession(state),
});

export default connect(mapStateToProps)(Dashboard);
