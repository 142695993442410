import styled, { css } from 'styled-components';
import { PrimaryButton } from '@virtus/components/Buttons';

const fullSize = css`
  width: 100%;
  height: 100%;
`;

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  ${fullSize}
  ${flexColumn}
  align-items: center;
  justify-content: flex-end;
`;

export const LoadingWrapper = styled.div`
  ${fullSize}
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg);
`;

export const Body = styled.div`
  ${fullSize}
  background: var(--darkblue);
  display: flex;
`;

export const Grid = styled.div`
  display: flex;
  flex: 3;
  height: 100%;
`;

export const Inspector = styled.div`
  ${fullSize}
  flex: 1;
  background: var(--darkblue);
  padding: 10px 10px 10px 0px;
  > div {
    border-top: 4px solid var(--border-top);
  }
`;
export const SearchAddButton = styled(PrimaryButton)`
  min-width: 1px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
`;
