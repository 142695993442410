import { BusinessCenter } from '@material-ui/icons';
import DropdownMenu from '@virtus/components/DropdownMenu';
import React, { CSSProperties, useEffect, useReducer } from 'react';
import * as S from './portfolio-fund-selector-style';
import { RootState } from 'src/reducers/rootReducer';
import { PortfolioSagaAction } from 'src/sagas/portfolio/portfolio.saga';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Glide, SearchQuery } from 'src/api/queries';
import { Search } from '@virtus/components/Search/Search';
import SortIcon from '@material-ui/icons/Sort';
import { PrimaryButton, TertiaryButton } from '@virtus/components/Buttons';
import { ListSelection } from '@virtus/components/list-selection';
import { selectedFundURIs } from 'src/reducers/portfolio';
import {
  fundSelectorReducer,
  initialState,
  sorttype,
  listPortfoliosAction,
  selectedFundAction,
  toggleAction,
  setFilteredPortfolios,
} from './portfolio-fund-selector.reducer';

interface ReduxProps {
  readonly portfolios: any;
  readonly fundURIs: string[];
  pendingSearch: boolean;
}

interface ReduxDispatch {
  fetchPortfolioNames: () => void;
  getPortfolioHoldings: (data: PortfolioQueryParams) => void;
}

interface PortfolioQueryParams {
  portfolioNames: string[];
  fundURIs: string[];
  clientViewConfiguration: any;
}

interface PortfolioManagerProps {
  clientViewConfiguration: any;
}

type Props = ReduxProps & ReduxDispatch & PortfolioManagerProps;

const portfolioDropdownBtnStyle: CSSProperties = {
  position: 'absolute',
  bottom: '0',
};

const PortfolioFundSelector: React.FunctionComponent<Props> = ({
  portfolios,
  fundURIs,
  pendingSearch,
  fetchPortfolioNames,
  getPortfolioHoldings,
  clientViewConfiguration,
}) => {
  const [state, dispatch] = useReducer(fundSelectorReducer, initialState);

  useEffect(() => {
    if (!portfolios && !pendingSearch) {
      fetchPortfolioNames();
    }
  }, []);

  useEffect(() => {
    if (portfolios && Array.isArray(portfolios)) {
      dispatch(listPortfoliosAction(portfolios));
      if (fundURIs) {
        dispatch(selectedFundAction(portfolios, fundURIs));
      }
    }
  }, [portfolios, fundURIs]);

  const onFilterChange = (value: string) => {
    const nextFilteredPortfolios = portfolios.filter(
      (portfolio: any) =>
        portfolio['data'].display_name != null &&
        portfolio['data'].display_name.toLowerCase().includes(value.toLowerCase()),
    );
    value.length === 0
      ? dispatch(setFilteredPortfolios(portfolios, sorttype.asc))
      : dispatch(setFilteredPortfolios(nextFilteredPortfolios, sorttype.asc));
  };

  const toggleSortingOrder = () => dispatch(toggleAction(portfolios));

  const onSearchClick = (e: React.SyntheticEvent<Element>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onSortFilterClick = (e: React.SyntheticEvent<Element>) => {
    e.stopPropagation();
    e.preventDefault();
    toggleSortingOrder();
  };

  const onMenuClose = () => dispatch(setFilteredPortfolios(portfolios, sorttype.asc));

  const onMenuOpen = () => {
    if (fundURIs && portfolios) dispatch(selectedFundAction(portfolios, fundURIs));
  };

  const onSelectedItemsChange = (args: any) => {
    if (args.name === 'selectedItemKeys') dispatch(selectedFundAction(portfolios, null, args.value));
  };

  const getPortfolioPositions = () => {
    const filteredPortfolioNames: any[] = [];
    state.selectedItemKeys.map((uri: string) =>
      state.listDataSource.byKey(uri).then((dataItem: any) => {
        return filteredPortfolioNames.push(dataItem);
      }),
    );
    const data: PortfolioQueryParams = {
      fundURIs: filteredPortfolioNames,
      portfolioNames: filteredPortfolioNames.map((item: any) => item.data.display_name),
      clientViewConfiguration,
    };
    getPortfolioHoldings(data);
  };

  const clearPortfolioSelection = () => dispatch(selectedFundAction(portfolios, null, []));

  const renderListItem = (itemData: any) => (
    <span title={itemData.data.display_name}>{itemData.data.display_name}</span>
  );
  return (
    <S.Container>
      <DropdownMenu
        button={
          <S.IconButton>
            <BusinessCenter />
          </S.IconButton>
        }
        menuMarginTop={156}
        onClose={onMenuClose}
        onOpen={onMenuOpen}
        keepMenuOpen
      >
        <S.PortfolioDropdownContentWrapper>
          <S.SearchContainer>
            <Search
              data-testid="layouts-filter-input"
              style={{ input: { backgroundColor: 'var(--label)', marginRight: '5px' } }}
              onChange={onFilterChange}
              onClick={onSearchClick}
            />
            <SortIcon
              style={{ display: 'flex', color: 'white', marginRight: '-5px', cursor: 'pointer' }}
              onClick={onSortFilterClick}
            />
          </S.SearchContainer>
          <S.MenuItem>
            <ListSelection
              dataSource={state.listDataSource}
              width={250}
              showSelectionControls
              selectionMode="all"
              selectAllMode="allPages"
              pageLoadMode="scrollBottom"
              focusStateEnabled={false}
              selectedItemKeys={state.selectedItemKeys}
              onOptionChanged={onSelectedItemsChange}
              itemRender={renderListItem}
              items={state.filteredPortfolios}
              useNativeScrolling
              showScrollbar="never"
            />
          </S.MenuItem>
          <S.PortfolioDropdownFooter>
            <TertiaryButton
              data-testid="portfolio-dropdown-clear-all-btn"
              style={{
                ...portfolioDropdownBtnStyle,
                left: '10px',
              }}
              disabled={Boolean(state.selectedItemKeys.length === 0)}
              onClick={clearPortfolioSelection}
            >
              Clear All
            </TertiaryButton>
            <PrimaryButton
              data-testid="portfolio-dropdown-apply-btn"
              variant="contained"
              color="secondary"
              style={{
                ...portfolioDropdownBtnStyle,
                right: '10px',
              }}
              disabled={Boolean(state.selectedItemKeys.length === 0)}
              onClick={getPortfolioPositions}
            >
              Apply
            </PrimaryButton>
          </S.PortfolioDropdownFooter>
        </S.PortfolioDropdownContentWrapper>
      </DropdownMenu>
    </S.Container>
  );
};

const mapStateToProps = (state: RootState, ownProps: any): ReduxProps => {
  const { clientViewConfiguration } = ownProps;
  return {
    portfolios: Glide.selector(state, 'portfolios'),
    fundURIs: selectedFundURIs(state, clientViewConfiguration),
    pendingSearch: SearchQuery.pending(state),
  };
};

const mapDispatchToProps = (dispatch: any): ReduxDispatch => ({
  fetchPortfolioNames: () => dispatch({ type: PortfolioSagaAction.FETCH_PORTFOLIO_NAMES }),
  getPortfolioHoldings: ({ portfolioNames, fundURIs, clientViewConfiguration }: PortfolioQueryParams) =>
    dispatch({
      type: PortfolioSagaAction.FETCH_PORTFOLIO_HOLDINGS,
      payload: {
        fullPath: clientViewConfiguration?.uri.lastSplitValue() === 'portfolio' ? '/portfolio' : '/portfolio-analyzer',
        tabName: portfolioNames,
        fundURIs,
        clientViewConfiguration,
      },
    }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(PortfolioFundSelector);
