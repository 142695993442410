import React, { useEffect } from 'react';
import { endpoints } from 'src/api/constants';
import { updateEntities } from 'redux-query';
import { store } from 'src/app/store';
import { notificationSelector } from 'src/reducers/notifications';
import { selector } from 'src/api/views/client-view/ClientView';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/reducers/rootReducer';
import { mapOrderDetailsToFormElements } from 'src/mappers/order-details/order-details';
import OverlayGroups from 'src/components/overlay-groups/overlay-group';
import { NotificationsAction } from 'src/reducers/notifications';
import { OrdersSagaAction } from 'src/sagas/orders/orders.saga';
import { selectComponents } from 'src/reducers/components';
import { STORE_VIEW_NAME_GLOBAL_ORDER } from 'src/utils/constants';
import { dispatchActions } from 'src/app/store';

export const GlobalOrderPopup = () => {
  const dispatch = useDispatch();
  const objectKey = 'new_order_with_instruments';
  const notification = useSelector(notificationSelector);
  const { global } = useSelector(selectComponents);
  const orders_details = useSelector((state: RootState) => selector(state, STORE_VIEW_NAME_GLOBAL_ORDER) || {});
  const resetOrderDetails = () => {
    store.dispatch(
      updateEntities({
        views: (prev: any, _: any) => ({
          ...prev,
          [`${STORE_VIEW_NAME_GLOBAL_ORDER}`]: {},
        }),
      }),
    );
  };

  const onSubmitOrder = (formChanges: any) => {
    const thereAreOrderChanges = Object.keys(formChanges[objectKey]).length > 0;
    if (thereAreOrderChanges) {
      const orderData = { [`${orders_details.uri}`]: { ...formChanges[objectKey] } };
      dispatch({
        type: OrdersSagaAction.NEW_ORDER_CREATION,
        payload: {
          orderData: orderData,
        },
      });
    }
  };

  const closeOverlayHandler = () => cancelOrder();

  const cancelOrder = () => {
    dispatchActions.components.update('global', { orders: { displayOverlay: false, active: false } });
    const CancelWorkflow = orders_details?.displayViewData?.workflow_transitions?.find(
      (wf: any) => wf.data.transition_end_value === 'Cancelled',
    );
    if (CancelWorkflow?.uri) {
      dispatchActions.db.update({
        endpoint: endpoints.order.workflowTransition,
        options: { method: 'PUT' },
        body: {
          uri: orders_details?.uri,
          transition_uri: CancelWorkflow?.uri,
        },
      });
    }

    dispatch({ type: NotificationsAction.RESET_NOTIFICATION });
    resetOrderDetails();
  };

  useEffect(() => {
    if (orders_details && orders_details?.uri) {
      dispatchActions.components.update('global', { orders: { displayOverlay: true } });
    }
  }, [orders_details]);

  const overlayCancelButton = { action: cancelOrder };

  const overlayPrimaryButton = { action: onSubmitOrder };

  useEffect(() => {
    const notificationObject = notification as any;
    if (
      notificationObject &&
      notificationObject?.type === 'success' &&
      notificationObject?.title === 'New Order Form'
    ) {
      dispatchActions.db.fetch({
        endpoint: endpoints.displayView.groups,
        body: {
          uri: notificationObject?.resolved_entity_uri,
          fetch_options: 'workflow_transitions',
          expand_prop: 'actions',
        },
        storeViewName: STORE_VIEW_NAME_GLOBAL_ORDER,
      });
    }
  }, [notification]);

  return (
    <OverlayGroups
      show={global?.orders?.displayOverlay}
      title="New Order"
      onCloseCallback={closeOverlayHandler}
      formGroups={orders_details}
      primaryButton={overlayPrimaryButton}
      cancelButton={overlayCancelButton}
      objectKey={objectKey}
      formGroupMapper={mapOrderDetailsToFormElements}
    />
  );
};
