import { Notification, NotificationType } from '@virtus/components/SnackBarNotification/SnackBarContent';
import { dispatchActions, store } from 'src/app/store';
import { RootState } from 'src/reducers/rootReducer';
import { HypoTestResult } from 'src/components/test-results-overlay/test-results-overlay';
import { ImportInstrumentResultTypes, ImportInstrumentsOverlayTitle } from 'src/utils/constants';
import { TabActions } from 'src/sagas/tabs.saga';
import { GlideViewActions } from 'src/sagas/glide-view.saga';

export type GlideNotificationType =
  | 'hypo_scenarios_run_complete'
  | 'hypo_scenarios_run_processing'
  | 'new_hypo_scenario'
  | 'hypothetical_scenario'
  | 'action_resolved'
  | 'create_new_hypo_order'
  | 'create_new_order'
  | 'import_marketmap_instruments_complete'
  | 'create_new_order_with_instrument_behaviour'
  | 'success'
  | 'error'
  | 'warning';

export enum GlideNotificationTypes {
  hypo_scenarios_run_complete = 'hypo_scenarios_run_complete',
  hypo_scenarios_run_processing = 'hypo_scenarios_run_processing',
  new_hypo_scenario = 'new_hypo_scenario',
  hypothetical_scenario = 'hypothetical_scenario',
  create_new_hypo_order = 'create_new_hypo_order',
  create_new_order = 'create_new_order',
  action_resolved = 'action_resolved',
  import_marketmap_instruments_complete = 'import_marketmap_instruments_complete',
  create_new_order_with_instrument_behaviour = 'create_new_order_with_instrument_behaviour',
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

export interface NotificationState {
  notification: Notification | null;
  history: Notification[];
}

interface NotificationPayload {
  notification?: Notification;
  [key: string]: any; // notification params
}

export const NOTIF_ACTION = (glideNotification: GlideNotificationTypes) => `NOTIF_${glideNotification.toUpperCase()}`;

const initialState = {
  notification: null,
  history: [],
};

export enum ReduxQueryActionType {
  MUTATE_FAILURE = '@@query/MUTATE_FAILURE',
  MUTATE_START = '@@query/MUTATE_START',
  MUTATE_SUCCESS = '@@query/MUTATE_SUCCESS',
  REQUEST_FAILURE = '@@query/REQUEST_FAILURE',
  REQUEST_START = '@@query/REQUEST_START',
  RESET = '@@query/RESET',
  // Not watched yet
  REQUEST_ASYNC = '@@query/REQUEST_ASYNC',
  REQUEST_SUCCESS = '@@query/REQUEST_SUCCESS',
  CANCEL_QUERY = '@@query/CANCEL_QUERY',
  MUTATE_ASYNC = '@@query/MUTATE_ASYNC',
  UPDATE_ENTITIES = '@@query/UPDATE_ENTITIES',
}

export enum NotificationsAction {
  NOTIFICATION = 'NOTIFICATION',
  ACTION_RESOLVED_NOTIFICATION = 'ACTION_RESOLVED_NOTIFICATION',
  ERROR_NOTIFICATION = 'ERROR_NOTIFICATION',
  VALIDATION_ERROR_NOTIFICATION = 'VALIDATION_ERROR_NOTIFICATION',
  PENDING_NOTIFICATION = 'PENDING_NOTIFICATION',
  RESET_NOTIFICATION = 'RESET_NOTIFICATION',
  RESET_ALL_NOTIFICATIONS = 'RESET_ALL_NOTIFICATIONS',
  // Redux Query notifications
  MUTATE_START = 'NOTIF_MUTATE_START',
  MUTATE_SUCCESS = 'NOTIF_MUTATE_SUCCESS',
  NOTIF_ON_POPUP = 'NOTIF_ON_POPUP',
}

export const notificationMessageMap = { MutateSuccessMessage: 'Update complete' };

export const MUTATE_START_NOTIFICATION = (title = 'Updating...'): Notification => ({
  title,
  type: 'info',
  message: 'Update in progress...',
});

export const MUTATE_SUCCESS_NOTIFICATION = (title = notificationMessageMap.MutateSuccessMessage): Notification => ({
  title,
  type: 'success',
  message: notificationMessageMap.MutateSuccessMessage,
  autoHideDuration: 3000,
});

const NOTIFICATION = (
  title: string = 'processing request...',
  message: string = 'Your request will complete soon',
): Notification => ({
  title,
  message,
  type: 'info',
});

const ERROR_NOTIFICATION = (errorMessage: string): Notification => ({
  type: 'error',
  title: 'Error processing request...',
  message: errorMessage,
});

const VALIDATION_ERROR_NOTIFICATION = (errorMessage: string): Notification => ({
  type: 'warning',
  title: 'Validation Failed',
  message: errorMessage,
  autoHideDuration: 5000,
});

const PENDING_NOTIFICATION = (message: string = 'Your request will complete soon'): Notification => ({
  type: 'info',
  title: 'Processing request...',
  message,
});

type NOTIFICATION_TEXT = { [key in NotificationType]: { title: string; message?: string } };

const NOTIFICATION_TEXT: NOTIFICATION_TEXT = {
  success: {
    title: 'Action complete',
  },
  warning: {
    title: 'Warning',
  },
  error: {
    title: 'Action failed',
    message: 'Error processing request...',
  },
  info: {
    title: 'Info',
  },
};

export const notifications = (
  state: NotificationState | any = initialState,
  action: { type: NotificationsAction & GlideNotificationTypes & ReduxQueryActionType; payload: NotificationPayload },
) => {
  switch (action.type) {
    case NotificationsAction.NOTIFICATION:
      return {
        notification: NOTIFICATION(action?.payload?.title),
      };
    case NotificationsAction.MUTATE_START:
      return {
        ...state,
        notification: MUTATE_START_NOTIFICATION(action.payload.title),
      };
    case NotificationsAction.MUTATE_SUCCESS:
      return {
        ...state,
        notification: MUTATE_SUCCESS_NOTIFICATION(action.payload?.title),
      };

    case NotificationsAction.ERROR_NOTIFICATION:
      return {
        ...state,
        notification: ERROR_NOTIFICATION(action.payload.errorMessage || 'Request failed'),
      };
    case NotificationsAction.VALIDATION_ERROR_NOTIFICATION:
      return {
        notification: VALIDATION_ERROR_NOTIFICATION(action.payload.errorMessage),
      };
    case NotificationsAction.PENDING_NOTIFICATION:
      return {
        ...state,
        notification: PENDING_NOTIFICATION(),
      };
    case NotificationsAction.RESET_NOTIFICATION:
      if (action.payload) {
        return {
          ...state,
          notification: {
            ...action.payload,
          },
        };
      } else {
        return {
          ...state,
          notification: null,
        };
      }
    case NotificationsAction.RESET_ALL_NOTIFICATIONS:
      return {
        notification: null,
        history: [],
      };
    // GlideNotificationTypes
    // Generic egg handler
    case NOTIF_ACTION(GlideNotificationTypes.action_resolved): {
      const testResultDetails = action?.payload?.test_result_details;
      const newState = {
        ...state,
        notification: NOTIFICATION('Action complete', ' '),
      };
      if (testResultDetails) {
        testResultDetails.forEach((test: HypoTestResult) => (test.datagrid.data = JSON.parse(test.datagrid.data)));
        newState['test_result_details'] = action.payload.test_result_details;
        newState['notification'] = NOTIFICATION('Test Results available', 'Test details will open soon...');
      }
      return newState;
    }
    case NOTIF_ACTION(GlideNotificationTypes.hypo_scenarios_run_complete): {
      const hypoScenarioId = action.payload.resolved_entity_uri.replace('instance/hypothetical_scenarios/', '');
      let egg_payload = action.payload?.data?.hypo_scenario;
      egg_payload = egg_payload && JSON.parse(egg_payload);
      let notification = {
        type: 'info',
        title: `Hypothetical scenario ${hypoScenarioId} test run complete`,
        // TODO: Fix issue where transition is broken if message is missing
        message: action?.payload?.message ?? ' ',
        actionLinks: [
          {
            title: 'View Hypo Test Results',
            onClick: () => {
              store.dispatch({
                type: GlideViewActions.OPEN_BOTTOM_PANEL,
                payload: egg_payload,
              });
            },
          },
        ],
      };

      if (egg_payload) {
        if (egg_payload['Current Result'] === 'Failed') {
          notification = {
            ...notification,
            type: 'error',
            title: `Hypothetical scenario ${hypoScenarioId} completed with errors`,
          };
        }
        if (egg_payload['Current Result'] === 'Error') {
          notification = {
            ...notification,
            type: 'warning',
            title: `Hypothetical scenario ${hypoScenarioId} failed to run`,
            actionLinks: [],
          };
        }
      }

      return {
        ...state,
        notification,
        // Add to notification history.
        // To automatically keep history of other request in the future, we could use a request meta property
        history: [...state.history, notification],
      };
    }
    case NOTIF_ACTION(GlideNotificationTypes.import_marketmap_instruments_complete): {
      const results: Notification = getImportInstrumentMessages(action?.payload);
      return {
        ...state,
        notification: {
          ...results,
          actionLinks: [
            {
              title: 'View Messages',
              onClick: () => {
                store.dispatch({
                  type: NotificationsAction.NOTIF_ON_POPUP,
                  payload: {
                    ...results,
                    showMessagesOnPopup: true,
                  },
                });
              },
            },
          ],
        },
      };
    }
    case NOTIF_ACTION(GlideNotificationTypes.create_new_order_with_instrument_behaviour): {
      return {
        ...state,
        notification: {
          type: 'success',
          title: 'New Order Form',
          message: 'Order form creation in progress',
          ...action.payload,
        },
      };
    }
    case NotificationsAction.NOTIF_ON_POPUP:
      return {
        ...state,
        notification: {
          ...action?.payload,
          showMessagesOnPopup: action?.payload?.showMessagesOnPopup,
        },
      };

    case NOTIF_ACTION(GlideNotificationTypes.create_new_order): {
      const actionLinks = [
        {
          title: 'View Order Blotter',
          onClick: () => {
            store.dispatch({
              type: TabActions.OPEN_VIEW,
              data: { path: '/Order-Management', activeView: 'instance/client_view_configuration/order_blotter' },
            });
            store.dispatch({ type: NotificationsAction.RESET_NOTIFICATION });
          },
        },
      ];

      // This is a workaround for bug on demo when gactionresult returns empty payload for new orders...
      // https://virtusllc.visualstudio.com/AlphaKinetic/_workitems/edit/84733
      if (action?.payload?.resolved_entity_uri) {
        actionLinks.push({
          title: 'View Order Details',
          onClick: () => {
            store.dispatch({
              type: TabActions.OPEN_VIEW,
              data: { path: '/Order-Management', activeView: 'instance/client_view_configuration/order_blotter' },
            });
            // use static inspector
            dispatchActions.components.updateView('inspector', 'instance/client_view_configuration/order_blotter', {
              uri: action.payload.resolved_entity_uri,
              isCollapsed: false,
              isEdit: true,
            });
            store.dispatch({ type: NotificationsAction.RESET_NOTIFICATION });
          },
        });
      }

      return {
        ...state,
        notification: {
          type: 'success',
          title: 'New order placed',
          message: 'Your new order has been created',
          actionLinks,
        },
      };
    }
    case NOTIF_ACTION(GlideNotificationTypes.create_new_hypo_order):
      return {
        ...state,
        notification: {
          type: 'success',
          title: 'Hypo order placed',
          message: 'Your new hypo order has been created',
          actionLinks: [
            {
              title: 'View Hypo Order Blotter',
              onClick: () => {
                store.dispatch({
                  type: TabActions.OPEN_VIEW,
                  data: {
                    path: '/Order-Management',
                    activeView: 'instance/client_view_configuration/hypo_order_blotter',
                  },
                });
                store.dispatch({ type: NotificationsAction.RESET_NOTIFICATION });
              },
            },
            {
              title: 'View Hypo Order Details',
              onClick: () => {
                store.dispatch({
                  type: TabActions.OPEN_VIEW,
                  data: {
                    path: '/Order-Management',
                    activeView: 'instance/client_view_configuration/hypo_order_blotter',
                  },
                });
                dispatchActions.components.updateView(
                  'inspector',
                  'instance/client_view_configuration/hypo_order_blotter',
                  {
                    isCollapsed: false,
                    isEdit: true,
                    uri: action.payload.resolved_entity_uri,
                  },
                );
                store.dispatch({ type: NotificationsAction.RESET_NOTIFICATION });
              },
            },
          ],
        },
      };
    case NOTIF_ACTION(GlideNotificationTypes.new_hypo_scenario):
    case NOTIF_ACTION(GlideNotificationTypes.hypothetical_scenario):
      return {
        ...state,
        notification: {
          type: 'success',
          title: 'Order added to new scenario',
          message: 'Order has been added to your new hypo scenario',
          actionLinks: [
            {
              title: 'View Scenarios',
              onClick: () => {
                store.dispatch({
                  type: TabActions.OPEN_VIEW,
                  data: {
                    path: '/Compliance',
                    activeView: 'instance/client_view_configuration/hypothetical_scenario_blotter',
                  },
                });
                store.dispatch({ type: NotificationsAction.RESET_NOTIFICATION });
              },
            },
            {
              title: 'View Scenario Details',
              onClick: () => {
                store.dispatch({
                  type: TabActions.OPEN_VIEW,
                  data: {
                    path: '/Compliance',
                    activeView: 'instance/client_view_configuration/hypothetical_scenario_blotter',
                  },
                });
                dispatchActions.components.updateView(
                  'inspector',
                  'instance/client_view_configuration/hypothetical_scenario_blotter',
                  {
                    isCollapsed: false,
                    isEdit: true,
                    uri: action.payload.resolved_entity_uri,
                  },
                );
                store.dispatch({ type: NotificationsAction.RESET_NOTIFICATION });
              },
            },
          ],
        },
      };
    case NOTIF_ACTION(GlideNotificationTypes.success):
    case NOTIF_ACTION(GlideNotificationTypes.warning):
    case NOTIF_ACTION(GlideNotificationTypes.info):
    case NOTIF_ACTION(GlideNotificationTypes.error): {
      const actionLinks = [];
      const title = action.payload?.title ?? NOTIFICATION_TEXT[action.type as NotificationType].title;
      const message = action.payload?.message ?? NOTIFICATION_TEXT[action.type as NotificationType]?.message;
      const objectUri = action?.payload?.object_uri;
      if (objectUri) {
        actionLinks.push({
          title: 'View Details',
          onClick: () => {
            dispatchActions.components.update('inspector', { visible: true, uri: objectUri });
            dispatchActions.form.setIsEdit();
            store.dispatch({ type: NotificationsAction.RESET_NOTIFICATION });
          },
        });
      }
      return {
        ...state,
        notification: {
          type: action.type,
          title,
          message,
          actionLinks,
        },
      };
    }
    default:
      return state;
  }
};
export const getImportInstrumentMessages = (results: any) => {
  let type: NotificationType = 'error';
  const messageList: any = [];
  let message = 'Request for import instruments failed';
  if (results?.resolved_entity) {
    const resolved_entity_results = JSON.parse(results?.resolved_entity);
    if (resolved_entity_results) {
      let completeCount = 0;
      let failedCount = 0;
      Object.entries(resolved_entity_results).forEach((eachEntry: any) => {
        const [key, value] = eachEntry;
        if (value.length) {
          messageList[key] = value.map((x: any) => x.Id + ' , ' + x.Outcome);
          if (key === ImportInstrumentResultTypes.COMPLETED) {
            completeCount = value.length;
          } else if (key === ImportInstrumentResultTypes.PARTIALLY_COMPLETED) {
            if (completeCount > 0) completeCount = completeCount + value.length;
            else completeCount = value.length;
          } else if (key === ImportInstrumentResultTypes.FAILED) {
            failedCount = value.length;
          }
        }
      });
      if (completeCount > 0 && failedCount > 0) {
        type = 'warning';
        message = `${completeCount} instruments imported successfully, ${failedCount} instrument couldn't imported. `;
      } else if (completeCount > 0) {
        type = 'success';
        message = `${completeCount} instruments imported successfully`;
      } else {
        type = 'error';
        message = `No instruments imported`;
      }
    }
  } else {
    messageList['Error'] = ['System error occurred'];
  }
  return { message, type, messageList, title: ImportInstrumentsOverlayTitle };
};

export const notificationSelector = (state: RootState) => state.notifications?.notification;
export const hypoTestResultDetailsSelector = (state: RootState) => state.notifications?.test_result_details || null;
export const historySelector = (state: RootState) => state.notifications.history;
