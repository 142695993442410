import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/reducers';
import {
  GlideObjectManagerComponent,
  GlideObjectManagerComponentProps,
} from 'src/components/glide-object-manager/components/glide-object-manager';
import { Changes } from 'src/components/glide-object-manager/model';
import {
  primaryActionConfig,
  EditableTabKeys,
} from 'src/components/glide-object-manager/components/glide-object-manager.model';
import { glideQuerySelectorViewName, isPendingQuerySelector } from 'src/api/query';
import { endpoints } from 'src/api/constants';
import { dispatchActions } from 'src/app/store';
import { actionTypes } from 'redux-query';

export type GlideObjectManagerProps = Omit<
  GlideObjectManagerComponentProps,
  'dataSource' | 'gridData' | 'onRefresh'
> & { onBack: () => void; objectCollectionUri: string };

type GridDataType = { object_uri: string; object_field_name: string; object_form_props: any; selected_row_data: any };

export const GlideObjectManager = React.memo(
  ({ onGridViewChangesSaved, selectedRowData, ...props }: GlideObjectManagerProps) => {
    const [objectCollectionUri, setObjectCollectionUri] = useState(props.objectCollectionUri);
    const [fieldName, setFieldName] = useState(props.fieldName);
    const [previousGridsData, setPreviousGridsData] = useState<Array<GridDataType>>([]);
    const [formProps, setFormProps] = useState(props.formProps);
    const [rowDataSelected, setRowDataSelected] = useState(selectedRowData);
    const dataGrid = useSelector((state: RootState) => glideQuerySelectorViewName(state, 'dataGridContent'));
    const isPending: boolean = useSelector((state: RootState) => isPendingQuerySelector(state, 'dataGridContent'));
    const getDataGridDetails = (body: any) => {
      dispatchActions.db.fetch({
        endpoint: endpoints.getCollection.rootObject,
        body: { object_uri: body.object_uri, object_field_name: body.object_field_name },
        options: {
          method: 'GET',
        },
        queryKey: 'dataGridContent',
        force: true,
      });
    };
    useEffect(() => {
      getDataGridDetails({ object_uri: objectCollectionUri, object_field_name: fieldName });
    }, []);

    const handleGetDataGrid = useCallback(
      () => getDataGridDetails({ object_uri: objectCollectionUri, object_field_name: fieldName }),
      [fieldName, objectCollectionUri],
    );

    const handleGetNestedDataGrid = useCallback(
      ({ object_uri, object_field_name, object_form_props, selected_row_data }: GridDataType) => {
        setPreviousGridsData(prevGridsData => [
          ...prevGridsData,
          ...[
            {
              object_uri: objectCollectionUri,
              object_field_name: fieldName,
              object_form_props: formProps,
              selected_row_data: rowDataSelected,
            },
          ],
        ]);
        getDataGridDetails({ object_uri, object_field_name });
        setFieldName(object_field_name);
        setObjectCollectionUri(object_uri);
        setFormProps(object_form_props);
        setRowDataSelected(selected_row_data);
      },
      [fieldName, objectCollectionUri, previousGridsData, formProps, rowDataSelected],
    );

    const handleNestedOnBack = useCallback(() => {
      const nextPreviousGridsData = [...previousGridsData];
      const previousGrid = nextPreviousGridsData.pop();
      if (!previousGrid) return props.onBack();
      setFieldName(previousGrid.object_field_name);
      setObjectCollectionUri(previousGrid.object_uri);
      setPreviousGridsData(nextPreviousGridsData);
      getDataGridDetails({ object_uri: previousGrid.object_uri, object_field_name: previousGrid.object_field_name });
      setFormProps(previousGrid.object_form_props);
      setRowDataSelected(previousGrid.selected_row_data);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousGridsData, props.onBack]);

    const handleOnGridViewChangesSaved = useCallback(
      ({ changedRows, newRows, currentAmountOfItems }: Changes) => {
        const hasChanges = Object.keys(changedRows).length > 0;
        const objectUpdating = fieldName
          .toLowerCase()
          .split('_')
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
        if (hasChanges || newRows.length > 0) {
          dispatchActions.db.update({
            endpoint: endpoints.update.rootObject,
            body: {
              object_uri: objectCollectionUri,
              [fieldName]: changedRows,
              new_objects: newRows,
            },
            queryKey: 'dataGridContent',
            options: { method: 'PUT' },
            meta: {
              notification: {
                [actionTypes.MUTATE_START]: `Saving ${objectUpdating}`,
                [actionTypes.MUTATE_SUCCESS]: `${objectUpdating} updated`,
              },
            },
          });
        }

        if (onGridViewChangesSaved) {
          // Notify parent
          onGridViewChangesSaved({ changedRows, newRows, currentAmountOfItems });
        }
      },
      [fieldName, objectCollectionUri, onGridViewChangesSaved],
    );
    return (
      <GlideObjectManagerComponent
        {...props}
        onRefresh={handleGetDataGrid}
        fieldName={fieldName}
        formProps={formProps}
        dataSource={{
          data: dataGrid?.data,
          schema: dataGrid?.schema,
          fieldRules: dataGrid?.fieldRules,
        }}
        overridePopupGOMProps={{
          onBack: handleNestedOnBack,
          getDataGrid: handleGetNestedDataGrid,
        }}
        displayViewData={{ ...dataGrid, displayView: { data: dataGrid?.displayViewData } }}
        selectedRowData={rowDataSelected}
        onGridViewChangesSaved={handleOnGridViewChangesSaved}
        editObjectIcons={primaryActionConfig[fieldName as EditableTabKeys]}
        removeIconsConfig={['revertButton']}
        loading={isPending}
      />
    );
  },
);

export default GlideObjectManager;
