import { updateEntities } from 'redux-query';
import { putResolve, select, takeLatest, put } from 'redux-saga/effects';
import { selectComponents, updateComponentAction } from 'src/reducers/components';
import { selector } from 'src/api/views/client-view/ClientView';
import { notificationSelector } from 'src/reducers/notifications';
import { STORE_VIEW_NAME_GLOBAL_ORDER } from 'src/utils/constants';

import { glideQuery } from 'src/api/query';
import { mutateAsync } from 'redux-query';
export enum OrdersSagaAction {
  NEW_ORDER_CREATION = 'NEW_ORDER_CREATION',
  CANCEL_ORDER = 'CANCEL_ORDER',
}

export function* createNewOrderOrChangeStatus(action: any) {
  yield putResolve(
    mutateAsync(
      glideQuery({
        endpoint: `/glide/order/update`,
        options: { method: 'PUT' },
        body: action?.payload?.orderData,
        storeViewName: STORE_VIEW_NAME_GLOBAL_ORDER,
      }),
    ),
  );

  const orderDetails = yield select(selector, STORE_VIEW_NAME_GLOBAL_ORDER);
  const componentsSelector = yield select(selectComponents);
  const notificationInfo = yield select(notificationSelector);
  if (notificationInfo.type === 'success' && notificationInfo.title === 'Update complete') {
    yield put(updateComponentAction('global', { orders: { displayOverlay: false } }));
    yield putResolve(
      updateEntities({
        views: (prev: any, _: any) => ({
          ...prev,
          [STORE_VIEW_NAME_GLOBAL_ORDER]: {},
        }),
      }),
    );
    if (orderDetails?.workflowActions && componentsSelector.global.orders.active && orderDetails?.uri) {
      yield put(updateComponentAction('global', { orders: { active: false } }));
      // Considering only Complete workflow action for global order (Incomplete => Pending).
      const { Complete } = orderDetails.workflowActions;
      if (Complete?.uri) {
        yield putResolve(
          mutateAsync(
            glideQuery({
              endpoint: `/glide/order/workflow-transition`,
              options: { method: 'PUT' },
              body: {
                uri: orderDetails?.uri,
                transition_uri: Complete.uri,
              },
            }),
          ),
        );
      }
    }
  }
}

export function* watchCreateNewOrderOrChangeStatus() {
  yield takeLatest<any>(OrdersSagaAction.NEW_ORDER_CREATION, createNewOrderOrChangeStatus);
}

export default [watchCreateNewOrderOrChangeStatus];
