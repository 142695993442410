import Tooltip from '@material-ui/core/Tooltip';
import { Close } from '@material-ui/icons';
import React, { useEffect, useRef } from 'react';
import useVisibleOnElement from 'src/hooks/useVisibleOnElement';
import { EllipsisText, Label, LoadingPlaceholderStyled } from 'src/ViewToolbar/components/ViewLabels';
import { ViewToolbarView } from 'src/ViewToolbar/ViewToolbar.model';
import styled from 'styled-components';
export interface ViewLabelProps {
  view: ViewToolbarView;
  onViewClick: (id: number, isReport?: boolean) => React.MouseEventHandler;
  active?: number;
  forwardRef?: React.Ref<any>;
  onClose?: (id: number, isReport?: boolean) => void;
  labelStyle?: any;
}

export const ViewLabel: React.FunctionComponent<ViewLabelProps> = ({
  forwardRef,
  view,
  onViewClick,
  active,
  onClose,
  labelStyle,
}) => {
  const { id, name, loading, isReport } = view;
  return (
    <Tooltip title={name} placement="top" style={{ flex: 1 }}>
      <Label
        ref={forwardRef}
        data-testid={`page-view-${name}`}
        active={id === active}
        isReport={view?.isReport}
        onClick={loading ? () => null : onViewClick(id, isReport)}
        style={{ ...labelStyle }}
      >
        {loading ? <LoadingPlaceholderStyled /> : <EllipsisText>{name}</EllipsisText> || <LoadingPlaceholderStyled />}
        {onClose && isReport && (
          <StyledClose
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onClose(id, isReport);
            }}
          />
        )}
      </Label>
    </Tooltip>
  );
};

export const ViewLabelWithVisibilityHandler = ({
  position,
  view,
  onViewClick,
  active,
  containerEl,
  visibilityHandler,
  onClose,
  labelStyle,
}: ViewLabelProps & {
  position: number;
  containerEl: Element | null;
  visibilityHandler: (id: number, isVisible: boolean, el: Element) => void;
}) => {
  const ref = useRef(null);
  let isVisible: any = useVisibleOnElement(ref, containerEl, position);

  useEffect(() => {
    if (visibilityHandler) {
      const curr = (ref as any)?.current;
      visibilityHandler(view.id, isVisible, curr);
    }
    return function cleanup() {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isVisible = null;
    };
  }, [isVisible]);

  return (
    <ViewLabel
      forwardRef={ref}
      view={view}
      onViewClick={onViewClick}
      active={active}
      onClose={onClose}
      labelStyle={labelStyle}
    />
  );
};

const StyledClose = styled(Close)`
  width: 13px;
  margin-top: -7px;
  margin-left: 7px;
  height: 13px;
  cursor: pointer;
  transition: 0.2s all;
  &:hover {
    background: #00000052;
  }
`;

export default ViewLabelWithVisibilityHandler;
