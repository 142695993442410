import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as S from './order-selection-panel.style';
import {
  ChangeBodyType,
  OrderSelectionTabsProps,
  Props,
  ReduxProps,
  StaticTabOrder,
} from './order-selection-panel.model';
import {
  EditableTabKeys,
  layoutRegion,
  primaryActionConfig,
} from 'src/components/glide-object-manager/components/glide-object-manager.model';
import TabPanel from 'devextreme-react/tab-panel';
import { RootState } from 'src/reducers/rootReducer';
import { connect } from 'react-redux';
import { glideDataTypeMap } from '@virtus/components/DxDataGrid/utils/mapSchemaGlide';
import { Context } from 'src/contexts/grid-theme/grid-theme.context';
import { notificationMessageMap, notificationSelector } from 'src/reducers/notifications';
import {
  getUpdatedRowsData,
  GlideObjectManagerComponent,
} from 'src/components/glide-object-manager/components/glide-object-manager';
import { getFormPropsForGlideObjectManager } from 'src/components/forms/glide-data-content';
import { GlideOrderDisplayView } from 'src/models/order/glideOrderDisplayView.api.model';
import { ViewElement } from 'src/models/api/viewElement';
import { glideQuerySelector, glideQuerySelectorViewName, isPendingQuerySelector } from 'src/api/query';
import { activeTabSelector } from 'src/reducers/tabs';
import { dispatchActions } from 'src/app/store';
import { selectComponents } from 'src/reducers/components';
import { endpoints } from 'src/api/constants';
import { actionTypes } from 'redux-query';

const sortTabDataSource = (dataSourceValues: { [key: string]: any }[]) => {
  const sortedTabs = StaticTabOrder.reduce((acc: any, value: any) => {
    const indexOfVal = dataSourceValues.findIndex((element: any) => element.key === value);
    if (indexOfVal > -1) {
      return [...acc, ...dataSourceValues.splice(indexOfVal, 1)];
    }
    return acc;
  }, []);
  return [...sortedTabs, ...dataSourceValues];
};
const updateBottomDataGridDetails = (body: ChangeBodyType, objectUpdating: string) => {
  dispatchActions.db.update({
    endpoint: endpoints.update.rootObject,
    body: body,
    options: { method: 'PUT' },
    queryKey: 'orderBottomPanelGrid',
    meta: {
      notification: {
        [actionTypes.MUTATE_START]: `Saving ${objectUpdating}`,
        [actionTypes.MUTATE_SUCCESS]: `${objectUpdating} updated`,
      },
    },
  });
};

const saveOrderCollection = (
  gridRef: any,
  tabDataSource: OrderSelectionTabsProps,
  currentTab: string,
  orderDetails: any,
) => {
  const currentData = gridRef?.current ? (gridRef?.current as any).instance.getController('data')._items : [];

  const [newRows, existingUpdatedRows] = getUpdatedRowsData(
    currentData,
    (gridRef as any)?.current?.instance.getController('editing')._editData,
  );
  const objectUpdating = tabDataSource.find((tabItem: any) => tabItem.key === currentTab) as any;
  updateBottomDataGridDetails(
    { object_uri: orderDetails.uri, [currentTab as string]: existingUpdatedRows, new_objects: newRows },
    objectUpdating['text'],
  );
};

// const CONFIRMATION_TEXT_CONTENT = 'Are you sure you want to discard changes?';
// const SAVE_CHANGE = 'Save change';

const getTabPanelDataSource = (orderDisplayViewData: GlideOrderDisplayView, bottomPanelFields: any[]) => {
  const { workflow_status_history, workflow_transitions, ...orderDisplayGroups } = orderDisplayViewData;
  const tabValues = Object.values(orderDisplayGroups).reduce((acc, values) => {
    const tabPanelKeys = values.reduce((accKey: any, field: ViewElement) => {
      if (typeof field.field === glideDataTypeMap.String) return accKey;
      const instance_uri: string = Object.keys(field.field)[0];
      const fieldName = instance_uri.replace('fields/', '');
      const firstField = Object.values(field.field)[0];
      const dataType: string = firstField.data_type.replace('lookups/', '');
      const displayName: string = firstField.display_name;
      if (dataType === 'objectcollection') {
        return [...accKey, { text: displayName, title: displayName, key: fieldName }];
      }
      return accKey;
    }, []);
    return [...acc, ...tabPanelKeys];
  }, []);

  const dataSource = [...bottomPanelFields].map(item => {
    if (tabValues.find((tab: any) => tab.key === item.key)) {
      return { ...item };
    }
    return { ...item, disabled: true };
  });

  return sortTabDataSource(dataSource);
};

// const isBottomPanelEdited = (tabRef: any) => {
//   const element = tabRef?.current?.instance.element().querySelectorAll('[title="Save changes"][tabindex="0"]')[0];
//   if (element) {
//     return !element.classList.contains('dx-state-disabled');
//   }
//   return false;
// };

const OrderSelectionPanel = ({
  orderDetails,
  bottomPanelFields,
  bottomDataGrid,
  isLoadingOrderDetails,
  notification,
  isBottomGridDataPending,
  selectedRowData,
  clientViewUri,
  components,
}: Props) => {
  const [currentTab, setCurrentTab] = useState<string | undefined>(undefined);
  const { useDarkTheme } = useContext(Context);
  const gridRef = useRef(null);
  const tabRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [displayViewUri, setDisplayViewUri] = useState('');
  const [tabDataSource, setTabDataSource] = useState<any>(sortTabDataSource([...bottomPanelFields]));
  const isExpanded =
    components?.viewComponents?.[clientViewUri as string]?.['bottomPanelExpandedState']?.['isExpanded'] ?? false;

  const getBottomGridData = (object_uri: string, object_field_name: string) => {
    dispatchActions.db.fetch({
      endpoint: endpoints.getCollection.rootObject,
      body: { object_uri, object_field_name },
      options: { method: 'GET' },
      queryKey: 'orderBottomPanelGrid',
    });
  };
  const handleTabChange = useCallback(
    (event: any) => {
      const currentTabKey = event?.key || '';
      setCurrentTab(currentTabKey);
      if (!isExpanded) return;
      if (orderDetails?.uri) {
        getBottomGridData(orderDetails.uri, currentTabKey);
      }
    },
    [orderDetails?.uri, isExpanded],
  );

  //code has been for future reference when decoupling handle confirmation popup
  // const closeDialog = useCallback(
  //   (event: any) => {
  //     if (event) {
  //       handleTabChange(event.itemData);
  //       setSelectedIndex(event.itemIndex);
  //     }
  //   },
  //   [handleTabChange],
  // );

  // const { DialogComponent: CancelEditConfirmDialog, onDispatcherClick: handleOnCancelScenarioWithConfirmation } =
  //   useConfirmationDialog({
  //     onClick: closeDialog,
  //     headerText: SAVE_CHANGE,
  //     bodyTextContent: CONFIRMATION_TEXT_CONTENT,
  //     bodyContentAlign: 'left',
  //   });

  const customDxToolbarButtonsActions = useMemo(
    () => ({
      saveButton: () => {
        saveOrderCollection(gridRef, tabDataSource, currentTab as string, orderDetails);
      },
    }),
    [currentTab, orderDetails, tabDataSource],
  );

  useEffect(() => {
    if (
      notification?.type === 'success' &&
      notification.title === notificationMessageMap.MutateSuccessMessage &&
      currentTab
    ) {
      getBottomGridData(orderDetails.uri, currentTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  useEffect(() => {
    if (orderDetails?.data) {
      setTabDataSource(getTabPanelDataSource(orderDetails.data, bottomPanelFields));
    }
  }, [bottomPanelFields, orderDetails?.data]);

  useEffect(() => {
    if (orderDetails?.uri) {
      setDisplayViewUri(orderDetails?.uri);
    }
  }, [orderDetails?.uri]);

  useEffect(() => {
    if (!isLoadingOrderDetails && isExpanded && currentTab && orderDetails?.uri) {
      getBottomGridData(orderDetails.uri, currentTab as string);
    }
  }, [isExpanded, orderDetails?.uri, currentTab]);

  const itemTitleRender = useCallback((props: any) => {
    const GridTitle = props?.text || '';
    return <span>{GridTitle}</span>;
  }, []);

  const bottomGrid = useCallback(() => {
    if ((!orderDetails?.uri && !isLoadingOrderDetails) || !isExpanded) {
      return undefined;
    }

    return (
      <>
        {bottomDataGrid && (
          <S.BottomPanelGridOverride>
            <GlideObjectManagerComponent
              layout={layoutRegion.BOTTOM}
              ref={gridRef}
              overrideBottomGOMProps={{
                useDarkTheme,
              }}
              fieldName={currentTab as string}
              onRefresh={() => getBottomGridData(orderDetails.uri, currentTab as string)}
              loading={isBottomGridDataPending || isLoadingOrderDetails}
              dataSource={{
                data: bottomDataGrid?.data,
                schema: bottomDataGrid?.schema,
                fieldRules: bottomDataGrid?.fieldRules,
              }}
              formProps={
                selectedRowData &&
                getFormPropsForGlideObjectManager({ field: currentTab || '', formValues: selectedRowData })
              }
              displayViewData={bottomDataGrid}
              selectedRowData={selectedRowData}
              customDxToolbarButtonsActions={customDxToolbarButtonsActions}
              editObjectIcons={primaryActionConfig[currentTab as EditableTabKeys]}
              removeIconsConfig={['revertButton']}
            ></GlideObjectManagerComponent>
          </S.BottomPanelGridOverride>
        )}
      </>
    );
  }, [
    isBottomGridDataPending,
    isLoadingOrderDetails,
    orderDetails?.uri,
    bottomDataGrid,
    currentTab,
    selectedRowData,
    useDarkTheme,
    isExpanded,
  ]);

  const onTitleClickHandler = useCallback(
    (e: any) => {
      const { itemData } = e;
      // if (isBottomPanelEdited(tabRef)) {
      //   //handleOnCancelScenarioWithConfirmation(e);
      //   return;
      // } else
      if (itemData && currentTab !== itemData.key) {
        handleTabChange(itemData);
      }

      // if (!isBottomPanelEdited(tabRef)) {
      setSelectedIndex(e.itemIndex);
      // }
      dispatchActions.components.updateView('bottomPanelExpandedState', clientViewUri, { isExpanded: true });
    },
    [
      currentTab,
      //handleOnCancelScenarioWithConfirmation,
      handleTabChange,
      isExpanded,
    ],
  );

  const onContentReady = useCallback(
    (e: any) => {
      const selectedItem = e.component.instance().option('selectedItem');
      if (!selectedItem) return;
      if (!selectedItem.disabled && !isLoadingOrderDetails && displayViewUri === orderDetails?.uri) {
        setCurrentTab(selectedItem.key);
      }
    },
    [displayViewUri, isLoadingOrderDetails, orderDetails?.uri],
  );

  return (
    <>
      {/* <CancelEditConfirmDialog /> */}
      <S.OrderSelectionTabHeader data-testid="order-bottom-tab-panel">
        <S.TabPanelOverride>
          <TabPanel
            ref={tabRef}
            dataSource={tabDataSource}
            itemTitleRender={itemTitleRender}
            itemRender={bottomGrid}
            onTitleClick={onTitleClickHandler}
            onContentReady={onContentReady}
            selectedIndex={selectedIndex}
          />
        </S.TabPanelOverride>
      </S.OrderSelectionTabHeader>
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: any): ReduxProps => {
  const { selectedRowData } = ownProps;
  return {
    orderDetails: glideQuerySelector(state, activeTabSelector(state), 'inspectorData'),
    isLoadingOrderDetails: isPendingQuerySelector(state, selectedRowData['_uri']),
    bottomDataGrid: glideQuerySelectorViewName(state, 'orderBottomPanelGrid'),
    isBottomGridDataPending: isPendingQuerySelector(state, 'orderBottomPanelGrid'),
    notification: notificationSelector(state),
    components: selectComponents(state),
  };
};

export default connect(mapStateToProps)(OrderSelectionPanel);
