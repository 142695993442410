import { authActions } from '@virtus/common/auth';
import { uniqBy } from 'lodash';
import groupBy from 'lodash/groupBy';
import { requestAsync } from 'redux-query';
import { put, putResolve, takeLatest } from 'redux-saga/effects';
import { ClientViewConfiguration } from 'src/components/glide-view/glide-view.model';
import { RootState } from 'src/reducers';
import { TabsAction } from 'src/reducers/tabs';
import { getType } from 'typesafe-actions';
import sortBy from 'lodash/sortBy';
import { glideQuery } from 'src/api/query';

export enum RoutesSagaAction {
  GET_CLIENT_VIEW_CONFIGURATION = 'GET_CLIENT_VIEW_CONFIGURATION',
}

export interface GlideSearchParams {
  object_type: string;
  search_fields?: object;
  fields?: string[];
  field_display_format?: 'uri' | 'lookups_display_name' | 'display_name' | 'summary';
  expand_prop?: string[];
  expand_fields_requested?: string;
}

export interface FetchClientViewConfigurations extends GlideSearchParams {
  payload: GlideSearchParams;
}

export interface RouteAction {
  type: RoutesSagaAction;
  payload: GlideSearchParams;
}

const getModuleRoutes = (data: any) =>
  data
    ?.map((cvc: ClientViewConfiguration) => {
      if (cvc?.data?.browser_region === 'BottomRegion') return;
      return {
        name: cvc.data.display_name,
        displayName: cvc.data.display_name,
        path: cvc.uri.substring(cvc.uri.lastIndexOf('/')),
        fullPath: cvc.uri.substring(cvc.uri.lastIndexOf('/')),
        uri: cvc.uri,
        category: cvc.data.category,
        is_default_view: cvc.data.is_default_view,
      };
    })
    .filter((routes: ClientViewConfiguration) => routes);

const getGlideViews = (routes: any) => {
  return (routes || []).reduce((acc: any, route: any) => {
    const _viewname = route?.path.replace('/', '');
    return { ...acc, [_viewname]: { displayName: route?.displayName, uri: route?.uri } };
  }, {});
};

export function* fetchCVCandActions() {
  const searchParams: GlideSearchParams = {
    object_type: 'client_view_configuration',
    expand_prop: ['actions_collection', 'static_menu_actions'],
  };

  const clientViewConfigurationResponse: any = yield putResolve(
    requestAsync(
      glideQuery({
        endpoint: '/gsearch',
        transform: (response: any) => {
          return { clientViewConfigurations: response };
        },
        options: { method: 'POST' },
        body: { ...searchParams, return_all_fields: true, expression: "enabled=true&category!=''" },
        update: {
          clientViewConfigurations: (_: any, next: any) => next,
        },
      }),
    ),
  );

  yield putResolve(
    requestAsync(
      glideQuery({
        endpoint: '/gsearch',
        transform: (response: any) => {
          return { globalActions: response };
        },
        options: { method: 'POST' },
        body: { object_type: 'global_actions', return_all_fields: true },
        update: {
          globalActions: (_: any, next: any) => next,
        },
      }),
    ),
  );

  const groupedCVCData: any = groupBy(
    uniqBy(clientViewConfigurationResponse?.body, 'uri'),
    (el: ClientViewConfiguration) => el.data.category,
  );

  let groupedRoutes: any = Object.keys(groupedCVCData).map(category => {
    const modulesRoutes = groupedCVCData[`${category}`];

    const subroutes = getModuleRoutes(sortBy(modulesRoutes, route => route.data.display_name));
    return {
      name: category,
      displayName: category,
      category: category,
      path: category.trim().replace(/\s/g, '-'),
      fullPath: category,
      uri: modulesRoutes[0].uri,
      subroutes,
      views: getGlideViews(subroutes),
    };
  });

  // @ts-ignore
  groupedRoutes = groupedRoutes.concat([
    // {
    //   name: 'x Credit Details',
    //   path: 'credit_details',
    //   displayName: 'x Credit Details',
    //   fullPath: '/credit_details',
    // },
    // {
    //   name: 'x Deals',
    //   displayName: 'x Deal Pipeline',
    //   category: 'x Deals',
    //   path: 'deals',
    //   fullPath: '/deals',
    // },
  ]);

  // put new module Routes into reducers
  yield put({
    type: TabsAction.ADD_ROUTE,
    routes: sortBy(groupedRoutes, route => route.displayName),
  });
}

// export const routeSelector = (state: RootState) => state.entities.actionResult;
export const cvcDataSelector = (state: RootState) => state.entities.clientViewConfigurations;
export const globalActionSelector = (state: RootState) => state.entities.globalActions;
export const pending = (state: RootState) => state.queries.resolveAction && state.queries.resolveAction.isPending;

export function* watchFetchCVCandActions() {
  yield takeLatest(getType(authActions.glideLoginSuccess), fetchCVCandActions);
}

export default [watchFetchCVCandActions];
