import React, { useEffect, useState } from 'react';

const useVisibleOnElement = (
  ref: React.RefObject<any>,
  root: Element | null = null,
  key?: string | number,
): boolean => {
  const [isNotIntersecting, setIsNotIntersecting] = useState<boolean | null>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsNotIntersecting(entry.intersectionRatio !== 1);
      },
      {
        root,
        threshold: [0, 1],
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      observer.unobserve(ref.current);
    };
  }, [ref, root, key]);

  return Boolean(!isNotIntersecting);
};

export default useVisibleOnElement;
