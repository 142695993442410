import {
  Column,
  Editing,
  Scrolling,
  ColumnChooser,
  ColumnFixing,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  SearchPanel,
  SortByGroupSummaryInfo,
  Sorting,
  Export,
  Paging,
} from 'devextreme-react/data-grid';
import { DataSourceType, mapSchema } from '@virtus/components/DxDataGrid/utils/mapSchema';
import { getExtraProps, DxColumn } from '@virtus/components/DxDataGrid/DxDataGrid';
import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import { GridViewProps, DefaultGridViewConfig } from './model';
import * as S from './style';
import { RenderLookup } from '@virtus/components/DxDataGrid/utils/edit/renderLookup';
import { getOnToolbarPreparing } from '@virtus/components/DxDataGrid/toolbarManagement';
import { DEFAULT_TOOLBAR_ICONS_ORDER } from 'src/utils/constants';
import { Template } from 'devextreme-react/core/template';
import InspectorActions from '@virtus/components/Inspector/components/InspectorActions';
import { onColumnGrouped } from 'src/utils/common';
import { getSummaryGlide, glideExcelExport } from '@virtus/components/DxDataGrid/utils/mapSchemaGlide';
const getColumns = ({
  dxColumns,
  EditObjectCellComponent,
}: {
  dxColumns: DxColumn[];
  EditObjectCellComponent: any;
}) => {
  const extraProps = {
    allowResizing: true,
    columnFixing: { enabled: true },
    allowSorting: true,
    editorOptions: { showClearButton: true },
  };

  return dxColumns.map((column: any) => {
    const props = { ...extraProps, ...column, ...getExtraProps(column) };
    return (
      <Column
        {...props}
        visible={column.visible !== undefined ? column.visible : true}
        {...(column.dataType === 'object' &&
          !column.lookups &&
          EditObjectCellComponent && {
            editCellComponent: _props => <EditObjectCellComponent props={_props} column={column} />,
          })}
      >
        {column.lookups && column.dataType === 'object' && RenderLookup(column.lookups.values)}
      </Column>
    );
  });
};

const defaultToolbarButtons = {
  add: [],
  remove: [],
  addAfter: [],
  addBefore: [],
};

const defaultGomProps: DefaultGridViewConfig = {
  edit: false,
  allowDeleting: true,
  usePagination: true,
  skipkeyId: false,
  skipSchema: false,
};

export const GridView = React.memo(
  React.forwardRef(
    (
      {
        dataGrid,
        style,
        useDarkTheme = true,
        customCellRenderKeyProps,
        onCustomToolBarPreparing,
        customizeColumns,
        EditObjectCellComponent,
        dxDataGridProps,
        customDxToolbarButtonsActions,
        layout,
        showRefreshButton = true,
        actionsCollection,
        allowHeaderSearch = false,
        exportFileName,
        ...props
      }: GridViewProps,
      ref: any,
    ) => {
      useLayoutEffect(() => {
        if (useDarkTheme) {
          require('@virtus/components/DxDataGrid/themes/dx.material.virtus-dark.css');
        }
      }, [useDarkTheme]);

      const totalItemSummary = useMemo(() => dataGrid?.summaryRender(), [dataGrid?.summaryRender]);

      const options = useMemo(() => {
        return props?.overrideGridViewProps
          ? { ...defaultGomProps, ...props.overrideGridViewProps }
          : { ...defaultGomProps };
      }, [props?.overrideGridViewProps]);

      const dxColumns = useMemo(() => mapSchema(dataGrid?.dataSource, DataSourceType.GLIDE, customCellRenderKeyProps), [
        customCellRenderKeyProps,
        dataGrid?.dataSource,
      ]);

      /**
       * EditDecimalCellComponent has big performance hits that makes it unusable
       * (1) User has to double click on certain values like 0 or when cell is in valid state
       * (2) Focus is not persisted through out the editing mode
       * (3) We can't see clear all (X) button
       * (4) First edit is very slow
       * (5) Required validation and other regex pattern doesn't work and many more...
       */
      // ℹ️ This memoization is needed to avoid an error in DXGrid when edit multiple cells and
      // close the modal. It memoizes columns on initial render.
      const columns = useMemo(
        () =>
          getColumns({
            dxColumns,
            EditObjectCellComponent,
            // EditDecimalCellComponent: DecimalWithMultiplier,
          }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
      );
      const onToolbarPreparing = useMemo(() => {
        return onCustomToolBarPreparing
          ? onCustomToolBarPreparing()
          : getOnToolbarPreparing({
              toolbarButtons: dxDataGridProps?.toolbarButtons || defaultToolbarButtons,
              showFilterButton: true,
              showRefresh: showRefreshButton,
              hideFilterPanel: false,
              hideHeaderFilter: true,
              excludeAlignment: [],
              isFilterButtonLeftAligned: true,
              toolbarIconsOrder: props?.toolbarIconsOrder || DEFAULT_TOOLBAR_ICONS_ORDER,
              customDxToolbarButtonsActions,
            });
      }, [onCustomToolBarPreparing, dxDataGridProps?.toolbarButtons, options.edit]);

      const summary = useMemo(
        () =>
          getSummaryGlide(dataGrid?.dataSource?.schema, {
            summaryDataTypeProps: {
              totalItemSummary: totalItemSummary,
            },
          }),
        [dataGrid?.dataSource?.schema, totalItemSummary],
      );

      const customizeExcelCellHandler = useCallback(
        (data: any) => {
          glideExcelExport(data, dataGrid?.dataSource);
        },
        [dataGrid?.dataSource],
      );

      return (
        <S.GridWrapper
          style={style?.wrapper}
          edit={options.edit}
          layout={layout}
          className={useDarkTheme ? 'dark-theme' : ''}
        >
          <S.DataGrid
            {...props}
            id="table-modal-datagrid"
            ref={ref}
            dataSource={dataGrid?.dataSource.data}
            onOptionChanged={dxDataGridProps?.onOptionChanged ?? onColumnGrouped}
            keyExpr={options.skipkeyId ? '' : '_uri'}
            showRowLines={false}
            hoverStateEnabled={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            cellHintEnabled={true}
            columnAutoWidth={true}
            columnHidingEnabled={false}
            onToolbarPreparing={onToolbarPreparing}
            repaintChangesOnly={false}
            customizeColumns={customizeColumns}
            {...dxDataGridProps}
          >
            <Scrolling mode={options.usePagination ? 'standard' : 'infinite'} />
            {summary}
            {columns}
            <Editing
              mode={options.editMode ? options.editMode : 'batch'}
              allowUpdating={options.edit && options.allowUpdating}
              allowDeleting={options.edit && options.allowDeleting}
              allowAdding={options.edit && options.allowAdding}
            />
            <Sorting mode="multiple" />
            <Paging enabled={options.usePagination} defaultPageSize={20} />
            <Export
              enabled={true}
              fileName={exportFileName}
              allowExportSelectedData={true}
              customizeExcelCell={customizeExcelCellHandler}
            />
            <HeaderFilter visible allowSearch={allowHeaderSearch} />
            <FilterRow />
            <FilterPanel />
            <GroupPanel visible emptyPanelText="Create Grouping" />
            <SearchPanel visible />
            <ColumnFixing enabled={true} />
            <Grouping autoExpandAll={true} contextMenuEnabled={true} />
            <SortByGroupSummaryInfo summaryItem="count" />
            <ColumnChooser height="300px" enabled={true} mode="select" />
            {!options.edit && actionsCollection && (
              <Template
                key="actions"
                name="otherActionsTemplate"
                render={() => (
                  <InspectorActions
                    actions={actionsCollection}
                    actionButtonText="More"
                    darktheme={useDarkTheme}
                    layout={layout}
                  />
                )}
              />
            )}
          </S.DataGrid>
        </S.GridWrapper>
      );
    },
  ),
);
