import { endpoints } from 'src/api/constants';
import { RootState } from 'src/reducers/rootReducer';
import { parseLayouts, addViewNameToLayoutData } from 'src/mappers/layouts/layouts.mapper';
import 'src/app/App/App.prototype';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { activeTabSelector } from 'src/reducers/tabs';
import { sanitizeGridData } from 'src/mappers/orders/sanitize-order-data';

export interface ClientView {
  data: any;
  schema: any;
  viewUri: string;
  clientViewConfiguration: ClientViewConfigurationData;
  webLayouts: {
    date: string;
    data: { name: string; owner?: string; json_layout: any };
    viewUri: string;
    uri: string;
  }[];
  layoutManager?: boolean;
}
export type ClientViewType = {
  configUri: string;
  [key: string]: any;
};

export const getClientView = ({ configUri, ...props }: ClientViewType) => {
  const configName = configUri;
  return {
    url: endpoints.clientViews.root,
    body: {
      uri: configUri,
      ...props,
    },
    transform: (body: any) => {
      const { data, schema, web_layouts, view_uri, client_view_configuration } = body;
      const webLayouts = web_layouts.length > 0 ? JSON.parse(web_layouts) : web_layouts;
      // console.log(`update ${configName} for view`, body);
      const parsedConfigData = JSON.parse(client_view_configuration);
      const clientViewConfiguration: ClientViewConfigurationData = {
        ...parsedConfigData.data,
        uri: parsedConfigData.uri,
      };
      return {
        views: {
          [`${configName}`]: {
            data: sanitizeGridData(JSON.parse(data)),
            schema,
            viewUri: view_uri,
            clientViewConfiguration,
            // TODO: Remove view_uri from addDefaultLayoutIfNotDefined. Added to verify lauout for pivot only
            webLayouts: addViewNameToLayoutData(parseLayouts(webLayouts), view_uri?.lastSplitValue()),
            collapseWrapperOpen: undefined,
            layoutManager: undefined,
          },
        },
      };
    },
    options: { method: 'GET' },
    queryKey: configName,
    force: true,
    update: {
      views: (prev: any, next: any) => ({
        ...prev,
        [`${configName}`]: {
          inspectorData: prev?.[`${configName}`]?.inspectorData,
          ...next[`${configName}`],
        },
      }),
    },
  };
};

export const selector = (state: RootState, configUri?: string) => {
  // TODO: Below line is to be removed once we integrate pages like credit, deals in glide view
  if (configUri) console.info(`Passed configURI ${configUri}`);
  const activeViewUri = configUri || activeTabSelector(state);
  return state.entities.views?.[activeViewUri];
};

export const isPending = (state: RootState, configUri: string) =>
  state.queries[configUri] && state.queries[configUri].isPending;
