import { GlideAPIFieldRules, GlideAPIFieldRule, FieldRules } from './field-rules.model';
import { FieldRuleType, FieldRule } from 'src/components/forms/form-elements/FormElement.model';

export const mapFieldRules = (apiFieldRules: GlideAPIFieldRules): FieldRules =>
  apiFieldRules &&
  apiFieldRules.reduce((acc: { [key: string]: FieldRule[] }, value: GlideAPIFieldRule) => {
    try {
      const validatorFn = eval(value.field_rule_script);
      const rule = {
        ruleFn: validatorFn,
        ruleType: value.field_rule_type as FieldRuleType,
        ...value,
      };
      const otherFieldRules = acc[value.field_rule_target] || [];

      return {
        ...acc,
        [value.field_rule_target]: [...otherFieldRules, ...[rule]],
      };
    } catch (err) {
      console.error(`Rule not evaluated. Typo in ${value.display_name} rule: `, value.field_rule_script);
      return acc;
    }
  }, {});
