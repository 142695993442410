import React, { useCallback, useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMarketmap } from 'src/hooks/use-marketmap';
import { selectComponents } from 'src/reducers/components';
import { GLIDE_LABELS } from 'src/utils/labels';
import { Switch } from '@virtus/components/Switch';
import { Context } from 'src/contexts/grid-theme/grid-theme.context';
import Modelling from 'src/components/portfolio/components/modelling/modelling';
import ProposedOrders from 'src/components/portfolio/components/proposed-orders/proposed-orders';
import { Template } from 'devextreme-react/core/template';
import { PortfolioSagaAction } from 'src/sagas/portfolio/proposed-order.saga';
import { actions } from 'src/reducers/actions';
import { DataSource } from '@virtus/components/DxDataGrid/DxDataGrid';
import { DxToolbarButton } from '@virtus/components/DxDataGrid/model/DxDataGrid.model';
import { ActionsCollectionWebDropdown } from 'src/components/portfolio/components/actions-collection-web/actions-collection-web';
import 'src/components/portfolio-fund-selector/portfolio-fund-selector';
const PortfolioFundSelector = React.lazy(
  () => import('src/components/portfolio-fund-selector/portfolio-fund-selector'),
);
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';

export interface ToolbarButtons {
  display_name: string;
  uri: string;
}
const { portfolio_modelling } = GLIDE_LABELS;

interface GridTemplateType {
  dataSource?: DataSource;
  clientViewConfiguration?: ClientViewConfigurationData;
  rowData?: any;
}

export const getLivePriceToggleStatus = (
  marketMapToggleStatus: any,
  cvcUri: string,
  isLoggedInToMarketmap: boolean,
) => {
  const isToggleSet = marketMapToggleStatus[cvcUri];
  return isLoggedInToMarketmap && isToggleSet === undefined ? true : isToggleSet;
};

const GridTemplates = ({ dataSource, clientViewConfiguration }: GridTemplateType) => {
  const [disableModellingBtn, setDisableModellingBtn] = useState(true);
  const { toggleLivePrice } = useMarketmap();
  const dispatch = useDispatch();
  const { useDarkTheme } = useContext(Context);
  const { livePriceToggleState, global } = useSelector(selectComponents);
  const { marketMapToggleStatus, isLoggedInToMarketmap } = livePriceToggleState;
  const cvcUri = clientViewConfiguration?.uri?.lastSplitValue();
  const toolbarButtons: ToolbarButtons[] = dataSource?.clientViewConfiguration?.toolbar_buttons;
  const marketMapToggleFlag = getLivePriceToggleStatus(marketMapToggleStatus, cvcUri!, isLoggedInToMarketmap);
  const toolbarButtonsFromConfig = [] as DxToolbarButton[];
  const toolbarTemplates = [] as any[];
  let livePriceEnabled = false;

  useEffect(() => {
    if ((dataSource?.data as any[])?.length > 0) {
      setDisableModellingBtn(false);
    }
    return () => {
      setDisableModellingBtn(true);
    };
  }, [dataSource]);

  const toggleLivePriceHandler = useCallback(
    (_checked: boolean) => {
      cvcUri && toggleLivePrice(cvcUri, _checked);
    },
    [cvcUri, toggleLivePrice],
  );

  const renderSwitchComponent = useCallback(
    () => <Switch onChange={toggleLivePriceHandler} checked={marketMapToggleFlag} useDarkTheme={useDarkTheme} />,
    [marketMapToggleFlag, toggleLivePriceHandler, useDarkTheme],
  );

  const ModellingButtonRender = useCallback(() => {
    const onModellingClick = () => {
      const uri = 'instance/actions/fund_modeling';
      dispatch({
        type: actions.action.DISPATCH_ACTION,
        payload: { action: { uri, data: { arguments: {} } }, clientViewConfiguration },
      });
    };

    return (
      <Modelling onIconClick={onModellingClick} useDarkTheme={useDarkTheme} isDisabledIcon={disableModellingBtn} />
    );
  }, [disableModellingBtn, dispatch, useDarkTheme]);

  const getProposedOrder = () =>
    dispatch({ type: PortfolioSagaAction.FETCH_PROPOSED_ORDERS, payload: { clientViewConfiguration } });

  const renderPortfolioManager = useCallback(
    () => <PortfolioFundSelector clientViewConfiguration={clientViewConfiguration} />,
    [clientViewConfiguration?.uri],
  );
  const renderActionsCollectionWeb = useCallback(
    (type: string) => {
      let returnComponent: JSX.Element = <></>;
      switch (type) {
        case 'orderdropdown':
          returnComponent = (
            <ActionsCollectionWebDropdown
              type={type}
              rowData={global.orders.rowData}
              actionCollectionsWeb={dataSource?.clientViewConfiguration?.actions_collection_web}
              disableOrderDropdown={global.orders.disabled}
            />
          );
          break;
        case 'addinstruments':
          returnComponent = <ActionsCollectionWebDropdown type={type} />;
          break;
      }
      return returnComponent;
    },
    [global.orders.disabled, global.orders.rowData],
  );

  const TOOLBAR_BUTTONS_TEMPLATE = [
    {
      templateName: 'portfolioManager',
      component: renderPortfolioManager,
      display_name: 'Portfolio Manager',
      toolbarItem: {
        name: 'portfolioManagerButton',
        widget: 'dxButton',
        cssClass: 'vertical-separator-line',
        options: {
          template: 'portfolioManager',
          hint: 'Portfolio Manager',
          elementAttr: { 'data-testid': 'portfolio-manager-btn' },
        },
        location: 'before',
      },
    },
    {
      templateName: 'livePricesSwitch',
      component: renderSwitchComponent,
      display_name: 'Live Price',
      toolbarItem: {
        name: 'switchComponent',
        widget: 'dxButton',
        options: {
          template: 'livePricesSwitch',
          hint: 'Live Prices',
          elementAttr: { 'data-testid': 'livePriceSwitch-btn' },
        },
        location: 'after',
      },
    },
    {
      templateName: 'portfolio-proposed-order',
      // @ts-ignore
      component: () => <ProposedOrders />,
      display_name: 'Proposed Orders',
      toolbarItem: {
        name: 'portfolioProposedOrderButton',
        widget: 'dxButton',
        cssClass: 'vertical-separator-line',
        options: {
          template: 'portfolio-proposed-order',
          hint: 'Proposed Orders',
          elementAttr: { 'data-testid': 'portfolio-proposed-order-btn' },
          onClick: getProposedOrder,
        },
        location: 'after',
      },
    },
    {
      templateName: 'portfolioOrderDropdownBtn',
      component: () => renderActionsCollectionWeb('orderdropdown'),
      display_name: 'Portfolio New Order Dropdown',
      toolbarItem: {
        name: 'portfolioOrderDropdownBtn',
        widget: 'dxButton',
        options: {
          template: 'portfolioOrderDropdownBtn',
          hint: 'Portfolio Order Dropdown',
          elementAttr: { 'data-testid': 'portfolio-order-dropdown' },
        },
        location: 'after',
      },
    },
    {
      templateName: 'portfolioAddDropdownBtn',
      component: () => renderActionsCollectionWeb('addinstruments'),
      display_name: 'Portfolio Import Instrument Button',
      toolbarItem: {
        name: 'portfolioAddDropdownBtn',
        widget: 'dxButton',
        options: {
          template: 'portfolioAddDropdownBtn',
          hint: 'Add Instrument',
          elementAttr: { 'data-testid': 'portfolio-import-instrument-dropdown' },
        },
        location: 'after',
      },
    },
    {
      templateName: 'modellingTemplate',
      component: ModellingButtonRender,
      display_name: 'Modeling',
      toolbarItem: {
        name: 'modellingButton',
        location: 'after',
        widget: 'dxButton',
        options: {
          template: 'modellingTemplate',
          hint: portfolio_modelling.tooltip_text,
          elementAttr: { 'data-testid': 'portfolio-modelling-btn' },
        },
        cssClass: 'vertical-separator-line',
      },
    },
  ];

  if (toolbarButtons?.length > 0) {
    toolbarButtons.forEach(toolbarButton => {
      const toolbarButtonItem = TOOLBAR_BUTTONS_TEMPLATE.find(
        templateItem => templateItem.display_name === toolbarButton.display_name,
      );
      if (toolbarButton.display_name === 'Live Price') {
        livePriceEnabled = true;
      }
      if (toolbarButtonItem) {
        toolbarTemplates?.push(
          <Template
            key={toolbarButtonItem.templateName}
            name={toolbarButtonItem.templateName}
            render={toolbarButtonItem.component}
          />,
        );
      }
      if (toolbarButtonItem?.toolbarItem) {
        toolbarButtonsFromConfig.push(toolbarButtonItem.toolbarItem);
      }
    });
  }

  return {
    toolbarButtonsFromConfig,
    toolbarTemplates,
    livePriceEnabled,
  };
};

export default GridTemplates;
