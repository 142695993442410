import React, { useState, useEffect, useRef } from 'react';
import GlideCommentaryComponent, { GlideCommentaryProps } from '../glide-commentary/glide-commentary';
import GlideList from 'src/components/glide-list/glide-list';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { Glide, GlideCommentaryApi } from 'src/api/queries';
import { RootState } from 'src/reducers';
import * as GlideContainerStyle from './glide-list-container-style';
import { sortByParams } from '../glide-list/glide-list-mapper';
import { requestAsync } from 'redux-query';
import { selectComponents } from 'src/reducers/components';
import Loading from '@virtus/components/Loading';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import * as S from '../glide-list/glide-list.style';
import { randomStringKey } from 'src/utils/common';
import { formatUserName } from '@virtus/common/utils/formatters';
import { ANALYST_COMMENT_TYPE_REQUEST_MAP } from 'src/components/glide-commentary/glide-commentary.util';
export interface GlideListContainerProps {
  uri: string;
  componentType?: string;
  fromModal?: boolean;
  fromColumn?: boolean;
}
export interface ReduxProps {
  readonly commentaryData: any;
  readonly isPendingGlideCommentary: boolean;
  readonly isNewCommentaryPending: boolean;
  readonly creditDetailUri: any;
}
export interface ReduxDispatch {
  updateAnalystComments?: (data: any) => void;
}
const searchExpr = ['username', 'title', 'comments'];
const groupByGlideList = [
  { id: -1, text: 'Group By None' },
  { id: 1, text: 'Category', groupfield: 'category' },
  { id: 2, text: 'Username', groupfield: 'username' },
];
const sortByGlideList = [
  { id: -1, text: 'Sort by' },
  { id: 1, text: 'Latest', orderfield: 'commentdate', orderby: 'desc' },
  { id: 2, text: 'Oldest', orderfield: 'commentdate', orderby: 'asc' },
  { id: 3, text: 'A-Z', orderfield: 'username', orderby: 'asc' },
  { id: 4, text: 'Z-A', orderfield: 'username', orderby: 'desc' },
];
const placeholder = 'Add Comment';
const GlideCommentaryDefaultValue: Omit<GlideCommentaryProps, 'fromModal' | 'creditUri'> = {
  title: '',
  username: '',
  commentdate: 0,
  category: '',
  comments: '',
};

const GlideListContainer = ({
  uri,
  commentaryData,
  isPendingGlideCommentary,
  updateAnalystComments,
  isNewCommentaryPending,
  creditDetailUri,
  componentType,
  fromModal = false,
  fromColumn = false,
}: GlideListContainerProps & ReduxProps & ReduxDispatch) => {
  const [commentEditor, setCommentEditor] = useState(false);
  const [commentaryDetails, setCommentaryDetails] = useState(sortByParams('desc', commentaryData, 'commentdate'));
  const sortData = () => {
    const sorted = sortByParams('desc', commentaryData, 'commentdate');
    return sorted[0];
  };
  const [GlideCommentaryDefaultProps, setGlideCommentaryDefaultProps] = useState(
    commentaryData && commentaryData.length > 0 ? sortData() : GlideCommentaryDefaultValue,
  );
  const { creditDetailsInspector } = useSelector(selectComponents);
  const createNewCommentEditor = (showEditor: any) => {
    setCommentEditor(showEditor);
  };
  const commentaryDataCallback = (commentaryDataCallbackData?: any) => {
    setCommentEditor(false);
    if (commentaryDataCallbackData) updateComments(commentaryDataCallbackData);
  };

  const ref = useRef(null);

  useEffect(() => {
    if (commentaryData && commentaryData.length) {
      const sortedData = sortByParams('desc', commentaryData, 'commentdate');
      setCommentaryDetails(sortedData);
      setSelectedItemIndex(sortedData[0].id);
      ref.current = commentaryData;
      setGlideCommentaryDefaultProps(sortedData[0]);
    } else {
      setGlideCommentaryDefaultProps(GlideCommentaryDefaultValue);
    }
  }, [commentaryData, commentaryData.length]);

  const updateComments = (commentaryDataCallbackData: any) => {
    const convertModelCommentaryData: any = [];
    const commentaryDataObject = ref?.current ? ref.current : [...commentaryData];
    if (commentaryDataObject && commentaryDataObject.length > 0) {
      commentaryDataObject.forEach((cd: any) => {
        convertModelCommentaryData[cd.commenturi] = {
          analyst: cd.username,
          analyst_comment:
            cd.commenturi === commentaryDataCallbackData.uri ? commentaryDataCallbackData.htmlEditorData : cd.comments,
          analyst_comment_type: cd.category,
          analyst_comment_date: new Date(cd.commentdate).toLocaleString(),
          uri: cd.commenturi,
          display_name: cd.displayname,
          credit_details: cd.creditdetails,
        };
      });
      if (convertModelCommentaryData && creditDetailUri) {
        const CreditDetailsUpdate = {
          object_uri: creditDetailUri,
          [ANALYST_COMMENT_TYPE_REQUEST_MAP[
            commentaryDataCallbackData.category as keyof typeof ANALYST_COMMENT_TYPE_REQUEST_MAP
          ]]: { ...convertModelCommentaryData },
        };
        updateAnalystComments && updateAnalystComments(CreditDetailsUpdate);
      }
    }
  };
  const onSelectedItem = (args: any) => {
    setCommentEditor(false);
    setGlideCommentaryDefaultProps(args);
  };
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(-1);
  const onSelection = (args: any) => {
    onSelectedItem(args);
    setSelectedItemIndex(args.id);
  };
  const shorten = (str: string, maxLen: number, separator: string = ' ') => {
    if (str.length <= maxLen) return str;
    return `${str.substr(0, str.lastIndexOf(separator, maxLen))}...`;
  };
  let leftDataTemplate: JSX.Element[] = [];
  let rightDataTemplate: JSX.Element[] = [];
  const ItemTemplate = (data: any) => {
    Object.entries(data).forEach(([key, value]: any) => {
      const randomKey = randomStringKey(64, 'Aa#');
      switch (key) {
        case 'title':
          leftDataTemplate.push(
            <S.BlogTitleWrapper key={randomKey}>{value ? value.toString() : ''}</S.BlogTitleWrapper>,
          );
          break;
        case 'username':
          leftDataTemplate.push(
            <S.BlogAuthorWrapper key={randomKey}>{value ? formatUserName(value.toString()) : ''}</S.BlogAuthorWrapper>,
          );
          break;
        case 'comments':
          leftDataTemplate.push(
            <S.BlogCommentsWrapper key={randomKey}>
              {value ? shorten(value.replace(/<[^>]+>/g, '').toString(), 150) : ''}
            </S.BlogCommentsWrapper>,
          );
          break;
        case 'category':
          rightDataTemplate.push(<S.TagStyle key={randomKey}>{value ? value.toString() : ''}</S.TagStyle>);
          break;
        case 'commentdate':
          rightDataTemplate.push(
            <S.DateStyle key={randomKey}>{value ? new Date(value).toLocaleString() : ''}</S.DateStyle>,
          );
          break;
      }
    });

    const leftSection = leftDataTemplate.map((ldt: JSX.Element) => ldt);
    const rightSection = rightDataTemplate.map((ldt: JSX.Element) => ldt);
    leftDataTemplate = [];
    rightDataTemplate = [];
    return (
      <S.BlogSection key={randomStringKey(64, '#Aa')} onClick={() => onSelection(data)}>
        {data.id == selectedItemIndex && <S.SelectedItemStyle />}
        <S.BlogLeftSection>{leftSection}</S.BlogLeftSection>
        <S.BlogRightSection>{rightSection}</S.BlogRightSection>
      </S.BlogSection>
    );
  };
  const GroupTemplate = (data: any) => {
    return (
      <div style={{ display: 'block' }} key={randomStringKey(128, '#Aa')}>
        <S.GroupHeading>{formatUserName(data.key)}</S.GroupHeading>
        {data.items.map((item: any) => ItemTemplate(item))}
      </div>
    );
  };

  return (
    <>
      {isPendingGlideCommentary || isNewCommentaryPending ? (
        <Loading type={LoadingIconType.Glide} size={LoadingIconSizes.large} show full />
      ) : (
        <GlideContainerStyle.GlideListWrapper>
          <GlideList
            itemTemplate={ItemTemplate}
            groupTemplate={GroupTemplate}
            searchExpr={searchExpr}
            commentaryData={commentaryDetails}
            groupByDropDownList={groupByGlideList}
            defaultSortBy={{ field: 'commentdate', orderBy: 'desc' }}
            sortByDropDownList={sortByGlideList}
            onSelectedItem={onSelectedItem}
            setSelectedItemIndex={index => setSelectedItemIndex(index)}
            isSearchEnabled={true}
            createNewCommentEditor={createNewCommentEditor}
            actionButtonText="+ Add Comment"
          />
          <GlideCommentaryComponent
            {...GlideCommentaryDefaultProps}
            addComment={commentEditor}
            placeholder={placeholder}
            commentaryDataCallback={commentaryDataCallback}
            componentType={componentType}
            fromModal={fromModal}
            creditUri={fromColumn ? creditDetailUri : uri}
            inspectorOpen={creditDetailsInspector.visible}
          />
        </GlideContainerStyle.GlideListWrapper>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: any): ReduxProps => {
  const { componentType } = ownProps;
  return {
    commentaryData: GlideCommentaryApi.CommentarySelector(state, componentType),
    isPendingGlideCommentary: GlideCommentaryApi.isPending(state),
    isNewCommentaryPending: GlideCommentaryApi.isNewCommentaryPending(state),
    creditDetailUri: Glide.ggselector(state, 'credit_detail'),
  };
};
const mapDispatchToProps = (dispatch: any): ReduxDispatch => ({
  updateAnalystComments: (data: any) =>
    dispatch(requestAsync(GlideCommentaryApi.updateAnalystCommentsCreditDetails(data))),
});
export default compose(connect(mapStateToProps, mapDispatchToProps))(GlideListContainer);
