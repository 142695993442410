import isFunction from 'lodash/isFunction';
import React, { useRef } from 'react';
import { IPortalLink, PortalLink } from 'src/Buttons';
import DropdownMenu from 'src/DropdownMenu/';
import Tooltip from '@material-ui/core/Tooltip';
import { ExtraIconButtonProps, ILabel } from './tool-bar.model';
import {
  IconButton,
  StyledButtonContainer,
  StyledContainer,
  StyledExportButton,
  StyledLabelContainer,
  StyledOpenInNewIcon,
} from './tool-bar.style';
import ViewLabels from 'src/ViewToolbar/components/ViewLabels';

export interface ToolBarProps {
  labels: ILabel[];
  active?: any;
  // active?: number;
  moreButtonItems?: () => React.ReactNode;
  onExport?: () => void;
  onClickTab: (key: string | any) => void;
  extraIconButtons?: ExtraIconButtonProps[];
  links?: IPortalLink[];
  customParseViews?: (data: any[]) => any[];
  viewContainerStyles?: any;
}

const onClickToolBarTab = () => console.info('Action not defined');

const setParentWidth = (width: number) => (width < 900 ? '30vw' : '65vw');
export const ToolBar = ({
  labels,
  active,
  moreButtonItems,
  onExport,
  onClickTab = onClickToolBarTab,
  extraIconButtons,
  customParseViews,
  viewContainerStyles = {},
  links = [],
}: ToolBarProps) => {
  const containerRef = useRef(null);

  const onLabelClickHandler = (id: number) => () => onClickTab(id);
  return (
    <StyledContainer>
      <StyledLabelContainer ref={containerRef} style={viewContainerStyles}>
        <ViewLabels
          views={labels}
          onClick={onLabelClickHandler}
          parentRef={containerRef}
          labelStyle={{
            minWidth: 'fit-content',
          }}
          active={active}
          customParseViews={customParseViews}
          setParentWidth={setParentWidth}
        />
        {links.map(link => (
          <PortalLink key={link.text} {...link} />
        ))}
      </StyledLabelContainer>
      <StyledButtonContainer>
        {extraIconButtons &&
          extraIconButtons.map(iconButton => (
            <Tooltip key={iconButton.testId} title={iconButton.tooltip}>
              <IconButton data-testid={iconButton.testId} onClick={iconButton.onClick}>
                <iconButton.Icon style={{ color: 'var(--text)' }} {...iconButton.iconProps} />
              </IconButton>
            </Tooltip>
          ))}
        <StyledExportButton data-testid="export-button" onClick={onExport} disabled={!isFunction(onExport)}>
          <Tooltip title="Export">
            <StyledOpenInNewIcon />
          </Tooltip>
        </StyledExportButton>
        <Tooltip title="More options">
          <DropdownMenu light>{moreButtonItems && moreButtonItems()}</DropdownMenu>
        </Tooltip>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default ToolBar;
