import React, { useEffect, useState } from 'react';
import { TimeSeriesDatePicker } from 'src/components/time-series-date-picker/time-series-date-picker';
import { computeDayCount } from 'src/components/glide-time-series/glide-time-series';
import { dispatchActions } from 'src/app/store';
import { activeTabSelector, selectCVC } from 'src/reducers/tabs';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'src/reducers';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { GlideViewActions } from 'src/sagas/glide-view.saga';
import { cancelQuery } from 'redux-query';

export interface DateConfig {
  dateType: string;
  singleDate: string;
  prevSingleDate?: string;
  toDate: string;
  fromDate: string;
  frequency: string;
}

export interface MultiDateObjectType {
  visible: boolean;
  count: number;
  data: DateConfig;
}

interface ReduxProps {
  clientViewUri: string;
  clientViewConfiguration: ClientViewConfigurationData;
}

interface DatePickerProps {
  singleDateSelection?: boolean;
  dateFieldLabel?: string;
}

const isPendingDateRequest = (
  datePickerConfig: MultiDateObjectType,
  clientViewConfiguration: ClientViewConfigurationData,
) =>
  datePickerConfig.visible &&
  datePickerConfig.data.singleDate &&
  datePickerConfig.data.singleDate !== datePickerConfig.data.prevSingleDate &&
  clientViewConfiguration.is_time_series;

const DatePicker: React.FunctionComponent<ReduxProps & DatePickerProps> = ({
  clientViewUri,
  clientViewConfiguration,
  singleDateSelection = true,
  dateFieldLabel,
}) => {
  const dispatch = useDispatch();
  const initialState = {
    visible: true,
    count: 0,
    data: {
      singleDate: '',
      prevSingleDate: '',
      toDate: '',
      fromDate: '',
      frequency: '',
      dateType: 'single',
    },
  };
  const [datePickerConfig, setDatePickerConfig] = useState<MultiDateObjectType>(initialState);

  const updateDateConfig = (dateConfig: any) => {
    if (singleDateSelection && dateConfig?.data?.singleDate.length && dateConfig.visible) {
      const latestDate = dateConfig.data.singleDate[dateConfig.data.singleDate.length - 1];
      const updateDates = {
        singleDate: latestDate,
        prevSingleDate: datePickerConfig.data.singleDate ?? latestDate,
      };
      setDatePickerConfig(prevState => ({ ...prevState, data: { ...prevState.data, ...updateDates } }));
    } else {
      setDatePickerConfig(prevState => ({ ...prevState, ...dateConfig }));
    }
  };

  const onDatePickerCancel = () => {
    updateDateConfig({
      visible: false,
      data: { ...datePickerConfig.data, prevSingleDate: datePickerConfig.data.singleDate },
    });
  };

  useEffect(() => {
    dispatchActions.components.updateView('datePicker', clientViewUri, datePickerConfig);
    if (isPendingDateRequest(datePickerConfig, clientViewConfiguration)) {
      dispatch(cancelQuery(clientViewUri));
      dispatch({
        type: GlideViewActions.FETCH_CLIENT_VIEW,
      });
    }
  }, [datePickerConfig]);

  const onDatePickerApply = (data: any) => {
    const { count } = computeDayCount(data);
    updateDateConfig({ visible: true, count, data });
    // onButtonClick && onButtonClick(data);
  };

  return (
    <>
      {datePickerConfig.visible && (
        <TimeSeriesDatePicker
          // Disable multiple date selection until it is supported by gsearch
          singleDateSelection
          onChange={onDatePickerApply}
          dateObj={datePickerConfig.data}
          inspectorOnApply={onDatePickerApply}
          inspectorOnCancel={onDatePickerCancel}
          calendarAlign="left"
          dateFieldLabel={dateFieldLabel}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  clientViewUri: activeTabSelector(state),
  clientViewConfiguration: selectCVC(state),
});

export default connect(mapStateToProps)(DatePicker);
