import { FormFieldOptions, FormFieldsType, OptionElement } from 'src/components/forms/form-elements/FormElement.model';
import React, { FC, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { DisplayViewApi } from 'src/api/queries';
import { Action } from 'src/api/mutations';
import { GlideDataContent } from 'src/components/forms/glide-data-content';
import { getFieldsFromView, parseUriParams } from 'src/mappers/common-mapper-functions';
import { RootState } from 'src/reducers';
import { Dispatch } from 'redux';
import { NewObjectType } from 'src/models/glide/glideObject';
import Modal from '@virtus/components/Modal';
import { mapFieldRules } from 'src/api/field-rules/field-rules.mapper';
import { mutateAsync, requestAsync } from 'redux-query';
import { useForm } from 'src/components/forms/hooks/useForm';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import { ActionArguments } from 'src/api/queries';
import { actions } from 'src/reducers/actions';
import { FormData } from 'src/reducers/forms';
import { dispatchActions } from 'src/app/store';
import Loading from '@virtus/components/Loading';
import { LoadingIconType, LoadingIconSizes } from '@virtus/components/LoadingIcon/LoadingIcon';
import { ModalWrapper } from 'src/components/forms/glide-new-object/glide-new-object.style';

interface ReduxProps {
  actionDisplayView: any;
  createdObjectUri: any;
  actionArguments: any;
}
export interface OverlayViewState {
  activeUri: string;
  inspector_history: string[];
}
interface ReduxDispatch {
  getdisplayViewData: (uri: string) => void;
  createNewObject: (newObjectPayload: any) => void;
  resolveAction: (action: any) => void;
}

export interface GlideNewObjectProps {
  newActionUri: string;
  onSubmitCallback?: (newObjectType: string) => void;
  showAsModal?: boolean;
  activeViewKey?: string;
  showBackButton?: boolean;
  customActionOverride?: any;
  runAction?: boolean;
}

type GlideNewObjectType = GlideNewObjectProps & ReduxProps & ReduxDispatch;

const getSelectedOption = (options: FormFieldOptions, defaultValue: string) => {
  const optionValues: OptionElement[] = Object.values(options) || [];
  const foundOption = optionValues.find((option: OptionElement) => option.value === defaultValue);
  return foundOption ? foundOption.value : '';
};

const getInitialNewObjectValues = (formFields: FormFieldsType) =>
  Object.keys(formFields).reduce((acc, key) => {
    const options = formFields[key].options || {};
    return {
      ...acc,
      [key]:
        formFields[key].formElementType === 'select'
          ? getSelectedOption(options, formFields[key].defaultValue as string)
          : formFields[key].defaultValue,
    };
  }, {});

export const NewObject: FC<GlideNewObjectType> = React.memo(
  ({
    actionDisplayView,
    createNewObject,
    showAsModal = false,
    newActionUri,
    getdisplayViewData,
    createdObjectUri,
    activeViewKey = '',
    showBackButton = false,
  }) => {
    const [displayViewData, setdisplayViewData] = useState<any>(actionDisplayView);
    const [formHasChanged, setFormHasChanged] = useState<boolean | undefined>(false);
    useEffect(() => {
      if (!displayViewData?.[newActionUri]?.display_view) {
        getdisplayViewData(newActionUri);
      }
    }, [newActionUri]);

    useEffect(() => {
      setdisplayViewData(actionDisplayView);
    }, [actionDisplayView]);
    //Initial mapping of fields and values
    const mappedFieldRules = mapFieldRules(displayViewData?.[newActionUri]?.fieldRules);
    const formInfo = displayViewData?.[newActionUri]?.display_view?.field_display_definitions;
    if (formInfo) formInfo.sort((a: any, b: any) => a?.ordering - b?.ordering);
    const newObjectType = displayViewData?.[newActionUri]?.data?.uri.split('?')[0].lastSplitValue();

    const formData = getFieldsFromView(formInfo, mappedFieldRules, displayViewData?.[newActionUri]?.data?.uri);

    const initialFormValues = getInitialNewObjectValues(formData);
    const defaultValuefromUri =
      displayViewData?.[newActionUri]?.data?.uri && parseUriParams(displayViewData?.[newActionUri]?.data?.uri);

    const onSubmitForm = () => {
      let new_object_data = formState.formValues;
      if (newActionUri.includes('dummy')) {
        new_object_data = {
          ...defaultValuefromUri,
          ...formState.formValues,
          behaviour: defaultValuefromUri?.['behaviour'],
        };
      }
      createNewObject({ object_type: newObjectType, object_data: new_object_data });
      setFormState({ formFields: formData, formValues: {}, formErrors: {}, formChanges: {} } as FormData);
      closeForm();
    };
    const safeClose = (closeFn: any) => {
      formHasChanged ? quitEditDispatch() : closeFn();
    };
    const closeForm = () => dispatchActions.components.toggleDisplay('newObject', false);

    const { DialogComponent: QuitEditDialog, onDispatcherClick: quitEditDispatch } = useConfirmationDialog({
      onClick: () => closeForm(),
      headerText: 'Save change',
      bodyTextContent: `Are you sure you want to discard changes for ${displayViewData?.[newActionUri]?.data?.display_name} ?`,
    });

    const { onSave, setFormState, formState, onChangeField, formChanges } = useForm({
      onSubmit: onSubmitForm,
      objectKey: newObjectType,
      initialEditing: false,
    });

    useEffect(() => {
      let hasChanged = false;
      if (formChanges) {
        Object.entries(formChanges).map((formChange: any) => {
          const [key, value] = formChange;
          if (key !== 'display_name' && value) hasChanged = true;
        });
      }
      setFormHasChanged(hasChanged);
    }, [formState]);

    const formContent = useMemo(
      () => ({
        formFields: formData,
        formValues: initialFormValues,
        formErrors: {},
        newActionUri,
      }),
      [formData, initialFormValues, newActionUri],
    );

    useEffect(() => {
      setFormState({ ...formContent, formChanges: {}, hasChanged: false });
      // Adding formContent as a dependancy causes infinite render , as the formContent reference changes on every render.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newActionUri, displayViewData]);

    useEffect(() => {
      if (createdObjectUri) {
        let actionFieldKey;
        let actionFieldSearchType;
        for (const [key, value] of Object.entries(formContent.formFields) as Array<[string, any]>) {
          if (value.action && Object.keys(createdObjectUri).find(el => el === value?.searchType)) {
            actionFieldKey = key;
            actionFieldSearchType = value.searchType;
          }
        }
        if (actionFieldKey) {
          const change = { [actionFieldKey]: createdObjectUri[actionFieldSearchType] };
          setFormState({
            ...formState,
            formValues: { ...formState.formValues, ...change },
          });
        }
      }
    }, [createdObjectUri]);

    const GlideDataContentForm = () => (
      <>
        <QuitEditDialog />
        <GlideDataContent
          isEditing
          formName="glide-new-object"
          dataTestId="glide-new-object"
          form={formState}
          formValues={formState.formValues}
          onChangeField={onChangeField}
          hideFooter={true}
          activeViewKey={activeViewKey}
        />
      </>
    );

    const dialogStyle = { height: 'auto', overflow: 'hidden', display: 'flex', flexDirection: 'column' };
    return (
      <Modal
        title={displayViewData?.[newActionUri]?.data?.display_name}
        onClose={closeForm}
        show={showAsModal}
        isDraggable
        fullWidth
        dialogStyle={dialogStyle}
        showBackButton={showBackButton}
        primaryButton={{
          text: 'Save',
          action: onSave,
        }}
        cancelButton={{
          text: 'Cancel',
          action: () => safeClose(closeForm),
        }}
      >
        <ModalWrapper>
          {!Object.keys(formState.formFields).length ? (
            <Loading
              type={LoadingIconType.Glide}
              size={LoadingIconSizes.large}
              show={true}
              text="Loading Form..."
              full
            />
          ) : (
            GlideDataContentForm()
          )}
        </ModalWrapper>
      </Modal>
    );
  },
);

const mapStateToProps = (state: RootState): ReduxProps => ({
  actionDisplayView: DisplayViewApi.displayViewSelector(state),
  createdObjectUri: Action.newObjectUri(state),
  actionArguments: ActionArguments.selector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatch => ({
  getdisplayViewData: (uri: string) => dispatch(requestAsync(DisplayViewApi.getDisplayView(uri))),
  createNewObject: ({ object_type, object_data }: NewObjectType) =>
    dispatch(mutateAsync(Action.createNewObject({ object_type, object_data }))),
  resolveAction: (action: any) => dispatch({ type: actions.action.DISPATCH_ACTION, payload: { action } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewObject);
