import XLSX from '@sheet/core';
import { getExcelNumeralFormat } from '@virtus/common/utils/formatters';
import { DataType, dataTypeMap, NexusDataType } from 'src/DxDataGrid/utils/mapSchemaVirtus';
import { getNexusDataType, getNumericPrecision, parseDataTypeValue } from 'src/parsers/parseDataTypeValueVirtus';
import { DataCellConfig, HorizontalAlignment, HorizontalAlignmentsByDataType } from './ExportToExcelConfig';

// @TODO move this of take it from ExportToExcelConfig.ts file
const headerStyles = { fgColor: { rgb: '#DFDFDF' }, bold: true, alignment: { horizontal: 'center' } };

const headerBorders = {
  top: { style: 'thin' },
  bottom: { style: 'thin' },
  left: { style: 'thin' },
  right: { style: 'thin' },
};

const filterByRowNumber = (key: string, rowNumber: number) =>
  Number(key.substring(key.length - rowNumber.toString().length)) === rowNumber &&
  isNaN(Number(key.substring(key.length - (rowNumber.toString().length + 1))));

// add borders and background to the header row
export const withHeaderRowStyles = (rowNumber: number, ws: XLSX.WorkSheet, showBorders?: boolean) => {
  const _ws = { ...ws };

  const rowKeys: string[] = Object.keys(ws).filter((k: string) => filterByRowNumber(k, rowNumber));
  rowKeys.forEach((key: string) => (_ws[key].s = { ...headerStyles, ...(showBorders ? headerBorders : {}) }));

  const cols = _ws['!cols'] || [];

  rowKeys.forEach(() => {
    if (cols) cols.push({ wpx: 250 });
  });

  _ws['!cols'] = cols;

  return _ws;
};

// get the extreme cells (top-left and bottom-right)
export const getFirstLastCells = (sheet: XLSX.WorkSheet = {}): { firstCell?: string; lastCell?: string } => {
  const [firstCell, lastCell] = (sheet['!ref'] || '')?.split(':');
  return { firstCell, lastCell };
};

export const getRowsLength = (sheet: XLSX.WorkSheet = {}) => {
  const { lastCell = '' } = getFirstLastCells(sheet);
  return Number(lastCell.replace(/[A-Za-z]/g, ''));
};

export const getHorizontalAlignment = (dataType: DataType) =>
  (HorizontalAlignmentsByDataType[dataType] || DataCellConfig.alignment.horizontal) as HorizontalAlignment;

export const getCellExcelNumeralFormat = (
  dataType: NexusDataType,
  fieldName: string = '',
  numericPrecisionArg?: number,
) => {
  const numericPrecision = getNumericPrecision(dataType, fieldName, numericPrecisionArg);

  switch (dataType) {
    case 'currency':
    case 'money':
    case 'float':
      return getExcelNumeralFormat(`d${numericPrecision}`);
    case 'decimal':
    case 'percent':
      return getExcelNumeralFormat(`p${numericPrecision}`);
    default:
      return '';
  }
};

// Converts 0-indexed cell address to A1 form and returns the cell
export const getCellByCellAddress = (sheet: XLSX.WorkSheet = {}, address: XLSX.CellAddress): XLSX.CellObject => {
  const encodedAddress = XLSX.utils.encode_cell(address);
  return sheet[encodedAddress];
};

// add a title row with header styles, with the cells merged and centered
export const addTitleRow = (title = '', columnsNum = 1, options: any = {}, sheet: XLSX.WorkSheet = {}) => {
  const placeholder = ' ';
  const arr = new Array(columnsNum).fill(placeholder);
  arr[0] = [title];

  const prevRow = getRowsLength(sheet);
  const titleOrigin = prevRow === 0 ? 0 : prevRow + 1;

  const _sheet = addHeaderRowByAoa([arr], options, sheet);

  _sheet['!merges'] = _sheet['!merges'] || [];
  _sheet['!merges']?.push({ s: { r: titleOrigin, c: 0 }, e: { r: titleOrigin, c: columnsNum - 1 } });

  return _sheet;
};

// add the header row by array of values array and style it
export const addHeaderRowByAoa = (
  headerArray: [any[]],
  options: any = {},
  sheet: XLSX.WorkSheet = {},
): XLSX.WorkSheet => {
  XLSX.utils.sheet_add_aoa(sheet, headerArray, options);
  const lastRow = getRowsLength(sheet);
  return withHeaderRowStyles(lastRow, sheet, true);
};

// add the json data and style the header row
export const addJSONData = (data: any[] = [], options: any = {}, sheet: XLSX.WorkSheet = {}) => {
  XLSX.utils.sheet_add_json(sheet, data, options);
  const headerRowIndex = getRowsLength(sheet) - data.length;
  return withHeaderRowStyles(headerRowIndex, sheet, true);
};

// Nulls are blank
export const sanitizeNullValue = (value?: null | string | number | Date | boolean, defaultValue: any = '') => {
  return value == null ? defaultValue : value;
};

// add type, format, styles or parse values if needed
export const configExcelCell = (
  cell: XLSX.CellObject = { t: 's' },
  dataType: NexusDataType,
  fieldName: string = '',
  numericPrecision?: number,
) => {
  // if value is null, show a black cell
  cell.v = sanitizeNullValue(cell.v);

  const nexusDataType = getNexusDataType(fieldName, dataType);

  switch (dataTypeMap[nexusDataType]) {
    case 'id':
      cell.t = 's';
      break;
    case 'number':
      cell.t = 'n';
      cell.z = getCellExcelNumeralFormat(nexusDataType, fieldName, numericPrecision);
      break;
    case 'date':
      cell.v = sanitizeNullValue(parseDataTypeValue(nexusDataType, cell.v));
      cell.s = { ...cell.s, alignment: { ...(cell.s?.alignment || {}), horizontal: 'right' } };
      break;
    case 'boolean':
      cell.t = 'b';
      break;
  }
};
