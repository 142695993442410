import { put, takeLatest } from 'redux-saga/effects';
import { TabsAction } from 'src/reducers/tabs';

export enum TabActions {
  OPEN_VIEW = 'OPEN_VIEW',
}

export function* open_View(action: any) {
  yield put({
    type: TabsAction.OPEN_TAB,
    path: action.data.path,
  });

  yield put({
    type: TabsAction.CHANGE_VIEW,
    data: { activeView: action.data.activeView },
  });
}

export function* watchOpenView() {
  yield takeLatest<any>(TabActions.OPEN_VIEW, open_View);
}
export default [watchOpenView];
