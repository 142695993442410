import { QueryConfig } from 'redux-query';
import { parseDisplayViewData } from 'src/api/queries/display-view';
import { RootState } from 'src/reducers/rootReducer';
// Make store view props mandatory for views requests
export interface GlideQueryView extends GlideQuery {
  storeViewName: string;
  storeViewProp: string;
}

export type GlideEndpoint =
  | '/gsearch'
  | '/glide/view'
  | '/glide/hypo/scenarios'
  | '/glide/order/update'
  | '/glide/order/workflow-transition'
  | `/glide/views/layouts`;

export interface GlideRequestBody {
  uri?: string;
  [key: string]: any;
}

export interface GlideQuery {
  body: GlideRequestBody;
  endpoint: GlideEndpoint;
  cache?: boolean;
  queryKey?: string;
  options?: any;
  transform?: (body: any) => any;
  update?: object;
  meta?: object;
  storeViewName?: string;
  storeViewProp?: string;
}

function getTransform(
  transform: ((body: any) => any) | undefined,
  storeViewName: string,
  storeViewProp: string | undefined,
) {
  if (transform) return transform;

  if (storeViewProp) {
    return (body: any) => ({
      views: {
        [storeViewName]: {
          [storeViewProp]: parseDisplayViewData(body),
        },
      },
    });
  }

  return (body: any) => ({
    views: {
      [storeViewName]: parseDisplayViewData(body),
    },
  });
}

function getUpdate(update: object | undefined, storeViewName: string, storeViewProp?: string) {
  if (update) return update;

  // Mutation request
  // Store in view within a dedicated path (ie: inspector data)
  if (storeViewProp) {
    return {
      views: (prev: any, next: any) => ({
        ...prev,
        [storeViewName]: {
          ...prev[storeViewName],
          [storeViewProp]: next[storeViewName][storeViewProp],
        },
      }),
    };
  }

  // Store directly in view (ie: FETCH_CLIENT_VIEW)
  return {
    views: (prev: any = {}, next: any = {}) => ({
      ...prev,
      [storeViewName]: {
        ...prev[storeViewName],
        ...next[storeViewName],
      },
    }),
  };
}

export const glideQuery = ({
  endpoint,
  body,
  options = {},
  cache = false,
  queryKey,
  update = undefined,
  transform = undefined,
  storeViewName,
  storeViewProp,
  meta = undefined,
}: GlideQuery): QueryConfig => {
  const _queryKey = queryKey || body?.uri || Object.keys(body)[0];
  const _storeViewName = storeViewName || _queryKey;
  return {
    url: endpoint,
    options: { method: 'GET', ...options },
    body,
    force: !cache,
    queryKey: _queryKey,
    update: getUpdate(update, _storeViewName, storeViewProp),
    transform: getTransform(transform, _storeViewName, storeViewProp),
    meta,
  };
};

export const glideQuerySelectorViewName = (state: RootState, storeViewName?: string) => {
  if (!storeViewName) return null;
  return state.entities.views?.[storeViewName as string] || { data: [], schema: [] };
};

export const glideQuerySelector = (state: RootState, storeViewName?: string, storeViewProp?: string) => {
  if (!storeViewName) return null;
  return state.entities.views?.[storeViewName as string]?.[storeViewProp as string];
};

export const isPendingQuerySelector = (state: RootState, uri: string) => {
  if (!uri) return null;
  return state.queries?.[uri]?.isPending;
};
