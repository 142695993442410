// eslint-disable (Handler for Glide meant for dev only)
import { IDPConfig } from '@virtus/common/auth';
import { User } from '@virtus/common/auth/msalWrapper';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import {
  authActions,
  authSelectors,
  GlideAuthError,
  GlideSession,
  GlideUser,
  RootState,
} from '@virtus/common/auth/reducer';
import PageError from '@virtus/components/page/page-error';
import React, { createContext, useEffect, SetStateAction, Dispatch as SetDispatch } from 'react';
import { connect } from 'react-redux';
import { GlideAppConfig } from 'src/config';
import { marketmapScripts } from 'src/utils/constants';
import IdleTimer from 'react-idle-timer';

interface ReduxProps {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isGlideAuthenticated: boolean;
  user: User | GlideUser | null;
  glideAuthError: GlideAuthError | any;
  readonly glideSession: any;
  isLogout: boolean;
}

interface OwnProps {
  children: (state: AuthenticationHandlerRenderProps) => React.ReactNode;
  config: GlideAppConfig;
  setClientEnv?: SetDispatch<SetStateAction<boolean>>;
}

interface UserDispatch {
  login: (arg?: any) => void;
  glideLogin: (arg: any) => void;
  logout: (arg?: any) => void;
}

interface ReduxDispatch {
  initializeAuth: (arg: IDPConfig) => void;
}

type Dispatch = ReduxDispatch & UserDispatch;

export type AuthenticationHandlerRenderProps = {
  isAuthenticated: boolean;
  isGlideAuthenticated: boolean;
  user: User | GlideUser | null;
  glideSession: GlideSession;
} & UserDispatch;

export type AuthenticationHandlerProps = ReduxProps & Dispatch & OwnProps;

export const AuthContext = createContext({});

let idleTimeoutRef: any;

const AuthenticationHandlerGlide = ({
  children,
  glideSession,
  isAuthenticated,
  logout,
  initializeAuth,
  login,
  glideLogin,
  isInitialized,
  user,
  isGlideAuthenticated,
  config,
  glideAuthError,
  setClientEnv,
  isLogout,
}: AuthenticationHandlerProps) => {
  useEffect(() => {
    initializeAuth(config.idp);
  }, [config, initializeAuth]);
  useEffect(() => {
    if (!isLogout) {
      if (isInitialized && !user) {
        login(config.idp);
      } else if (user && !isGlideAuthenticated) {
        glideLogin(config);
      }
    }
  });

  const NOTIFICATION_POPUP_WAIT_TIME = 5 * 60 * 1000;

  const logOut = () => {
    logout(config);
  };

  const continueUsingApp = () => {
    if (idleTimeoutRef) {
      clearTimeout(idleTimeoutRef);
    }
  };

  const { DialogComponent: ExtendSessionDialogue, onDispatcherClick: idlePopupDispatch } = useConfirmationDialog({
    onClick: () => {},
    onCancel: logOut,
    onConfirm: continueUsingApp,
    showCloseButton: false,
    headerText: 'You have been Idle for a While.',
    bodyTextContent: 'Do you want to continue using Glide?',
  });

  const handleOnIdle = () => {
    idleTimeoutRef = setTimeout(logOut, NOTIFICATION_POPUP_WAIT_TIME);
    idlePopupDispatch();
  };

  /**
   *  Required for marketmap integration in Glide
   *  current version of @script muse.min.js is "1.0.5"
   */
  useEffect(() => {
    if (isAuthenticated && isGlideAuthenticated) {
      marketmapScripts.map(script => {
        const scriptingElement = document.createElement('script');
        scriptingElement.src = script.scriptingUrl;
        scriptingElement.defer = true;
        scriptingElement.async = false;
        script.scriptingElement = scriptingElement as HTMLScriptElement;
        document.body.appendChild(scriptingElement);
      });
    }

    return () => {
      if (isAuthenticated && isGlideAuthenticated) {
        marketmapScripts.forEach(script => document.body.removeChild(script.scriptingElement));
      }
    };
  }, [isAuthenticated, isGlideAuthenticated]);

  return (
    <>
      <ExtendSessionDialogue />
      {config.idp?.idleTimeout > 0 && (
        <IdleTimer timeout={config.idp.idleTimeout} onIdle={handleOnIdle} debounce={250} />
      )}
      {glideAuthError && (
        <PageError
          statusCode={glideAuthError.statusCode}
          statusMessage={glideAuthError.statusMessage}
          footerMessage="Please contact support: support@alphakinetic.com"
          setClientEnv={setClientEnv}
        />
      )}
      {isInitialized &&
        (user || isGlideAuthenticated) &&
        children({
          glideLogin,
          glideSession,
          isAuthenticated,
          isGlideAuthenticated,
          login,
          logout,
          user,
        })}
    </>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  isInitialized: authSelectors.getStatus(state) === 'initialized',
  user: authSelectors.getUser(state),
  isAuthenticated: authSelectors.isAuthenticated(state),
  isGlideAuthenticated: authSelectors.isGlideAuthenticated(state),
  glideAuthError: authSelectors.glideAuthError(state),
  glideSession: authSelectors.glideSession(state),
  isLogout: authSelectors.getStatus(state) === 'logout',
});

const mapDispatchToProps: Dispatch = {
  initializeAuth: authActions.initialize,
  login: authActions.login,
  logout: authActions.glideLogout,
  glideLogin: authActions.glideLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationHandlerGlide);
