import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { glideQuerySelector, isPendingQuerySelector } from 'src/api/query';
import DefaultInspector from './glide-object-inspector.default';
import { ModalInspector } from './glide-object-inspector.modal';
import { PaperStyled } from 'src/components/inspectors/glide-inspector/glide-inspector.style';
import { RootState } from 'src/reducers';
import { ComponentProps, selectComponents } from 'src/reducers/components';
import GlideInspectorFooter from '../glide-inspector/glide-inspector-footer';
import { dispatchActions } from 'src/app/store';
import { useFormGroup } from 'src/components/forms/hooks/useFormGroup';
import { mapDetailsToFormElements } from 'src/mappers/common-mapper-functions';
import { NotificationsAction } from 'src/reducers/notifications';
import { mutationPending } from 'src/api/mutations/mutationfunctions';
import { selectCVC } from 'src/reducers/tabs';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';

export const glideInspectorStyle: React.CSSProperties = {
  position: 'relative',
  right: 'inherit',
  height: 'calc(100vh - 80px)',
};

export const glideInspectorExpandedStyle: React.CSSProperties = {
  position: 'absolute',
  right: 0,
  width: '100vw',
  height: 'calc(100vh - 80px)',
};

interface GlideObjectInspectorProps {
  uri: string;
  clientViewUri: string;
}

interface ReduxProps {
  inspectorData: any;
  isWorkflowTransitionInProgress?: boolean;
  isPendingQuery: boolean;
  components: any;
  clientViewConfiguration: ClientViewConfigurationData;
}

export interface ReduxDispatch {
  onValidationError: (errorMessage: any) => void;
}

export type GlideObjectInspector = GlideObjectInspectorProps & ReduxDispatch & ReduxProps & ComponentProps;
const STORE_VIEW_PROP = 'inspectorData';

const GlideObjectInspector: React.FC<GlideObjectInspector> = ({
  uri,
  inspectorData,
  isPendingQuery,
  components,
  clientViewUri,
  onValidationError,
}) => {
  if (!uri) return null;
  const [isExpanded, setIsExpanded] = useState(false);

  const onSubmit = (formChanges: object) => {
    dispatchActions.db.updateView({
      endpoint: '/glide/update',
      options: { method: 'PUT' },
      body: formChanges,
      storeViewName: clientViewUri,
      storeViewProp: STORE_VIEW_PROP,
    });
    if (components.inspector.isEdit) {
      // console.info('[glideObjectInspector] reset static inspector');
      dispatchActions.components.reset('inspector');
    }
  };

  const {
    onSave,
    isEditing,
    onChangeField,
    enableEditMode,
    setFormGroups,
    formGroupsState,
    disableEditMode,
    formGroupsStateRef,
  } = useFormGroup({
    onSubmit,
    objectKey: uri,
    initialEditing: components.inspector.isEdit,
    onValidationError,
  });

  useEffect(() => {
    console.info('GET Glide Object:', uri);
    dispatchActions.db.fetchView({
      endpoint: '/glide/display-view/groups',
      body: {
        uri,
        fetch_options: 'workflow_transitions',
        expand_prop: 'actions',
      },
      storeViewName: clientViewUri,
      storeViewProp: STORE_VIEW_PROP,
    });
  }, [uri]);

  useEffect(() => {
    if (!inspectorData?.data) return;
    const inspectorDataForm = mapDetailsToFormElements(inspectorData.data, inspectorData.fieldRules);
    setFormGroups(inspectorDataForm.formGroups);
    // Reset static component in case it was set by a notification
  }, [inspectorData]);

  const actionHandlers = {
    'instance/actions/edit_object': enableEditMode,
    'instance/actions/saveobject': onSave,
    'instance/actions/canceledit': () => disableEditMode(),
  };

  const onWorkflowActionClicked = (transition_uri: string) => {
    dispatchActions.db.update({
      endpoint: '/glide/order/workflow-transition',
      options: { method: 'PUT' },
      body: { uri, transition_uri },
      storeViewName: clientViewUri,
      storeViewProp: STORE_VIEW_PROP,
    });
  };

  const onHandleClose = () => {
    dispatchActions.components.updateView('inspector', clientViewUri, { isCollapsed: true });
    setIsExpanded(false);
  };
  const onExpandHandler = () => setIsExpanded(!isExpanded);

  const inspectorFooter = (
    <GlideInspectorFooter
      displayViewData={inspectorData}
      actionHandlers={actionHandlers}
      isExpanded={isExpanded}
      isEditing={components.inspector.isEdit || isEditing}
      onWorkflowActionClicked={onWorkflowActionClicked}
      formGroupsStateRef={formGroupsStateRef}
    />
  );

  const defaultInspector = (
    <DefaultInspector
      dataTestId={uri}
      isEditing={components.inspector.isEdit || isEditing}
      forms={formGroupsState}
      onChangeField={onChangeField}
      objectCollectionUri={uri}
      style={glideInspectorStyle}
      loadingIcon={{ type: LoadingIconType.Glide, size: LoadingIconSizes.large }}
      // This is ok! We don't need to inject the display name from somewhere else if data is not available
      title={isPendingQuery ? 'Loading...' : inspectorData?.displayName}
      paperProps={{ component: PaperStyled }}
      loading={isPendingQuery}
      onClose={onHandleClose}
      onExpand={onExpandHandler}
      renderFooter={inspectorFooter}
    />
  );

  const handleCloseModal = () => setIsExpanded(false);

  const modalInspector = (
    <ModalInspector
      title={isPendingQuery ? 'Loading...' : inspectorData?.displayName}
      isEditing={components.inspector.isEdit || isEditing}
      forms={formGroupsState}
      onChangeField={onChangeField}
      objectCollectionUri={uri}
      onClose={handleCloseModal}
      renderFooter={inspectorFooter}
    />
  );

  return isExpanded ? modalInspector : defaultInspector;
};

const mapStateToProps = (
  state: RootState,
  { uri, clientViewUri }: GlideObjectInspectorProps,
): ReduxProps & ComponentProps => ({
  components: selectComponents(state),
  isPendingQuery: isPendingQuerySelector(state, uri),
  isWorkflowTransitionInProgress: mutationPending(state as RootState, 'executeWorkflowTransitionMutation'),
  inspectorData: glideQuerySelector(state, clientViewUri, STORE_VIEW_PROP),
  clientViewConfiguration: selectCVC(state),
});

const mapDispatchToProps = (dispatch: any): ReduxDispatch => ({
  onValidationError: (errorMessage: string) =>
    dispatch({
      type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
      payload: { errorMessage },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlideObjectInspector);
