import { authSelectors, GlideSession } from '@virtus/common/auth/reducer';
import { FormFieldOptions, FormFieldsType, OptionElement } from 'src/components/forms/form-elements/FormElement.model';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ActionArguments, DisplayViewApi } from 'src/api/queries';
import { GlideDataContent } from 'src/components/forms/glide-data-content';
import { mapActionArgumentsToActionForm } from 'src/mappers/action-arguments/action-entity';
import { RootState } from 'src/reducers';
import { ComponentProps, selectComponents } from 'src/reducers/components';
import Modal from '@virtus/components/Modal';
import { FormWrapper } from './action-form.style';
import { omit } from 'lodash';
import { actionFormOverrideSelector, actions } from 'src/reducers/actions';
import { dispatchActions } from 'src/app/store';

interface ReduxProps {
  glideSession: GlideSession;
  actionArguments: any;
  actionDisplayView: any;
  actionOverrideObject: any;
}

interface ReduxDispatch {
  executeAction: ({
    targetUri,
    actionArguments,
    actionUri,
  }: {
    targetUri: any;
    actionArguments?: any;
    actionUri: string;
  }) => void;
}

export interface ActionFormProps {
  onChangeField?: (change: { [key: string]: string }) => void;
  targetUris?: string | string[];
  showAsModal?: boolean;
}

interface ActionFormContentType {
  formFields: any;
  formValues: any;
  formErrors: { [key: string]: any };
  actionUri: string;
}

type ActionFormType = ActionFormProps & ReduxProps & ReduxDispatch & ComponentProps;

const getSelectedOption = (options: FormFieldOptions, defaultValue: string) => {
  const optionValues: OptionElement[] = Object.values(options) || [];
  const foundOption = optionValues.find((option: OptionElement) => option.value === defaultValue);
  return foundOption ? foundOption.value : '';
};

const getInitialCreateActionFormValues = (formFields: FormFieldsType) =>
  Object.keys(formFields).reduce((acc, key) => {
    const options = formFields[key].options || {};
    return {
      ...acc,
      [key]:
        formFields[key].formElementType === 'select'
          ? getSelectedOption(options, formFields[key].defaultValue as string)
          : formFields[key].defaultValue,
    };
  }, {});

export const ActionForm: FC<ActionFormType> = ({
  actionArguments,
  components,
  targetUris,
  actionDisplayView,
  onChangeField,
  executeAction,
  actionOverrideObject,
  showAsModal = true,
}) => {
  const [argsNotAvailable, setArgsNotAvailable] = useState<boolean>(false);
  const [formContent, setFormContent] = useState<ActionFormContentType>({
    formFields: {},
    formValues: {},
    formErrors: {},
    actionUri: '',
  });

  useEffect(() => {
    //reset form visibility on unmount
    return () => dispatchActions.components.update('actionForm', { visible: false, actionUri: '' });
  }, []);

  const actionUri = components?.actionForm?.actionUri;

  useEffect(() => {
    if (actionArguments) {
      if (actionArguments?.[actionUri]?.arguments) {
        const formData = mapActionArgumentsToActionForm(
          actionArguments?.[actionUri],
          actionDisplayView?.[actionUri],
          actionOverrideObject?.[actionUri] || {},
        );
        const initialFormValues = getInitialCreateActionFormValues(formData?.formFields);
        const filteredFormFieldsKeys = Object.keys(formData.formFields).filter(key => formData.formFields[key]?.hidden);
        setFormContent({
          formFields: omit(formData.formFields, filteredFormFieldsKeys),
          formValues: initialFormValues,
          formErrors: {},
          actionUri,
        });
      } else {
        setArgsNotAvailable(true);
      }
    }
  }, [actionArguments, actionDisplayView, actionUri]);

  if (argsNotAvailable) {
    executeAction({ actionUri, targetUri: targetUris });
    dispatchActions.components.toggleDisplay('actionForm', false);
    return null;
  }

  const onChangeFormField = (change: { [key: string]: string }) => {
    setFormContent({ ...formContent, formValues: { ...formContent.formValues, ...change } });
    if (onChangeField) {
      onChangeField(change);
    }
  };

  const handleOnSubmitAction = (formValues: object) => {
    executeAction({ actionUri, actionArguments: formValues, targetUri: components?.actionForm?.target_uri });
    dispatchActions.components.toggleDisplay('actionForm', false);
  };

  const onCancel = () => dispatchActions.components.toggleDisplay('actionForm', false);

  const GlideDataContentForm = (
    <FormWrapper>
      <GlideDataContent
        isEditing
        formName="action-form"
        dataTestId="action-form"
        onCancel={onCancel}
        onSubmitForm={handleOnSubmitAction}
        form={formContent}
        formValues={formContent?.formValues}
        onChangeField={onChangeFormField}
        primaryButtonLabel={actionOverrideObject[actionUri]?.primaryActionButton?.label}
      />
    </FormWrapper>
  );

  return (
    <Modal
      onClose={onCancel}
      show={showAsModal}
      isDraggable
      hideFooter
      title={actionArguments?.[actionUri]?.description ?? actionArguments?.[actionUri]?.display_name}
      fullWidth
    >
      {GlideDataContentForm}
    </Modal>
  );
};

const mapStateToProps = (state: RootState): ReduxProps & ComponentProps => ({
  actionArguments: ActionArguments.selector(state),
  glideSession: authSelectors.glideSession(state),
  components: selectComponents(state),
  actionDisplayView: DisplayViewApi.displayViewSelector(state),
  actionOverrideObject: actionFormOverrideSelector(state),
});

const mapDispatchToProps = (dispatch: any): ReduxDispatch => ({
  executeAction: ({ targetUri, actionArguments, actionUri }) =>
    dispatch({
      type: actions.action.EXECUTE_ACTION,
      payload: { targetUri, arguments: actionArguments, actionUri },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionForm);
