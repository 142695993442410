import PaletteIcon from '@material-ui/icons/Palette';
import { DropdownMenuItem, DropdownMenuItemText } from '@virtus/components/DropdownMenu';
import ToolBar from '@virtus/components/tool-bar';
import { ToolBarProps } from '@virtus/components/tool-bar/tool-bar';
import React, { useContext } from 'react';
import { Context } from 'src/contexts/grid-theme/grid-theme.context';

export interface GlideToolbarProps extends Pick<ToolBarProps, 'extraIconButtons' | 'links' | 'onClickTab'> {
  pageToolBarProps?: any;
  views: { [viewKey: string]: any };
  activeViewKey: string;
}

export enum ThemeNames {
  light = 'light',
  dark = 'dark',
}

export const GlideToolbar = ({
  views,
  activeViewKey,
  onClickTab,
  extraIconButtons,
  links,
  pageToolBarProps,
}: GlideToolbarProps) => {
  const { useDarkTheme, toggleGridTheme } = useContext(Context);

  const items = [
    {
      text: `Change grid theme to ${useDarkTheme ? ThemeNames.light : ThemeNames.dark}`,
      icon: () => <PaletteIcon />,
      onClick: () => toggleGridTheme(),
    },
  ];

  const customParseViews = (views: any) => {
    return views.map((view: any) => {
      const { id, name, loading = false } = view;
      return {
        id,
        name,
        loading,
      };
    });
  };

  const moreButtonItems = () =>
    items.map((Item, index) => (
      <DropdownMenuItem key={index} onClick={Item.onClick}>
        <Item.icon />
        <DropdownMenuItemText>{Item.text}</DropdownMenuItemText>
      </DropdownMenuItem>
    ));

  const labels = Object.entries(views).map(([key, value]: any, index) => ({
    key,
    id: index,
    name: value.displayName,
  }));

  const onClickTabHandler = (id: number) => {
    const selectedTab = labels.find(label => label.id === id);
    onClickTab(selectedTab?.key);
  };

  const toolbarProps: ToolBarProps = {
    labels,
    active: labels.findIndex(label => label.key === activeViewKey.lastSplitValue()),
    links,
    onClickTab: onClickTabHandler,
    moreButtonItems,
    extraIconButtons,
    customParseViews: customParseViews,
    viewContainerStyles: { width: '65vw' },
  };

  return <ToolBar {...toolbarProps} {...pageToolBarProps} />;
};
