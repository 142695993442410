import React from 'react';
import { FormElementInputComponent, FormFieldType } from 'src/components/forms/form-elements';
import { StyledInput } from 'src/components/forms/form-elements/FormElements.style';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper';
import { applyMultiplier } from './DefaultMultiplier';
import { useFieldRule } from 'src/components/forms/hooks/use-field-rule';
import { formatNumber } from '@virtus/common/utils/formatters';
const LOCALE = 'en-US';

const convertValueToLocale = (value: any) => value.toLocaleString(LOCALE);

const checkPercentageFieldEdited = (field: FormFieldType) =>
  field.format && field.format.includes('p') && !field.isEdited;

export const DecimalInput = ({
  field,
  value = '',
  onChange,
  error,
  style,
  hideLabel,
  isInATable,
  formValues,
  isEditing,
}: FormElementInputComponent & { isInATable?: boolean; isEditing?: boolean }) => {
  const fieldUpdateFn = (nextValue: any) => {
    const multipliedValue = applyMultiplier(nextValue);
    const event: Partial<React.SyntheticEvent> = {
      target: {
        value: convertValueToLocale(multipliedValue),
      } as HTMLInputElement,
    };
    onChange(event);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const multipliedValue = applyMultiplier(e.target.value);
    e.target.value = convertValueToLocale(multipliedValue);
    if (onChange) {
      onChange(e);
    }
    field.isEdited = true;
  };
  let nonNumericValue: string;
  const isValidValue = value || value === 0;
  if ((field.readonly || field.disabled) && isValidValue) {
    if (field.format && checkPercentageFieldEdited(field)) {
      nonNumericValue = formatNumber(Number(value), field.format);
    } else {
      const precision = field.format?.charAt(field.format.length - 1);
      nonNumericValue = Number(value).toLocaleString(LOCALE, {
        minimumFractionDigits: Number(precision),
      });
    }
  } else {
    if (field.format && checkPercentageFieldEdited(field)) {
      nonNumericValue = (value * 100).toFixed(+field.format?.charAt(field.format.length - 1));
    } else {
      nonNumericValue = isValidValue ? value : '';
    }
  }

  const fieldRule = useFieldRule({
    fieldName: field.displayName,
    fieldRules: field.fieldRules,
    formValues,
    fieldUpdateFn,
  });
  if (fieldRule.hidden) return null;
  return (
    <FormErrorWrapper
      error={error}
      displayName={field.displayName}
      name={field.name}
      key={field.name}
      disabled={fieldRule.disabled || field.disabled}
      required={fieldRule.required || field.required}
      readonly={fieldRule.disabled || field.readonly}
      hideLabel={hideLabel}
      disableErrorWarning={field.disableErrorWarning}
    >
      <StyledInput
        autoComplete="off"
        data-testid={`${field.name}-decimal` || 'decimal'}
        disabled={fieldRule.disabled || field.disabled || field.readonly}
        error={Boolean(error) && !(field.readonly || field.disabled)}
        onChange={handleOnChange}
        inputProps={{
          style: {
            textAlign: isInATable ? 'right' : 'left',
          },
        }}
        style={style}
        value={typeof value === 'number' ? value.toLocaleString(LOCALE) : nonNumericValue}
        name={field.name}
        placeholder={!value && value !== 0 && !isEditing ? '' : field.displayName || '0'}
      />
    </FormErrorWrapper>
  );
};
