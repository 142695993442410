import { endpoints } from 'src/api/constants';
import { RootState } from 'src/reducers/rootReducer';

export const get = (action_uri: string) => ({
  url: endpoints.order.actionArguments,
  transform: (body: any) => ({
    actionArguments: body,
  }),
  options: { method: 'GET' },
  body: { action_uri },
  queryKey: `${action_uri}`,
  force: false, // Cache the request!
  update: {
    actionArguments: (a: any, c: any) => ({ [action_uri]: c, ...a }),
  },
});

export const selector = (state: RootState) => state.entities.actionArguments;
export const pending = (state: RootState) =>
  state.queries.getActionArguments && state.queries.getActionArguments.isPending;
